import { cn } from "@/lib/utils";
import { MenuOption } from "@/types/sort";
import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import React, { useMemo } from "react";
import { Text } from "./typography";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemProps,
  DropdownMenuTrigger,
  DropdownMenuTriggerProps,
} from "./ui/dropdown-menu";

/**
 * This component will be used by the following:
 * - Filters and Sort By
 * - Dropdown Menu for Transactions, Account Information
 *
 * This is to reduce implemeting multiple structure in different components.
 */
interface DropdownProps extends DropdownMenuItemProps {
  /** Trigger Label */
  label?: string | React.ReactNode;
  options: MenuOption[];
  isSorting?: boolean;
  activeId?: string;
  onSelectOption: (option?: MenuOption) => void;
  menuProps?: DropdownMenuProps;
  triggerProps?: DropdownMenuTriggerProps;
}

export default function Dropdown(props: Readonly<DropdownProps>) {
  const {
    label,
    activeId,
    isSorting,
    options,
    variant,
    className,
    onSelectOption,
    menuProps,
    triggerProps,
    ...menuItemProps
  } = props;

  const triggerLabel = useMemo(() => {
    // use the provided label
    if (label) {
      return label;
    } else {
      // if there is no provided label, get the label from the selected option
      return options.find((option) => {
        return option.id === activeId;
      })?.label;
    }
  }, [options, label, activeId]);

  return (
    <DropdownMenu {...menuProps}>
      <DropdownMenuTrigger
        {...triggerProps}
        className={`${cn("shrink-0 text-start hover:opacity-75", triggerProps?.className)}`}
      >
        {triggerLabel}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="grid max-h-96 max-w-80 gap-1.5 overflow-y-visible">
        {options?.map((option) => {
          return (
            <DropdownMenuItem
              disabled={option.isDisabled}
              key={option.id}
              variant={variant ?? "secondary"}
              className={cn(
                className,
                `${isSorting && "flex w-60 justify-between gap-4"}`,
              )}
              onSelect={() => {
                onSelectOption(option);
              }}
              active={activeId === option.id}
              {...menuItemProps}
            >
              {isSorting ? (
                <>
                  <Text size="md" variant="primary">
                    {option.label}
                  </Text>
                  <Text size="md">{option.orderLabel}</Text>
                </>
              ) : (
                <Text size="md">{option.label}</Text>
              )}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export { Dropdown, type DropdownProps };
