import CheckIcon from "@/assets/icons/check.svg";
import ClipboardIcon from "@/assets/icons/clipboard.svg";
import { useEffect, useState } from "react";
import { toast } from "./hooks/use-toast";
import { Button } from "./ui";

type CopyButtonProps = {
  text: string;
  className?: string;
};

export function CopyButton({ text, className }: CopyButtonProps) {
  const [isCopied, setIsCopied] = useState<"eoa" | "fp" | null>(null);

  async function handleCopy(text: string) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      toast({
        title: "Error!",
        description: `failed to copy ${text}`,
        variant: "error",
      });
    }
  }

  useEffect(() => {
    if (isCopied) {
      // TODO: Fix this - auto remove of copied flag
      setTimeout(() => {
        setIsCopied(null);
      }, 5000);
    }
  }, [isCopied]);

  return (
    <Button
      onClick={() => {
        setIsCopied("eoa");
        handleCopy(text);
      }}
      className={className}
    >
      {isCopied === "eoa" ? (
        <img src={CheckIcon} className="text-primary" alt="Check Icon" />
      ) : (
        <img
          src={ClipboardIcon}
          className="text-primary"
          alt="Clipboard Icon"
        />
      )}
    </Button>
  );
}
