import MoreMenuIcon from "@/assets/icons/moremenu.svg";
import { Text } from "@/components/typography";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Separator,
} from "@/components/ui";
import { useNavigationItems } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Small Screen Navigation Menu
 * @returns
 */
export default function Menu() {
  const { items } = useNavigationItems();
  const { pathname } = useLocation();
  const { toggleModal } = useModalState();

  const navigate = useNavigate();

  function getCurrentPath() {
    return pathname === Routes.DASHBOARD ? "" : pathname;
  }

  return (
    <div className="flex items-center xl:hidden">
      <DropdownMenu>
        <DropdownMenuTrigger variant="outline" arrow={false}>
          <div className="flex items-center rounded-lg bg-accent/50 p-1">
            <img
              src={MoreMenuIcon}
              className="h-6 w-6 px-0.5"
              alt="More Menu Icon"
            />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="p-0 max-sm:w-[calc(100vw-40px)]">
          {items
            .filter((item) => {
              return item.path !== Routes.ADMIN && !item.isHidden;
            })
            .map((item, index) => {
              return (
                <div key={`${item.label}-${item.id}`}>
                  {item.path === Routes.TRANSACTION ? (
                    item.subItems?.map((subItem) => {
                      return (
                        <DropdownMenuItem
                          key={`${subItem.label}-${subItem.id}`}
                          className="py-4.5 h-10 px-5"
                          onClick={() => {
                            toggleModal({
                              title: subItem.label,
                              id: subItem?.label.toLowerCase(),
                              redirectBack: true,
                            });
                            navigate(getCurrentPath() + "/" + subItem.path);
                          }}
                        >
                          <Text size="md" className="w-full px-3 text-start">
                            {subItem.label}
                          </Text>
                        </DropdownMenuItem>
                      );
                    })
                  ) : (
                    <DropdownMenuItem
                      className="py-4.5 h-10 px-5"
                      onClick={() => {
                        navigate(item.path);
                      }}
                    >
                      <Text
                        variant={`${pathname === item.path ? "active" : "default"}`}
                        size="md"
                        className="w-full px-3 text-start"
                      >
                        {item.label}
                      </Text>
                    </DropdownMenuItem>
                  )}
                  {index !== items.length - 1 && <Separator />}
                </div>
              );
            })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
