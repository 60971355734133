import { Spinner, Text } from "@/components";
import {
  Button,
  Card,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
} from "@/components/ui";
import { cn, getHighlightedTexts } from "@/lib/utils";
import { useKyc } from "@/redux/kyc/kyc.slice";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { KycProps } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

const items = [
  {
    id: "immersve-privacy",
    label: "Immersve",
    content:
      "I consent to the collection, use, and disclosure of my personal information in accordance with Immersve’s Privacy Policy.",
    highlights: [
      {
        label: "Immersve’s Privacy Policy",
        link: "https://immersve.com/terms-and-conditions/",
      },
    ],
  },
  {
    id: "immersve-terms",
    label: "Immersve",
    content: "I agree to the Immersve General Terms of Use.",
    highlights: [
      {
        label: "Immersve General Terms of Use",
        link: "https://immersve.com/terms-and-conditions/",
      },
    ],
  },
];

const InfoFormSchema = z.object({
  items: z.array(z.string()).refine((value) => value.length === items.length, {
    message: "You have to accept all terms.",
  }),
});

export function Information(props: Readonly<KycProps>) {
  const { navButtons, isStepCompleted } = props;
  const [updateUserProfile, result] = useUpdateUserProfileMutation();
  const { isOnboardingEnabled } = useKyc();

  function getDefaultValues() {
    if (isStepCompleted) {
      return ["immersve-terms", "immersve-privacy"];
    }

    return [];
  }

  const form = useForm<z.infer<typeof InfoFormSchema>>({
    resolver: zodResolver(InfoFormSchema),
    defaultValues: {
      items: getDefaultValues(),
    },
  });

  async function onSubmit(data: z.infer<typeof InfoFormSchema>) {
    await updateUserProfile({
      immersveTermsOfUse: true,
      immersvePrivacyPolicy: true,
    });

    console.log(
      "You submitted the following values:: ",
      JSON.stringify(data, null, 2),
    );
  }

  return (
    <div className="col-auto grid h-full max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons()}
        <Text size="2xl" variant="secondary">
          Step 1: <span className="text-foreground">Information</span>
        </Text>
        <Text size="sm">
          As part of the Mastercard activation process Joinn requires users to
          accept and agree to the documents listed in Step 1.
        </Text>
        <Text size="sm">
          These documents are from third parties that Joinn has partnered with
          in order to provide the ability to spend USDC anywhere in the world a
          digital Mastercard is accepted. This includes the ability to use your
          Mastercard with Google Pay, Apple Pay and Secure 3D.
        </Text>
      </div>
      <Card
        variant={isStepCompleted || result.isSuccess ? "success" : "default"}
      >
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex h-full flex-col justify-between space-y-8"
          >
            <div className="flex items-center gap-4">
              <FormLabel className="px-0 text-xl text-primary max-sm:text-lg">
                Immersve
              </FormLabel>
              <Separator className="shrink" />
            </div>
            <FormField
              control={form.control}
              name="items"
              render={() => (
                <FormItem className="h-28">
                  {items.map((item, index) => {
                    const texts = getHighlightedTexts(
                      item.highlights,
                      item.content,
                    );

                    return (
                      <div key={item.id}>
                        <FormField
                          control={form.control}
                          name="items"
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={item.id}
                                className="flex flex-row items-start space-y-0 py-2"
                              >
                                <FormControl>
                                  <Checkbox
                                    className={cn(
                                      isStepCompleted && "pointer-events-none",
                                    )}
                                    disabled={
                                      !isOnboardingEnabled && !isStepCompleted
                                    }
                                    checked={field.value?.includes(item.id)}
                                    onCheckedChange={(checked) => {
                                      const values = [...field.value];

                                      return checked
                                        ? field.onChange([...values, item.id])
                                        : field.onChange(
                                            values.filter((value) => {
                                              return value !== item.id;
                                            }),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="h-full pl-3 pr-0 text-sm font-normal text-foreground/50 max-sm:text-xs">
                                  {texts.map((text, textIndex) => {
                                    const accent = item.highlights.find(
                                      (accent) => {
                                        return accent.label === text;
                                      },
                                    );

                                    return !isEmpty(accent) ? (
                                      <Link
                                        to={accent.link}
                                        target="_blank"
                                        className="font-semibold text-secondary underline"
                                        key={`${item.label}-${item.id}-${textIndex}`}
                                      >
                                        {text}
                                      </Link>
                                    ) : (
                                      <span
                                        key={`${item.label}-${item.id}-${textIndex}`}
                                      >
                                        {text}
                                      </span>
                                    );
                                  })}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex w-full justify-end">
              <Button
                disabled={!isOnboardingEnabled && !isStepCompleted}
                readOnly={isStepCompleted}
                variant={isStepCompleted ? "success" : "primary"}
                className="rounded"
                type="submit"
                size="sm"
              >
                {isStepCompleted
                  ? "I have accepted the Terms of Service"
                  : "I accept and agree to the above"}

                {result.isLoading && <Spinner className="mx-0" />}
              </Button>
            </div>
          </form>
        </Form>
      </Card>
    </div>
  );
}
