import { VAULT_ABI } from "@/abis";
import { TOKEN_ADDRESSES } from "@/const";
import { useGetBalanceQuery } from "@/redux/transactionHistory/transactionHistory.api";
import { formatUnits } from "ethers/lib/utils";
import { useEffect, useMemo, useState } from "react";
import { Abi, erc20Abi } from "viem";
import { useAccount, useReadContracts } from "wagmi";
import { useDashboard } from "./useDashboard";

export function useBalances() {
  const { chain } = useAccount();
  const { userWallets, balances } = useDashboard();
  const joinnWalletAddress = userWallets?.joinnWalletAddress;

  const tokenAddress = useMemo(
    () =>
      chain && TOKEN_ADDRESSES[chain.id]?.USDC.address.startsWith("0x")
        ? TOKEN_ADDRESSES[chain.id].USDC.address
        : "0x0000000000000000000000000000000000000000",
    [chain],
  );

  const [vaultsBalance, setVaultsBalance] = useState<number | null>(null);
  const [walletsBalance, setWalletsBalance] = useState<number | null>(null);
  const [spendingAccountBalance, setSpendingAccountBalance] = useState<
    number | null
  >(null);

  const {
    data: historyTransactionsData,
    isFetching: historyTransactionsIsFetching,
  } = useGetBalanceQuery(joinnWalletAddress as string, {
    skip: !joinnWalletAddress,
  });

  const walletsAddress = useMemo(
    () =>
      balances?.wallets?.map((wallet) => wallet.address).filter(Boolean) ?? [],
    [balances],
  );

  const joinnWalletAddressList = useMemo(
    () =>
      balances?.wallets?.map((wallet) => wallet.joinnAddress).filter(Boolean) ??
      [],
    [balances],
  );

  const vaultsAddress = useMemo(
    () => balances?.vaults?.map((vault) => vault.address) ?? [],
    [balances],
  );

  const usdcToken = useReadContracts({
    allowFailure: false,
    contracts: [
      ...walletsAddress.map((address) => ({
        address: tokenAddress,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [address as `0x${string}`],
      })),
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: "decimals",
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: "symbol",
      },
    ],
  });

  const vaultJoinnWalletPairs = useMemo(
    () =>
      vaultsAddress.flatMap((vaultAddress) =>
        joinnWalletAddressList.map((joinnAddress) => ({
          joinnAddress,
          vaultAddress,
        })),
      ),
    [vaultsAddress, joinnWalletAddressList],
  );

  const vaultsContracts = vaultJoinnWalletPairs.flatMap(
    ({ joinnAddress, vaultAddress }) => {
      if (!joinnAddress || !vaultAddress) return [];

      return [
        {
          address: vaultAddress as `0x${string}`,
          abi: VAULT_ABI as Abi,
          functionName: "balanceOf",
          args: [joinnAddress as `0x${string}`],
        },
        {
          address: vaultAddress as `0x${string}`,
          abi: VAULT_ABI as Abi,
          functionName: "decimals",
          args: [],
        },
        {
          address: vaultAddress as `0x${string}`,
          abi: VAULT_ABI as Abi,
          functionName: "symbol",
          args: [],
        },
      ];
    },
  );

  const walletBalancesInVaults = useReadContracts({
    allowFailure: false,
    contracts: vaultsContracts,
  });

  const [usdcDecimals = 0, usdcSymbol = ""] = (usdcToken.data?.slice(-2) as [
    number,
    string,
  ]) || [0, ""];

  const usdcBalances = useMemo(
    () =>
      walletsAddress.map((address, index) => ({
        address,
        balance: String(usdcToken.data?.[index] || "0"),
      })),
    [walletsAddress, usdcToken.data],
  );

  useEffect(() => {
    if (balances?.vaults) {
      const totalVaultsBalance = balances.vaults.reduce(
        (acc, vault) => acc + (vault?.balance ?? 0),
        0,
      );
      setVaultsBalance(totalVaultsBalance);
    }

    if (balances?.wallets) {
      const totalWalletsBalance = balances.wallets.reduce(
        (acc, wallet) => acc + (wallet?.balance ?? 0),
        0,
      );
      setWalletsBalance(totalWalletsBalance);
    }
  }, [balances]);

  useEffect(() => {
    if (historyTransactionsData) {
      setSpendingAccountBalance(
        Number(formatUnits(BigInt(historyTransactionsData.amount), 6)),
      );
    }
  }, [historyTransactionsData]);

  function getTotalBalance() {
    return (
      (vaultsBalance ?? 0) +
      (walletsBalance ?? 0) +
      (spendingAccountBalance ?? 0)
    );
  }

  function getSpendingAccountBalance() {
    return spendingAccountBalance
      ? (spendingAccountBalance ?? 0) - (vaultsBalance ?? 0)
      : undefined;
  }

  const vaultsWalletsBalancesSorted = useMemo(() => {
    if (!walletBalancesInVaults.data) return {};

    return vaultJoinnWalletPairs.reduce(
      (acc, { joinnAddress, vaultAddress }, index) => {
        if (!joinnAddress) return acc;

        const balanceIndex = index * 3;
        const vaultBalanceRaw =
          (walletBalancesInVaults.data?.[balanceIndex] as string) || "0";
        const vaultDecimals =
          (walletBalancesInVaults.data?.[balanceIndex + 1] as number) || 0;
        const vaultSymbol =
          (walletBalancesInVaults.data?.[balanceIndex + 2] as string) || "";

        const vaultBalance = formatUnits(vaultBalanceRaw, vaultDecimals);

        if (!acc[joinnAddress]) {
          const associatedWallet = balances?.wallets?.find(
            (wallet) => wallet.joinnAddress === joinnAddress,
          );
          const usdcBalanceRaw =
            usdcBalances.find(
              (wallet) => wallet.address === associatedWallet?.address,
            )?.balance || "0";

          const usdcBalance = formatUnits(usdcBalanceRaw, usdcDecimals);

          acc[joinnAddress] = {
            totalVaultParticipation: 0,
            walletAddress: associatedWallet?.address || "",
            walletName: associatedWallet?.name || "",
            vaults: {},
            usdcDecimals,
            usdcSymbol,
            usdcBalance,
          };
        }

        acc[joinnAddress].vaults[vaultAddress] = {
          balance: vaultBalance,
          decimals: vaultDecimals,
          symbol: vaultSymbol,
        };

        acc[joinnAddress].totalVaultParticipation += parseFloat(vaultBalance);

        return acc;
      },
      {} as Record<
        string,
        {
          totalVaultParticipation: number;
          usdcDecimals: number;
          usdcSymbol: string;
          walletAddress: string;
          walletName: string;
          usdcBalance: string;
          vaults: Record<
            string,
            { balance: string; decimals: number; symbol: string }
          >;
        }
      >,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vaultJoinnWalletPairs,
    walletBalancesInVaults.data,
    usdcBalances,
    usdcDecimals,
    balances,
  ]);

  return {
    balanceIsProcessing: historyTransactionsIsFetching,
    vaultsBalance,
    walletsBalance,
    totalBalance: getTotalBalance(),
    spendingAccountBalance: getSpendingAccountBalance(),
    vaultsWalletsBalancesSorted,
  };
}
