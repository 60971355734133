import { Tooltip } from "@/components";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Card,
  Separator,
} from "@/components/ui";
import { useIcons } from "@/hooks";
import { cn } from "@/lib/utils";
import { isEmpty } from "lodash";
import React, { useState } from "react";

type TransferDetailsProps = {
  caption?: string;
  captionOnHide?: string;
  data?: any[];
  className?: string;
};

export function TransferDetails(props: Readonly<TransferDetailsProps>) {
  const {
    caption = "Show details",
    captionOnHide = "Hide details",
    data,
    className,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState<string>("");
  const { InfoIcon2 } = useIcons();
  if (isEmpty(data) || !data) return false;

  return (
    <Accordion
      type="single"
      collapsible
      value={isCollapsed}
      onValueChange={setIsCollapsed}
      className={cn(className)}
    >
      <AccordionItem value="item-1">
        <AccordionContent>
          <Card className="px-3 py-2">
            {data.map((item, index) => (
              <React.Fragment key={item.item}>
                <div className="flex h-7 w-full items-center justify-between">
                  {item.tooltip ? (
                    <Tooltip content={item.tooltip} side="right">
                      <div className="flex items-center gap-3 text-sm text-white/50">
                        {item.item}
                        <InfoIcon2 />
                      </div>
                    </Tooltip>
                  ) : (
                    <div className="text-sm text-white/50">{item.item}</div>
                  )}
                  <div className="text-right text-sm text-secondary">
                    {item.value}
                  </div>
                </div>
                {index !== data.length - 1 && (
                  <Separator className="col-span-2" />
                )}
              </React.Fragment>
            ))}
          </Card>
        </AccordionContent>
        <AccordionTrigger
          noIcon
          variant="primary"
          size="sm"
          className="flex w-full justify-end"
        >
          {isCollapsed === "item-1" ? captionOnHide : caption}
        </AccordionTrigger>
      </AccordionItem>
    </Accordion>
  );
}
