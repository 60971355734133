import { cn } from "@/lib/utils";
import { isEmpty } from "lodash";
import { Info } from "lucide-react";
import React from "react";
import { Tooltip } from "../tooltip";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  Card,
  Separator,
} from "../ui";

type TransferCardProps = {
  data?: any[];
  collapsed: string;
};

export function TransferCard(props: Readonly<TransferCardProps>) {
  const { data, collapsed } = props;
  if (isEmpty(data) || !data) return false;

  return (
    <Accordion type="single" collapsible value={collapsed} className={cn("")}>
      <AccordionItem value="amount-details">
        <AccordionContent>
          <Card
            className="flex h-9 items-center justify-between rounded px-2 py-0"
            radius="default"
          >
            {data?.map((item, index) => {
              return (
                <React.Fragment key={item.item}>
                  <div className="flex h-7 w-full items-center justify-between">
                    {item.tooltip ? (
                      <Tooltip content={item.tooltip} side="right">
                        <div className="flex items-center gap-3 text-sm text-white/50">
                          {item.item}
                          <Info color="hsl(var(--accent))" size={14} />
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="text-sm text-white/50">{item.item}</div>
                    )}
                    <div className="text-right text-sm text-secondary">
                      {item.value}
                    </div>
                  </div>
                  {index !== data.length - 1 && (
                    <Separator className="col-span-2" />
                  )}
                </React.Fragment>
              );
            })}
          </Card>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
