import { Text } from "@/components";
import { Button, Card, Separator } from "@/components/ui";
import { useIcons } from "@/hooks";
import { scrollIntoElement } from "@/lib/utils";

export function QuickLinks() {
  const { CalculatorIcon, MastercardSupportIcon } = useIcons();

  return (
    <Card className="rounded-lg p-0 max-sm:p-0">
      <Text size="lg" variant="primary" className="px-8 py-6 max-md:p-3">
        Quick Links
      </Text>
      <Separator />
      <div className="flex flex-col gap-2 px-8 py-6 max-sm:p-4">
        <div className="flex gap-2">
          <Button
            variant="primary"
            size="sm"
            className="flex-1 text-start text-xs"
            onClick={() => {
              scrollIntoElement("currency-calculator");
            }}
          >
            <CalculatorIcon size={24} />
            Currency Calculator
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="flex-1 text-start text-xs"
            onClick={() => {
              window.location.href = "mailto:help@joinn.io";
            }}
          >
            <MastercardSupportIcon size={24} />
            Mastercard Support
          </Button>
        </div>
      </div>
    </Card>
  );
}
