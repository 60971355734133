import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../baseApi";
import { RootState } from "../store";
import { useUser } from "../user/user.slice";

type ImmersveState = {
  isConnected: boolean;
  isConnecting: boolean;
  isDisconnecting: boolean;
  isSpendingHidden: boolean;
  isActivationPaid: boolean;
};

const initialState: ImmersveState = {
  isConnected: false,
  isConnecting: true,
  isDisconnecting: false,
  isSpendingHidden: false,
  isActivationPaid: false,
};

export const immersveState = createSlice({
  name: "immersve",
  initialState,
  reducers: {
    connectImmersve: (state, action) => {
      if (action.payload) {
        sessionStorage.setItem("immersveTokenData", action.payload);
      }
      state.isConnected = true;
      state.isConnecting = false;
    },

    disconnectImmersve: (state) => {
      sessionStorage.removeItem("immersveTokenData");
      state.isConnected = false;
      state.isDisconnecting = false;
    },

    hideSpending: (state, action) => {
      const spending = localStorage.getItem("hiddenAccounts");
      const newAccount = action.payload;

      if (spending) {
        const data: string[] = JSON.parse(spending ?? "");
        data.push(newAccount);
        localStorage.setItem("hiddenAccounts", JSON.stringify(data));
      } else {
        localStorage.setItem("hiddenAccounts", JSON.stringify([newAccount]));
      }
      state.isSpendingHidden = true;
    },

    showSpending: (state, action) => {
      const spending = localStorage.getItem("hiddenAccounts");

      if (spending) {
        const data: string[] = JSON.parse(spending ?? "");
        if (data.length === 1) {
          localStorage.removeItem("hiddenAccounts");
        } else {
          const filteredAccounts = data.filter((account) => {
            return account !== action.payload;
          });

          localStorage.setItem(
            "hiddenAccounts",
            JSON.stringify(filteredAccounts),
          );
        }
      }
      state.isSpendingHidden = false;
    },

    setIsActivationPaid: (state, action) => {
      state.isActivationPaid = action.payload;
    },

    resetSpendingHiddenState: (state) => {
      state.isSpendingHidden = false;
    },

    setConnectingStatus: (state, action) => {
      state.isConnecting = action.payload;
    },

    setDisconnectingStatus: (state, action) => {
      state.isDisconnecting = action.payload;
    },
  },
});

export function useImmersveState() {
  const dispatch = useDispatch();
  const { lastLoggedInWallet } = useUser();
  const { actions } = immersveState;

  const isConnected = useSelector((state: RootState) => {
    return state.immersveState.isConnected;
  });

  const isDisconnected = useSelector((state: RootState) => {
    return !state.immersveState.isConnected;
  });

  const isConnecting = useSelector((state: RootState) => {
    return state.immersveState.isConnecting;
  });

  const isDisconnecting = useSelector((state: RootState) => {
    return state.immersveState.isDisconnecting;
  });

  const isSpendingStateHidden = useSelector((state: RootState) => {
    return state.immersveState.isSpendingHidden;
  });

  const isActivationPaid = useSelector((state: RootState) => {
    return state.immersveState.isActivationPaid;
  });

  return {
    connectImmersve: (token?: string) => {
      dispatch(actions.connectImmersve(token));
    },

    disconnectImmersve: () => {
      dispatch(actions.disconnectImmersve());
    },

    hideSpending: () => {
      dispatch(actions.hideSpending(lastLoggedInWallet));
    },

    showSpending: () => {
      dispatch(actions.showSpending(lastLoggedInWallet));
      dispatch(api.util.resetApiState());
    },

    resetSpendingHiddenState: () => {
      dispatch(actions.resetSpendingHiddenState());
    },

    setConnectingStatus: (status: boolean) => {
      dispatch(actions.setConnectingStatus(status));
    },

    setDisconnectingStatus: (status: boolean) => {
      dispatch(actions.setDisconnectingStatus(status));
    },

    setIsActivationPaid: (status: boolean) => {
      dispatch(actions.setIsActivationPaid(status));
    },

    isConnected,
    isDisconnected,
    isConnecting,
    isDisconnecting,
    isSpendingStateHidden,
    isActivationPaid,
  };
}
