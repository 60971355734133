import { FutureVerseProvider, TrnNativeApiProvider } from "@/providers";
import {
  QueryClient,
  QueryClientProvider as TanstackProvider,
} from "@tanstack/react-query";
import { Provider as RtkProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { Config, WagmiProvider } from "wagmi";
import { config } from "./chains/config";
import { Toaster, TooltipProvider } from "./components/ui";
import { store } from "./redux";
import { routers } from "./routes/routers";

const queryClient = new QueryClient();

//Microsoft Clarity for heatmapping/analytics
import { useEffect } from "react";
import { clarity } from "react-microsoft-clarity";

function App() {
  useEffect(() => {
    clarity.init("ogp3ea9jg7");
  }, []);

  return (
    // TODO: IMPORTANT!! -- Create a wrapper for all providers
    <WagmiProvider config={config as unknown as Config} reconnectOnMount={true}>
      <TanstackProvider client={queryClient}>
        <RtkProvider store={store}>
          <FutureVerseProvider>
            <TrnNativeApiProvider>
              <TooltipProvider>
                <RouterProvider router={routers} />
                <Toaster />
              </TooltipProvider>
            </TrnNativeApiProvider>
          </FutureVerseProvider>
        </RtkProvider>
      </TanstackProvider>
    </WagmiProvider>
  );
}

export default App;
