import { getMaskedAddress } from "@/lib/utils";
import { useCallback, useMemo } from "react";
import { useDashboard } from "./useDashboard";
import { useSpending } from "./useSpending";

type CombinedData = {
  address: string | undefined;
  name: string;
  balance: number | undefined;
};

export function useAddressToName() {
  const { info } = useDashboard();
  const { balances } = useDashboard();
  const { spendingAccount } = useSpending();

  const combinedData = useMemo<CombinedData[]>(() => {
    if (!balances) return [];

    const { vaults, wallets } = balances || {};
    if (!vaults || !wallets) return [];

    return [
      ...vaults.map((item) => ({
        address: item.address,
        name: item.name,
        balance: item.balance,
      })),
      ...wallets.map((item) => ({
        address: item.joinnAddress,
        name: item.name,
        balance: item.balance,
      })),
      {
        address: info?.immersveAddress,
        name: "Spending Account",
        //TODO: Check if this is the correct balance
        balance: spendingAccount?.balance ?? 0,
      },
    ];
  }, [balances, info?.immersveAddress]);

  const getContractFromName = useCallback(
    (name: string) => {
      if (name.toLowerCase() === "all") return;
      const addressWithName = combinedData.find(
        (item) => item.name.toLowerCase() === name.toLowerCase(),
      );
      return addressWithName?.address;
    },
    [combinedData],
  );

  const getContractName = useCallback(
    (searchAddress?: string) => {
      if (!searchAddress) return;

      const addressData = combinedData.find(
        (item) => item.address?.toLowerCase() === searchAddress.toLowerCase(),
      );

      return addressData?.name || getMaskedAddress(searchAddress);
    },
    [combinedData],
  );

  return {
    combinedData,
    getContractFromName,
    getContractName,
  };
}
