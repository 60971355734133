import { Spinner, Text } from "@/components";
import { Button, Collapsible, CollapsibleContent } from "@/components/ui";
import { useIcons } from "@/hooks";
import { cn, getMaskedAddress } from "@/lib/utils";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { useState } from "react";

type ProfileProps = {
  name: string;
  email: string;
  address: string;
};

export function ProfileName(props: ProfileProps) {
  const { name, email, address } = props;

  const { EditIcon, CloseIcon, CheckIcon } = useIcons();
  const [updateUserProfile, userResult] = useUpdateUserProfileMutation();

  const [isNameEditable, setIsNameEditable] = useState(false);
  const [inputFieldValue, setInputFieldValue] = useState("");

  async function handleNicknameSave() {
    await updateUserProfile({
      name: inputFieldValue,
    });
    setIsNameEditable(false);
  }

  return (
    <div className="w-full pl-3">
      <div
        className={cn(
          "flex w-full items-center justify-between border-b border-b-transparent",
          isNameEditable && "border-b-accent",
        )}
      >
        <input
          readOnly={!isNameEditable}
          placeholder="Enter Nickname here..."
          value={
            isNameEditable ? inputFieldValue : name || getMaskedAddress(address)
          }
          className="w-full bg-transparent"
          onChange={(event) => {
            const value = event.target.value;
            setInputFieldValue(value);
          }}
        />
        <div className="flex items-center gap-1 pl-2">
          <Collapsible
            open={
              isNameEditable &&
              inputFieldValue !== name &&
              !userResult.isLoading
            }
          >
            <CollapsibleContent>
              <Button className="mt-2" onClick={handleNicknameSave}>
                <CheckIcon size={18} />
              </Button>
            </CollapsibleContent>
          </Collapsible>
          <Collapsible open={!userResult.isLoading}>
            <CollapsibleContent>
              <Button
                className="mt-2"
                onClick={() => {
                  setIsNameEditable(!isNameEditable);
                  setInputFieldValue(name ?? "");
                }}
              >
                {isNameEditable ? <CloseIcon size={18} /> : <EditIcon />}
              </Button>
            </CollapsibleContent>
          </Collapsible>
          <Collapsible open={userResult.isLoading}>
            <CollapsibleContent>
              <Spinner />
            </CollapsibleContent>
          </Collapsible>
        </div>
      </div>
      <Text size="md">{email}</Text>
    </div>
  );
}
