import { Text } from "@/components";

export function SuccessPage() {
  return (
    <div className="flex max-w-screen-sm flex-col gap-10 pt-10 text-center max-md:text-start">
      <Text size="2xl">🎉 Congratulations!</Text>
      <Text size="md">
        You have successfully completed the Mastercard Activation and KYC
        process. You can now enjoy the services offered by the Spending Account!{" "}
      </Text>
    </div>
  );
}
