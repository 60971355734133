import { Card, Separator } from "../ui";

type ActivityCardProps = {
  title: string;
  children?: React.ReactNode;
  route?: string;
};

export function ActivityCard({ title, children }: Readonly<ActivityCardProps>) {
  return (
    <Card size="md" radius="md" className="flex flex-col p-0">
      <h2 className="p-5 text-lg text-primary max-sm:p-4">{title}</h2>
      <Separator />
      <div className="flex flex-col gap-2 p-5 max-sm:p-4">{children}</div>
    </Card>
  );
}
