import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";

type KycState = {
  isUserOnboardingEnabled?: boolean;
  // Note: Add other properties here for KYC as needed
};

const initialState: KycState = {
  isUserOnboardingEnabled: false,
};

export const kycState = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    enableOnboarding: (state) => {
      state.isUserOnboardingEnabled = true;
    },

    disableOnboarding: (state) => {
      state.isUserOnboardingEnabled = false;
    },
  },
});

export function useKyc() {
  const dispatch = useDispatch();
  const { actions } = kycState;

  const isOnboardingEnabled = useSelector((state: RootState) => {
    return state.kycState.isUserOnboardingEnabled;
  });

  function enableOnboarding() {
    dispatch(actions.enableOnboarding());
  }

  function disableOnboarding() {
    dispatch(actions.disableOnboarding());
  }

  return {
    enableOnboarding,
    disableOnboarding,
    isOnboardingEnabled,
  };
}
