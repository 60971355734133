import { Button } from "@/components/ui";
import { useSpending } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { X } from "lucide-react";

export function ImmersveKycFlow() {
  const { kyc } = useSpending();
  const { closeModal } = useModalState();

  return (
    <div className="h-[85vh] rounded-sm bg-white">
      <div className="absolute right-8 z-20 flex w-full justify-end p-2">
        <Button
          onClick={() => {
            closeModal();
          }}
        >
          <X color="hsl(var(--accent))" size={32} />
        </Button>
      </div>
      {kyc?.params?.kycUrl && (
        <iframe
          src={`${kyc?.params?.kycUrl}`}
          allow="camera *; fullscreen *"
          sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          title="Immersve KYC"
          className="relative z-10 h-full w-full border-none"
        />
      )}
    </div>
  );
}
