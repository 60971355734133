import * as React from "react";
import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";

const gradientBoxVariants = cva(
  "bg-gradient-to-b p-px shadow-md shadow-black/50 w-full max-md:[clip-path:none]",
  {
    variants: {
      variant: {
        default: "from-secondary to-primary",
        secondary:
          "max-md:shadow-none group secondary from-secondary/50 from-25% via-primary/50 via-50% to-secondary/50 to-100% h-full max-md:p-0",
      },
      radius: {
        default: "rounded-sm",
        lg: "rounded-lg",
      },
      clip: {
        default: "",
        "top-left":
          "[clip-path:polygon(30px_0%,100%_0,100%_100%,0_100%,0%_30px)]",
        left: "[clip-path:polygon(30px_0%,100%_0,100%_100%,30px_100%,0%_calc(100%_-_30px),0%_30px)]",
      },
      size: {},
    },
    defaultVariants: {
      variant: "default",
      clip: "default",
      radius: "default",
    },
  },
);

const gradientInnerBoxVariants = cva(
  "w-full h-[inherit] rounded-[inherit] [clip-path:inherit]",
  {
    variants: {
      variant: {
        default: "bg-background",
        secondary: "bg-card",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const boxContentVariants = cva(
  "rounded-[inherit] h-[inherit] group-[.secondary]:max-md:p-0 grid gap-5",
  {
    variants: {
      variant: {
        default: "",
        secondary: "",
        button: " bg-black/10 hover:bg-black/50 hover:cursor-pointer",
      },
      padding: {
        default: "p-5",
        sm: "",
        md: "py-2.5 px-5",
        lg: "p-8",
        wide: "px-12 py-5",
        none: "p-0",
      },
    },
    defaultVariants: {
      padding: "default",
      variant: "default",
    },
  },
);

interface BoxProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof gradientBoxVariants> {
  children?: React.ReactNode;
  innerBox?: string;
}

const Box = React.forwardRef<HTMLDivElement, BoxProps>(
  (
    { className, variant, size, innerBox, clip, radius, children, ...props },
    ref,
  ) => {
    return (
      <div
        className={cn(
          gradientBoxVariants({ variant, size, radius, className, clip }),
        )}
        ref={ref}
        {...props}
      >
        <div
          className={cn(
            gradientInnerBoxVariants({ variant, className: innerBox }),
          )}
        >
          {children}
        </div>
      </div>
    );
  },
);
Box.displayName = "Box";

interface BoxContentProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof boxContentVariants> {
  children?: React.ReactNode;
}

const BoxContent = React.forwardRef<HTMLDivElement, BoxContentProps>(
  ({ className, variant, padding, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(boxContentVariants({ variant, padding, className }))}
        {...props}
      >
        {children}
      </div>
    );
  },
);
BoxContent.displayName = "BoxContent";

export { Box, BoxContent, type BoxProps, type BoxContentProps };
