import { Button } from "@/components/ui";
import { removeCommaSeparator, sanitizeNumber } from "@/lib/utils";
import { useState } from "react";
import { InputField } from "../inputField";

type TransferInputProps = {
  onChange: (value: number) => void;
  value: number;
  maxValue?: number;
};

export function TransferInput({
  onChange,
  value,
  maxValue = 0,
}: Readonly<TransferInputProps>) {
  const initialValue = value ? sanitizeNumber(value.toString()) : "";
  const [inputValue, setInputValue] = useState(initialValue);

  function onMaxClick() {
    setInputValue(maxValue.toString());
    onChange(maxValue);
  }

  return (
    <InputField
      className="h-14 pl-4 text-base text-white max-sm:pl-3"
      label="Amount"
      placeholder="0.00"
      min={0}
      type="text"
      onPaste={(e) => {
        e.preventDefault();
      }}
      onKeyDown={handleOnKeyPress}
      onChange={(e) => {
        const value = e.target.value;
        const sanitized = sanitizeNumber(removeCommaSeparator(value));
        setInputValue(sanitized);
        onChange(Number(value));
      }}
      value={inputValue}
      endAdornment={
        <div className="flex items-center">
          <p className="text-primary-dark h-full pl-3 pr-3 text-accent">USDC</p>
          <Button
            onClick={onMaxClick}
            type="button"
            className="h-14 rounded-none rounded-r bg-secondary/10 px-4"
          >
            MAX
          </Button>
        </div>
      }
    />
  );
}

function handleOnKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
  const validKeys = [
    ".",
    "Backspace",
    "Tab",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
  ];

  const value = e.currentTarget.value;
  const periodCount = value.split(".");

  // allow number only
  const isKeyValid = RegExp(/\d/).exec(e.key)?.input;

  // if key is not a digit or not in the validKeys, prevent
  if (!isKeyValid && !validKeys.includes(e.key)) {
    e.preventDefault();
  }

  // do not allow multiple period
  if (periodCount.length > 1 && e.key === ".") {
    e.preventDefault();
  }

  // allow 5 decimal places
  if (!validKeys.includes(e.key)) {
    const decimals = value.split(".");

    if (periodCount.length > 1) {
      const decimalLength = decimals[1].length;
      if (decimalLength > 4) {
        e.preventDefault();
      }
    }
  }
}

function handleOnKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
  const input = e.target as HTMLInputElement;
  const inputKey = e.key;
  const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"];

  const [integerPart, decimalPart = ""] = input.value.split(".");

  if (
    (inputKey < "0" || inputKey > "9") &&
    inputKey !== "." &&
    inputKey !== "," &&
    !allowedKeys.includes(inputKey)
  ) {
    e.preventDefault();
    return;
  }

  if (inputKey === "," || inputKey === ".") {
    e.preventDefault();
    if (!input.value.includes(".")) {
      const cursorPosition = input.selectionStart ?? 0;
      const newValue =
        input.value.slice(0, cursorPosition) +
        "." +
        input.value.slice(cursorPosition);
      input.value = newValue;
      input.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
    }
    return;
  }

  if (
    integerPart.length >= 11 &&
    inputKey !== "Backspace" &&
    !allowedKeys.includes(inputKey)
  ) {
    e.preventDefault();
    return;
  }

  if (decimalPart.length >= 2 && !allowedKeys.includes(inputKey)) {
    e.preventDefault();
    return;
  }

  const currentValue =
    integerPart + (decimalPart.length > 0 ? "." + decimalPart : "");
  console.log("currentValue", currentValue);
}
