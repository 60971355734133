import { customPostQueryFn } from "@/services/customQuery";
import {
  AuthRequest,
  AuthResponse,
  ChallengeRequest,
  ChallengeResponse,
  LinkAccountResp,
} from "@/services/interfaces";
import { api } from "../baseApi";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generateChallenge: builder.mutation<ChallengeResponse, ChallengeRequest>({
      query: ({ ...request }) => ({
        url: "auth/generate-challenge",
        method: "POST",
        data: request,
      }),
    }),

    generateToken: builder.mutation<AuthResponse, AuthRequest>({
      queryFn: async ({ ...request }) => {
        const response = await customPostQueryFn({
          url: "auth/login",
          data: request,
        });

        if (response.data) {
          localStorage.setItem(
            "accessToken",
            (response.data as AuthResponse).token,
          );
        }

        return response;
      },
    }),

    /**
     * What does the /auth/switch-account does?
     * And why does the swagger not have an address parameter?
     */
    switchAccount: builder.mutation<AuthResponse, string>({
      queryFn: async (address) => {
        const response = await customPostQueryFn({
          url: "auth/switch-account",
          data: { address: address.toLowerCase() },
        });

        if (response.data) {
          localStorage.setItem(
            "accessToken",
            (response.data as AuthResponse).token,
          );
        }

        return response;
      },
    }),
    linkAccount: builder.mutation<LinkAccountResp, AuthRequest>({
      queryFn: async ({ address, nonce, signature }) => {
        const response = await customPostQueryFn({
          url: "auth/link-account",
          method: "POST",
          data: { address: address.toLowerCase(), nonce, signature },
        });
        return response;
      },
    }),
  }),
});

export const {
  useGenerateChallengeMutation,
  useGenerateTokenMutation,
  useSwitchAccountMutation,
  useLinkAccountMutation,
} = authApi;
