import { useDashboard, useNetworkConfig } from "@/hooks";
import { ChainNotSupported } from "@/pages";
import ModalContainer from "@/pages/Modal/ModalContainer";
import { useSwitchAccountMutation } from "@/redux/auth/auth.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { useUser } from "@/redux/user/user.slice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAccount } from "wagmi";
import PageFooter from "./Footer";
import PageNavigation from "./Navigation";

export default function RootLayout() {
  const { isChainSupported } = useNetworkConfig();
  const { toggleModal, closeModal, isModalOpen } = useModalState();
  const { address, isConnected } = useAccount();
  const { lastLoggedInWallet, setLastLoggedInWallet } = useUser();
  const { userWallets } = useDashboard();
  const [switchAccount] = useSwitchAccountMutation();

  async function handleSwitchAccount(address?: string) {
    try {
      if (!address) throw new Error("Address is required");
      await switchAccount(address);
      setLastLoggedInWallet(address);
    } catch (error) {
      console.error("Failed to switch account", error);
    }
  }

  useEffect(() => {
    if (!isChainSupported || !userWallets?.joinnWallets) return;

    const linkedWallet = Object.values(userWallets.joinnWallets).find(
      (wallet) => wallet?.owner.toLowerCase() === address?.toLowerCase(),
    );

    if (!linkedWallet) {
      toggleModal({
        id: "add-wallet-action",
        isCloseEnabled: false,
        isClickawayDisabled: true,
      });
    } else {
      if (isModalOpen) closeModal();
      handleSwitchAccount(address);
    }
  }, [isChainSupported, address, userWallets]);

  if (!isChainSupported && isConnected) {
    return (
      <div className="min-h-screen bg-background p-5 max-md:bg-card max-md:p-3">
        <ChainNotSupported />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background p-5 max-md:bg-card max-md:p-3">
      <div className="m-auto max-w-screen-2xl">
        <PageNavigation />
        <Outlet />
        <ModalContainer />
        <PageFooter />
      </div>
    </div>
  );
}
