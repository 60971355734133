import { Text } from "@/components";
import { useIcons } from "@/hooks";

type CardHeaderProps = {
  isFrontCard: boolean;
  expiry?: string;
  isConnected: boolean;
};

export function CardHeader({
  isFrontCard,
  expiry,
  isConnected,
}: Readonly<CardHeaderProps>) {
  const { JoinnIcon } = useIcons();

  return (
    <div>
      {isFrontCard ? (
        <div>
          <Text variant="label" size="sm">
            Expiry
          </Text>
          <Text variant="default" className="font-medium">
            {isConnected ? expiry : "**/**"}
          </Text>
        </div>
      ) : (
        <JoinnIcon className="w-12 opacity-100" />
      )}
    </div>
  );
}
