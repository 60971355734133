import VanEckIcon from "@/assets/partners/vanEck.svg";
import { Text } from "@/components";
import {
  AssetCard,
  HistoricChart,
  HoldingsCard,
  TransactionPanel,
  VaultOverviewCard,
} from "@/components/accountInfo";
import { Card } from "@/components/ui";
import { AAVE_AUDIT, BDC_INCOME, QUICK_SWAP_AUDIT } from "@/const";
import { BDC_INCOME_ASSETS } from "@/const/assets";
import { useAccount } from "wagmi";
import { AccountPageProps } from "../AccountInformation";

export function BdcIncome(props: Readonly<AccountPageProps>) {
  const { balance, pricePerShare, address, strategyAddress, isKYCApproved } =
    props;
  const { chain } = useAccount();

  // TODO: Add query to useGetBdcIncome when supported
  // TODO: Dummy data while BDC Income is not supported yet in TRN
  const assetDetails = {
    description: "A token representing 3 year Treasury Bonds + USDC",
    properties: [
      { property_type: "token_address", value: address },
      { property_type: "asset_type", value: "Bond Token + USDC" },
      { property_type: "redemption_asset_ticker", value: "USDC" },
      { property_type: "strategy_address", value: strategyAddress },
      { property_type: "kya_version", value: "1.0" },
      { property_type: "token_standard", value: "SX1411" },
      { property_type: "token_version", value: "2.0" },
      { property_type: "protocols", value: "Aave V3 | Quickswap" },
      { property_type: "aave_audits", value: AAVE_AUDIT },
      { property_type: "aave_audits", value: QUICK_SWAP_AUDIT },
      { property_type: "joinn_audits", value: AAVE_AUDIT },
    ],
  };

  return (
    <>
      <Card
        variant="container"
        radius="lg"
        size="md"
        className="col-span-3 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
      >
        {/* TODO: 0 values are temporary only - fix when graphql is ready */}
        <HoldingsCard
          balance={balance ?? 0}
          sharePrice={pricePerShare ?? 0}
          sharesOfVault="0%"
          weeklyEarned={0}
          totalEarned={0}
        />

        {/* TODO: hard-coded values are temporary only - fix when graphql is ready */}
        <VaultOverviewCard
          icon={
            <img
              src={VanEckIcon}
              alt="VanEck Icon"
              className="h-6 max-sm:h-4"
            />
          }
          tvl={{ value: 10000 }}
          tokenizedSecurity={{
            value: 9000,
            link: `${chain?.blockExplorers?.default.url}/address/${address}`,
          }}
          reserves={{
            value: 1000,
            link: `${chain?.blockExplorers?.default.url}/address/${address}`,
          }}
          reservesTarget="10%"
          liquidity="Instant*"
          info={{
            label: "VanEck BDC Income ETF",
            link: BDC_INCOME,
          }}
          heading={
            <Text size="sm">
              This Tokenized Securities Vault is made up of VanEck’s BDC Income
              ETF + USDC.
            </Text>
          }
        >
          <Text size="sm">
            <span className="font-semibold">
              *Joinn's Tokenized Securities Vaults
            </span>{" "}
            are unique as they provide users with instant liquidity up to the
            value of the total USDC Reserves available at any point in time.
            This value fluctuates every time there is a deposit and/or
            withdrawal from the vault. If the withdrawal amount is greater than
            that of the USDC Reserves Value then the portion over and above the
            USDC Reserves Value will be placed in the order book for execution
            upon either markets reopening or when the broker can next process
            the order on market.
          </Text>
        </VaultOverviewCard>
        <HistoricChart accountId={address} />
      </Card>
      <Card
        className="col-span-2 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
        variant="container"
        radius="lg"
        size="md"
      >
        {isKYCApproved && <TransactionPanel address={address} />}
        <AssetCard fields={BDC_INCOME_ASSETS} asset={assetDetails} />
      </Card>
    </>
  );
}
