import { theRootNetwork } from "@/chains/theRootNetwork.chain";
import { config } from "@/config";
import { NetworkName } from "@therootnetwork/api";
import { polygon, polygonAmoy, rootPorcini } from "viem/chains";

import Polygon from "@/assets/chains/polygon.svg";
import Root from "@/assets/chains/root.svg";
import Unsupported from "@/assets/icons/unsupportedchain.svg";
import { useMemo } from "react";
import { useAccount } from "wagmi";

interface ConfigProps {
  activeChain?: number;
}

export function useNetworkConfig(props?: ConfigProps) {
  const { chainId, isConnected } = useAccount();

  const getIcon = () => {
    switch (props?.activeChain) {
      case polygonAmoy.id:
      case polygon.id:
        return Polygon;
      case rootPorcini.id:
      case theRootNetwork.id:
        return Root;
      default:
        return Unsupported;
    }
  };

  function getSupportedChains() {
    const isTestnet = config.BLOCKCHAIN_NET === "testnet";

    const testnets = [
      { image: Polygon, ...polygonAmoy },
      { image: Root, ...rootPorcini },
    ];
    const mainnets = [
      { image: Polygon, ...polygon },
      { image: Root, ...theRootNetwork },
    ];

    return isTestnet ? testnets : mainnets;
  }

  const isChainSupported = useMemo(() => {
    if (isConnected && chainId)
      return getSupportedChains().some((chain) => chain.id === chainId);
  }, [chainId]);

  const isChainSupportedForFp = useMemo(() => {
    if (!chainId) return false;
    const fpChainsSupported: number[] =
      config.BLOCKCHAIN_NET === "mainnet"
        ? [theRootNetwork.id]
        : [rootPorcini.id];
    return fpChainsSupported.includes(chainId);
  }, [chainId, config.BLOCKCHAIN_NET]);

  function getRootNetworkConfig() {
    const chain = config.BLOCKCHAIN_NET;

    switch (chain) {
      // Testnet - Porcini
      case "testnet":
        return {
          network: "porcini" as NetworkName,
          ...rootPorcini,
        };
      // Mainnet - Root
      case "mainnet":
      default:
        return {
          ...theRootNetwork,
          network: "root" as NetworkName,
        };
    }
  }

  return {
    supportedChains: getSupportedChains(),
    isChainSupported,
    isChainSupportedForFp,
    chainImage: getIcon(),
    rootConfig: getRootNetworkConfig(),
  };
}
