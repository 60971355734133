import { AutoComplete, Option, Text } from "@/components";
import { Button, Card, Checkbox } from "@/components/ui";
import { COUNTRIES } from "@/const";
import { Country } from "@/const/countries";
import { useKyc } from "@/redux/kyc/kyc.slice";
import { CheckedState } from "@radix-ui/react-checkbox";
import { useState } from "react";

type SupportedRegions = {
  children?: React.ReactNode;
  regions?: Country[];
};

export function SupportedRegions(props: Readonly<SupportedRegions>) {
  const { children, regions } = props;

  const [value, setValue] = useState<Option>({ label: "" });
  const [isChecked, setIsChecked] = useState<CheckedState>(false);
  const { enableOnboarding, isOnboardingEnabled } = useKyc();

  return (
    <Card radius="lg">
      <div className="relative z-10 flex w-full flex-wrap items-center gap-8">
        <div className="grid max-w-[900px] flex-[1_1_60%] gap-4">
          <Text variant="primary" size="2xl" className="pb-3">
            Before you Start
          </Text>
          {children}
        </div>
        <Card size="md" className="h-min flex-[1_0_36%] bg-black/25 max-sm:p-2">
          <AutoComplete
            placeHolder="All Supported Countries"
            value={value}
            options={regions || COUNTRIES}
            disabled={isOnboardingEnabled}
            onSelect={(value: Option) => {
              setValue(value);
            }}
          />
          <div className="flex gap-4 px-2 pt-3">
            <Checkbox
              disabled={isOnboardingEnabled}
              checked={isChecked || isOnboardingEnabled}
              onCheckedChange={setIsChecked}
            />
            <Text variant="label" size="sm">
              I confirm that all information I provide is truthful and complete,
              and that I am not a US person or hold identity from other
              countries not supported.
            </Text>
          </div>
          <div className="flex justify-end pt-5">
            <Button
              size="sm"
              variant={isOnboardingEnabled ? "success" : "primary"}
              className="rounded"
              disabled={!isChecked || value.label === "" || isOnboardingEnabled}
              onClick={() => {
                enableOnboarding();
              }}
            >
              {isOnboardingEnabled ? "Confirmed" : "Confirm"}
            </Button>
          </div>
        </Card>
      </div>
    </Card>
  );
}
