import { config } from "@/config";
import axios from "axios";
import { toast } from "./components/hooks/use-toast";

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/connect";
    }

    if (error.status !== 401) {
      toast({
        title: "An error has occurred!",
        description: error.message,
        variant: "error",
      });
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
