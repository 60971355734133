import { Card, Separator, Tabs, TabsList, TabsTrigger } from "@/components/ui";
import { Transactions } from "@/const";
import { Deposit, Transfer, Withdraw } from "@/pages/Transactions";
import { useState } from "react";

export type TransactionPanelProps = {
  address: string;
};

export function TransactionPanel(props: Readonly<TransactionPanelProps>) {
  const { address } = props;
  const [activeTab, setActiveTab] = useState<Transactions>(
    Transactions.DEPOSIT,
  );

  return (
    <Card className="rounded-lg p-0 max-sm:p-0">
      <Tabs value={activeTab}>
        <TabsList className="grid grid-flow-col justify-start rounded border-none bg-transparent px-0 max-sm:flex">
          {Object.entries(Transactions).map((type) => {
            return (
              type[1] !== Transactions.ALL && (
                <TabsTrigger
                  key={type[0]}
                  value={type[1]}
                  onClick={() => {
                    const value = type[1] as Transactions;
                    setActiveTab(value);
                  }}
                  className="m-0 rounded-none border-none bg-background p-4 data-[state=active]:bg-black/75 [&:first-of-type]:rounded-tl-lg max-sm:[&:last-of-type]:rounded-tr-lg"
                >
                  {type[1]}
                </TabsTrigger>
              )
            );
          })}
        </TabsList>
        <Separator />
        <div className="overflow-hidden rounded-lg p-5 max-sm:p-0">
          {activeTab === Transactions.DEPOSIT && (
            <Deposit fromAddress={address} type="panel" />
          )}
          {activeTab === Transactions.WITHDRAW && (
            <Withdraw fromAddress={address} type="panel" />
          )}
          {activeTab === Transactions.TRANSFER && (
            <Transfer fromAddress={address} type="panel" />
          )}
        </div>
      </Tabs>
    </Card>
  );
}
