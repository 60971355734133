export enum KYCStatus {
  IN_PROGRESS = "IN_PROGRESS",
  AWAITING_DATA = "AWAITING_DATA",
  IN_VERIFICATION = "IN_VERIFICATION",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  ERROR = "ERROR",
}

export enum StepStatus {
  NOT_STARTED = "not started",
  SUCCESS = "success",
  LOADING = "loading",
  ERROR = "error",
}

export enum ImmersveStageStatus {
  OK = "ok",
  ACTION_REQUIRED = "action-required",
  PENDING = "pending",
  BLOCKED = "blocked",
}

export enum ImmersveKycStatus {
  CREATED = "created",
  PENDING = "pending",
  PASSING = "passing",
  PASSED = "passed",
  FAILING = "failing",
  FAILED = "failed",
  NOT_STARTED = "not_started",
}

export enum ImmersveStage {
  FUNDING = "funding",
  KYC = "kyc",
  AML = "aml",
}
