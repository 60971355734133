import TransferIcon from "@/assets/icons/transfer.svg";
import { Text } from "@/components";
import { Button, Card, Separator, Skeleton } from "@/components/ui";

export function EarnItemSkeleton() {
  return [...Array(3)].map((_, index) => {
    return (
      <Card
        variant="secondary"
        key={`Skeleton-Earn-${index + 1}`}
        className="grid grid-cols-12 gap-4 px-7 py-5 max-sm:p-4"
      >
        <div className="col-span-12 grid gap-1 sm:col-span-6 xl:col-span-4">
          <div className="relative">
            <Skeleton className="absolute block h-full w-full" />
            <Text size="xl" className="invisible">
              Earn Account
            </Text>
          </div>
          <div className="relative">
            <Skeleton className="absolute block h-full w-full" />
            <Text className="invisible">Account Information</Text>
          </div>
        </div>
        <div className="col-span-12 grid grid-cols-12 sm:col-span-6 xl:col-span-4">
          <div className="col-span-6 flex gap-5 sm:col-span-6 xl:col-span-6">
            <Separator
              orientation="vertical"
              className="h-full max-sm:hidden"
            />
            <div>
              <div className="relative">
                <Skeleton className="absolute block h-full w-full" />
                <Text size="xl" className="invisible">
                  0.00
                </Text>
              </div>
              <Text size="sm" variant="label">
                APY
              </Text>
            </div>
          </div>
          <div className="col-span-6 sm:col-span-6 xl:col-span-6">
            <div className="relative">
              <Skeleton className="absolute block h-full w-full" />
              <Text size="xl" className="invisible">
                0.00
              </Text>
            </div>
            <Text variant="label" size="sm">
              Balance
            </Text>
          </div>
        </div>
        <div className="col-span-12 xl:col-span-4">
          <Separator className="hidden max-xl:flex" />
          <div className="xs:justify-start flex items-center gap-2 max-xl:pt-4 min-[360px]:justify-end">
            <Button variant="secondary" size="sm" disabled>
              Deposit
            </Button>
            <Button size="sm" variant="primary" disabled>
              Withdraw
            </Button>
            <Button size="sm" variant="primary" disabled className="py-2.5">
              <img src={TransferIcon} alt="Transfer Icon" width={24} />
            </Button>
          </div>
        </div>
      </Card>
    );
  });
}
