import { defineChain } from 'viem'

export const trnPorcini = defineChain({
    id: 7672,
    name: "TRN Porcini",
    network: "root-porcini",
    nativeCurrency: { name: "XRP", symbol: "XRP", decimals: 18 },
    rpcUrls: {
        archive: {
            http: ["https://porcini.rootnet.app/archive"],
            webSocket: ["wss://porcini.rootnet.app/archive/ws"],
        },
        default: {
            http: ["https://porcini.rootnet.app"],
        },
        public: {
            http: ["https://porcini.rootnet.app"],
            webSocket: ["wss://porcini.rootnet.app/ws"],
        },
    },
    blockExplorers: {
        default: {
            name: "rootPorcini",
            url: "https://porcini.rootscan.io/",
        },
    },
    contracts: {},
    testnet: true,
});

/**
 * Use this config to programatically add this network to a wallet
 */
export const porciniWalletConfig = {
    chainId: "0x1DF8",
    chainName: 'The Root Network - Porcini Testnet',
    nativeCurrency: { name: 'Ripple', symbol: 'XRP', decimals: 18 },
    rpcUrls: ['https://porcini.rootnet.app/archive'],
    blockExplorerUrls: ['https://porcini.rootscan.io/'],
}