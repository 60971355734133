import { api } from "../baseApi";
import { ApyVault, Info } from "@/services/interfaces";
import { gql } from "graphql-request";

export const infoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * What does the info here represents?
     * Vault Info? User Info?
     */
    info: builder.query<Info, number | undefined>({
      query: (chainId) => ({
        url: `info/${chainId}`,
        method: "GET",
      }),
    }),

    apyForVaults: builder.query<ApyVault[], void>({
      query: () => ({
        request: "graphql",
        body: gql`
          query {
            VaultApys(sort: TIMESTAMP_DESC, limit: 2) {
              vault
              timestamp
              apy1d
              apy3d
              apy7d
              apy14d
              name
              symbol
            }
          }
        `,
      }),
    }),
  }),
});

export const { useInfoQuery, useLazyInfoQuery } = infoApi;
