"use client";

import { useToast } from "@/components/hooks/use-toast";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useIcons } from "@/hooks/useIcons";

export function Toaster() {
  const { toasts } = useToast();
  const { SuccessIcon, ErrorIcon, WarningIcon, InfoIcon } = useIcons();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        variant,
        ...props
      }) {
        return (
          <Toast key={id} variant={variant} {...props}>
            <div className="flex items-start gap-3">
              {variant === "success" && <SuccessIcon />}
              {variant === "warning" && <WarningIcon />}
              {variant === "info" && <InfoIcon />}
              {(variant === "error" || variant === "destructive") && (
                <ErrorIcon />
              )}
              <div>
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>
            <ToastClose />
            {action}
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
