export enum Transactions {
  ALL = "All",
  DEPOSIT = "Deposit",
  WITHDRAW = "Withdraw",
  TRANSFER = "Transfer",
}

export enum TransactionsStatus {
  IDLE = "idle",
  PENDING = "pending",
  PROCESSING = "processing",
  SUCCESS = "success",
  ERROR = "error",
}
