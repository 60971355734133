import BlackRockIcon from "@/assets/partners/blackrock.svg";
import { Text } from "@/components";
import {
  AssetCard,
  HistoricChart,
  HoldingsCard,
  TransactionPanel,
  VaultOverviewCard,
} from "@/components/accountInfo";
import { Card } from "@/components/ui";
import { SWARM_AUDIT, US_TREASURY_BONDS } from "@/const";
import { US_TREASURY_ASSETS } from "@/const/assets";
import { useGetUsTreasuryAssetQuery } from "@/redux/accountInfo/asset.api";
import { Asset } from "@/services/interfaces/asset";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { AccountPageProps } from "../AccountInformation";

export function UsTreasuryBond(props: Readonly<AccountPageProps>) {
  const { balance, pricePerShare, address, isKYCApproved } = props;
  const { data: asset, isSuccess } = useGetUsTreasuryAssetQuery();
  const { chain } = useAccount();

  const [assetDetails, setAssetDetails] = useState<Asset | undefined>();

  useEffect(() => {
    if (isSuccess) {
      const assetData = asset
        ? {
            ...asset,
            properties: [
              ...asset.properties,
              { property_type: "swarm_audits", value: SWARM_AUDIT },
              { property_type: "joinn_audits", value: SWARM_AUDIT },
            ],
          }
        : asset;

      setAssetDetails(assetData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      {/* LEFT PANEL */}
      <Card
        variant="container"
        radius="lg"
        size="md"
        className="col-span-3 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
      >
        {/* TODO: 0 values are temporary only - fix when graphql is ready */}
        <HoldingsCard
          balance={balance ?? 0}
          sharePrice={pricePerShare ?? 0}
          sharesOfVault="0%"
          weeklyEarned={0}
          totalEarned={0}
        />

        {/* TODO: hard-coded values are temporary only - fix when graphql is ready */}
        <VaultOverviewCard
          icon={
            <img
              src={BlackRockIcon}
              alt="BlackRock Icon"
              className="h-6 max-sm:h-4"
            />
          }
          tvl={{ value: 10000 }}
          tokenizedSecurity={{
            value: 9000,
            link: `${chain?.blockExplorers?.default.url}/address/${address}`,
          }}
          reserves={{
            value: 1000,
            link: `${chain?.blockExplorers?.default.url}/address/${address}`,
          }}
          reservesTarget="10%"
          liquidity="Instant*"
          info={{
            label: "iShares $ Treasury Bond 0-1yr UCITS ETF",
            link: US_TREASURY_BONDS,
          }}
          heading={
            <Text size="sm">
              This Tokenized Securities Vault is made up of iShares $ Treasury
              Bond 0-1yr UCITS ETF by Blackrock + USDC.
            </Text>
          }
        >
          <Text size="sm">
            <span className="font-semibold">
              *Joinn's Tokenized Securities Vaults
            </span>{" "}
            are unique as they provide users with instant liquidity up to the
            value of the total USDC Reserves available at any point in time.
            This value fluctuates every time there is a deposit and/or
            withdrawal from the vault. If the withdrawal amount is greater than
            that of the USDC Reserves Value then the portion over and above the
            USDC Reserves Value will be placed in the order book for execution
            upon either markets reopening or when the broker can next process
            the order on market.
          </Text>
        </VaultOverviewCard>
        <HistoricChart accountId={address} />
      </Card>

      {/* RIGHT PANEL */}
      <Card
        className="col-span-2 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
        variant="container"
        radius="lg"
        size="md"
      >
        {isKYCApproved && <TransactionPanel address={address} />}
        <AssetCard fields={US_TREASURY_ASSETS} asset={assetDetails} />
      </Card>
    </>
  );
}
