import NavigationMenu from "../Menu";
import DropdownMenu from "./DropdownMenu";
import Network from "./Network";
import UserProfile from "./UserProfile";
import WalletAccount from "./WalletAccount";

export default function Toolbar() {
  return (
    <div className="flex items-center justify-center gap-3.5">
      <NavigationMenu />
      <div className="flex items-center gap-3.5 max-md:flex-wrap-reverse">
        <div className="flex basis-full items-center gap-3.5 max-md:shrink-0 max-md:grow">
          <WalletAccount />
          <Network />
          <DropdownMenu />
        </div>
        <div className="flex shrink-0 grow items-center max-md:hidden">
          <UserProfile />
        </div>
      </div>
    </div>
  );
}
