import { Transactions } from "@/const";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { useNavigate } from "react-router-dom";

export function useTransactionNav() {
  const navigate = useNavigate();
  const { toggleModal } = useModalState();

  function handleTransactionNav(type: Transactions, vaultAddress?: string) {
    toggleModal({
      title: type,
      id: type.toLowerCase(),
      redirectBack: true,
    });
    navigate(Routes.TRANSACTION.replace(":type", type), {
      state: { vaultAddress },
    });
  }

  return { handleTransactionNav };
}
