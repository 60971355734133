import { ImmersveStageStatus } from "@/const/kycStatus";
import { useDashboard, useImmersve, useMastercard, useSpending } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { Routes } from "@/routes/routers";
import { isNull } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Mastercard from "../Mastercard/Mastercard";
import { MastercardActivation } from "../Mastercard/MastercardActivation";
import { PreKycSpending } from "../SpendingAccount/PreKycSpending";
import SpendingAccount from "../SpendingAccount/SpendingAccount";

export default function SpendingAccountSection() {
  const { action, state } = useImmersve();
  const { kyc, isSpendingHidden, hasFundingSource } = useSpending();
  const { info, userProfile } = useDashboard();
  const { toggleModal } = useModalState();
  const { mastercard } = useMastercard();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  useEffect(() => {
    console.log("------------------------");
    console.log("kyc:: ", kyc);
    console.log("haveMastercard:: ", userProfile?.haveMastercard);
    console.log("hasMastercard:: ", mastercard.hasMastercard);
    console.log("------------------------");
  }, [kyc]);

  const location = useLocation();
  const [isConnecting, setIsConnecting] = useState(false);

  const isLoggingIn = isConnecting || !info;

  // This is on initial load only - toggle modal when redirected from Immersve KYC Flow
  useEffect(() => {
    if (location.pathname === Routes.MASTERCARD_ACTIVATION) {
      toggleModal({
        id: "pre-kyc-modal",
        isFullWidth: true,
        redirectBack: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function handleLogin() {
      if (info && !isSpendingHidden && state.isChainImmersveAllowed) {
        setIsConnecting(true);
        await action.login();
        setIsConnecting(false);
      }
    }

    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  /**
   * This is only a workaround for old users
   * see useMemo below for another reference
   */
  useEffect(() => {
    if (!userProfile?.haveMastercard && mastercard.hasMastercard) {
      updateUserProfile({
        haveMastercard: true,
      });
    } else if (
      kyc &&
      kyc?.status !== ImmersveStageStatus.ACTION_REQUIRED &&
      isNull(userProfile?.haveMastercard) &&
      hasFundingSource
    ) {
      updateUserProfile({
        haveMastercard: false,
      });
    }
  }, [mastercard.hasMastercard, kyc]);

  const content = useMemo(() => {
    /**
     * use haveMastercard as a work-around,
     * Initially, haveMastercard will be null,
     * when a user triggers the immersve KYC flow, haveMastercard will be set to false
     * when a user triggers the creation of mastercard, haveMastercard will be set to true
     */
    if (userProfile?.haveMastercard && kyc?.status === ImmersveStageStatus.OK) {
      return (
        <>
          <SpendingAccount isConnecting={isLoggingIn} />
          <Mastercard />
        </>
      );
    } else if (isNull(userProfile?.haveMastercard)) {
      return <PreKycSpending isConnecting={isLoggingIn} isLoading={!info} />;
    } else {
      return (
        <>
          <SpendingAccount isConnecting={isLoggingIn} />
          <MastercardActivation kyc={kyc} />
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kyc, isLoggingIn, userProfile]);

  if (!state.isChainImmersveAllowed || isSpendingHidden) return false;

  return (
    <div className="flex flex-col gap-4 min-[920px]:max-[1020px]:flex-row lg:xl:flex-row">
      {content}
    </div>
  );
}
