import { Text } from "@/components";

export function SuccessPage() {
  return (
    <div className="flex max-w-screen-sm flex-col gap-10 pt-10 text-center max-md:text-start">
      <Text size="2xl">🎉 Congratulations!</Text>
      <Text size="md">
        You have successfully completed the Onboarding and KYC process and your
        Joinn profile is now verified. All wallets linked to your Joinn profile
        can now enjoy all the products and services on offer.{" "}
      </Text>
    </div>
  );
}
