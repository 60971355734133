import {
  Cell,
  Head,
  Header,
  HistoryDropdownFilter,
  HistoryTile,
  Row,
  Table,
} from "@/components/transactionHistory";
import { HistoryRowSkeleton } from "@/components/transactionHistory/HistoryRowSkeleton";
import { LIST_TRANSACTIONS_RESP_STATUS } from "@/const";
import { useBalances } from "@/hooks";
import { useListTransactionsQuery } from "@/redux/immersve/immersve.api";
import { getImmersveTokenData } from "@/utils";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

export function Mastercard() {
  const immersveTokenData = getImmersveTokenData();
  const { data, isFetching } = useListTransactionsQuery(
    immersveTokenData.cardholderAccountId,
    {
      skip: !immersveTokenData.cardholderAccountId,
    },
  );
  const { spendingAccountBalance } = useBalances();
  const [mastercardHistoryStatusFilter, setMastercardHistoryStatusFilter] =
    useState<LIST_TRANSACTIONS_RESP_STATUS>(LIST_TRANSACTIONS_RESP_STATUS.ALL);

  const mastercardHistory = data?.items;

  const MastercardHistoryStatusOptions = [
    { id: "1", label: LIST_TRANSACTIONS_RESP_STATUS.ALL },
    { id: "2", label: LIST_TRANSACTIONS_RESP_STATUS.INIT },
    { id: "3", label: LIST_TRANSACTIONS_RESP_STATUS.HOLDING },
    { id: "4", label: LIST_TRANSACTIONS_RESP_STATUS.CLEARED },
    { id: "5", label: LIST_TRANSACTIONS_RESP_STATUS.REVERSED },
  ];

  const filteredTransactions = useMemo(() => {
    if (!mastercardHistory) return [];
    const matchesStatusType = mastercardHistory.filter(
      (transaction) =>
        mastercardHistoryStatusFilter === LIST_TRANSACTIONS_RESP_STATUS.ALL ||
        transaction.status.toLowerCase() ===
          mastercardHistoryStatusFilter.toLowerCase(),
    );
    return matchesStatusType;
  }, [mastercardHistory, mastercardHistoryStatusFilter]);

  function noHistoryCases() {
    const noResultsMessage =
      mastercardHistoryStatusFilter === LIST_TRANSACTIONS_RESP_STATUS.ALL
        ? "There is no history yet"
        : "No filtered results";

    return (
      filteredTransactions.length === 0 && (
        <div className="flex w-full justify-center text-accent">
          {noResultsMessage}
        </div>
      )
    );
  }

  return (
    <>
      <HistoryTile balance={spendingAccountBalance} title="Mastercard Balance">
        <HistoryDropdownFilter
          title="Transaction Status"
          label={mastercardHistoryStatusFilter}
          options={MastercardHistoryStatusOptions}
          onSelectOption={(option) =>
            setMastercardHistoryStatusFilter(
              option?.label as LIST_TRANSACTIONS_RESP_STATUS,
            )
          }
        />
      </HistoryTile>

      <Table>
        <Head>
          <Header>Date</Header>
          <Header>Time</Header>
          <Header>Transaction Id</Header>
          <Header>Status</Header>
          <Header>To</Header>
          <Header>Amount</Header>
        </Head>
        {noHistoryCases()}
        {filteredTransactions.map((row) => (
          <Row key={row.id}>
            <Cell caption="Date:" textStyles="text-accent">
              {dayjs(row.transactionDate).format("MM/DD/YYYY")}
            </Cell>
            <Cell caption="Date:" textStyles="text-accent">
              {dayjs(row.transactionDate).format("hh:mm A")}
            </Cell>
            <Cell caption="Transaction">
              <span className="capitalize">{row.id.toLowerCase()}</span>
            </Cell>
            <Cell caption="From:" className="capitalize">
              {row.status}
            </Cell>
            <Cell caption="To: ">{row.cardAcceptor.name}</Cell>
            <Cell caption="Amount: ">{row.amount} USD</Cell>
          </Row>
        ))}
      </Table>
      <HistoryRowSkeleton colsNumber={6} isLoading={isFetching} />
    </>
  );
}
