import { cn } from "@/lib/utils";
import { Text } from "../typography";
import { Card } from "../ui";

type HeadProps = {
  children?: React.ReactNode;
};

export function Table({ children }: Readonly<{ children: React.ReactNode }>) {
  return <div className="flex flex-row flex-wrap gap-1">{children}</div>;
}

export function Head({ children }: Readonly<HeadProps>) {
  return (
    <Card
      variant="secondary"
      className="mb-2 hidden items-center justify-between border-muted px-0 lg:flex"
      size="md"
    >
      {children}
    </Card>
  );
}

type RowProps = {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
};

export function Row({ children, onClick, className }: Readonly<RowProps>) {
  return (
    <Card
      className={cn("flex flex-col lg:flex-row", className)}
      size="sm"
      onClick={onClick}
    >
      {children}
    </Card>
  );
}

export function Header({ children }: Readonly<{ children: React.ReactNode }>) {
  return (
    <div className="w-full border-l border-primary/25 px-8 text-lg text-white [&:first-of-type]:border-0">
      {children}
    </div>
  );
}

export function Cell({
  children,
  caption,
  className,
  captionStyles,
  textStyles,
}: Readonly<{
  children: React.ReactNode;
  caption?: string;
  className?: string;
  captionStyles?: string;
  textStyles?: string;
}>) {
  return (
    <div
      className={cn(
        "mb-3 flex w-full min-w-14 flex-col px-4 lg:mb-0 lg:px-8",
        className,
      )}
    >
      <Text
        size={"sm"}
        variant="accent"
        className={cn("lg:hidden", captionStyles)}
      >
        {caption}
      </Text>
      <div
        className={cn(
          "flex items-center justify-between text-base text-white",
          textStyles,
        )}
      >
        {children}
      </div>
    </div>
  );
}
