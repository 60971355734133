import { Dropdown, Text } from "@/components";
import { Button, Separator } from "@/components/ui";
import { Accounts, AccountsAddressByChain } from "@/const";
import { KYCStatus } from "@/const/kycStatus";
import { useDashboard } from "@/hooks";
import {
  AutomatedLending,
  BdcIncome,
  SpendingAccount,
  UsTreasuryBond,
} from "@/pages/AccountInfo/Account";
import { Routes } from "@/routes/routers";
import { VaultsFormatted } from "@/services/interfaces";
import { MenuOption } from "@/types";
import { ArrowLeft } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount } from "wagmi";

export type AccountPageProps = VaultsFormatted & {
  isKYCApproved?: boolean;
};

type Option = MenuOption & {
  address: string;
};

export function AccountInformation() {
  const { account } = useParams();
  const { balances, userProfile } = useDashboard();
  const { chainId } = useAccount();

  const navigate = useNavigate();

  const accountOptions = useMemo(() => {
    return chainId ? AccountsAddressByChain[chainId] : [];
  }, [chainId]);

  const [activeId, setActiveId] = useState<string>(accountOptions[0].id);

  const isKycApproved = useMemo(() => {
    return userProfile?.kyc?.status === KYCStatus.APPROVED;
  }, [userProfile?.kyc?.status]);

  const vaultData = useMemo(() => {
    return (
      balances?.vaults.filter((vault) => {
        return vault.address === account;
      }) ?? []
    );
  }, [balances, account]);

  const accountInfo = useMemo(() => {
    const infoPage = accountOptions.find((accountOption) => {
      return accountOption.address === account;
    });

    switch (infoPage?.label) {
      case Accounts.US_TREASURY_BONDS:
        return (
          <UsTreasuryBond {...vaultData[0]} isKYCApproved={isKycApproved} />
        );
      case Accounts.BDC_INCOME:
        return <BdcIncome {...vaultData[0]} isKYCApproved={isKycApproved} />;
      case Accounts.AUTOMATED_LENDING:
        return (
          <AutomatedLending {...vaultData[0]} isKYCApproved={isKycApproved} />
        );
      default:
        return (
          <SpendingAccount
            accountId={infoPage?.address ?? ""}
            isKYCApproved={isKycApproved}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountOptions, isKycApproved, vaultData]);
  return (
    <>
      <div className="flex w-full items-center gap-5 px-5 pt-5 max-md:px-0 max-sm:gap-3">
        <Button
          className="rounded-full bg-black/25 p-3 max-md:p-3"
          onClick={() => {
            navigate(Routes.DASHBOARD);
          }}
        >
          <ArrowLeft color="hsl(var(--secondary))" />
        </Button>
        <Dropdown
          options={accountOptions}
          activeId={activeId}
          label={
            <Text
              size="2xl"
              variant="secondary"
              className="text-nowrap hover:text-secondary/75"
            >
              {vaultData.length !== 0
                ? vaultData[0].name
                : Accounts.SPENDING_ACCOUNT}
            </Text>
          }
          triggerProps={{ arrowColor: "hsl(var(--secondary)/0.75)" }}
          onSelectOption={(option) => {
            const account = option as Option;
            setActiveId(option?.id ?? "");
            navigate(
              `/${Routes.ACCOUNT_INFORMATION.replace(":account", account.address)}`,
            );
          }}
        />
        <Separator className="shrink max-sm:hidden" />
      </div>
      <div className="col-auto grid grid-cols-5 gap-6 py-5 max-lg:grid-cols-1 max-lg:gap-4">
        {accountInfo}
      </div>
    </>
  );
}
