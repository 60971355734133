import { Link, Spinner, Text } from "@/components";
import { toast } from "@/components/hooks/use-toast";
import {
  Button,
  Card,
  Checkbox,
  Collapsible,
  CollapsibleContent,
  Separator,
  Skeleton,
} from "@/components/ui";
import { useImmersvePayment } from "@/hooks/useImmersvePayment";
import { cn } from "@/lib/utils";
import { KycProps } from "@/types";
import { useCallback, useEffect, useState } from "react";

type PaymentOption = "USDC" | "USD";

type StatusTypes = "Not Started" | "Pending" | "Success" | "Failed";

type Variants = "pending" | "error" | "success" | "default";

type CardProps = {
  label: string;
  variant: Variants;
};

type ActivationProps = KycProps & {
  refetch: () => void;
};

type PaymentOptionProps = {
  label: string;
  onSelect: () => void;
  isSelected: boolean;
  isStepCompleted: boolean;
  isRecommended?: boolean;
  isLoading?: boolean;
};

export function PaymentOptionCard(props: Readonly<PaymentOptionProps>) {
  const { onSelect, isSelected, isStepCompleted, label, isLoading } = props;

  return (
    <div
      onClick={onSelect}
      aria-hidden="true"
      className={cn(
        "relative flex w-full cursor-pointer items-center justify-between gap-5 rounded-sm border bg-black/25 px-8 py-2 shadow-lg shadow-black/50 transition-all duration-500 hover:scale-105",
        isSelected ? "border-secondary/75" : "border-transparent shadow-md",
        isStepCompleted && "cursor-not-allowed transition-none hover:scale-100",
      )}
    >
      <div className="py-5 text-left">
        <Text size="md" variant="label">
          PAY
        </Text>
        <Text size="3xl" className="font-bold">
          {isLoading ? <Skeleton className="h-10 w-20" /> : (label ?? "")}
        </Text>
      </div>
      <Checkbox
        checked={isSelected}
        className="h-6 w-6 rounded-full data-[state=checked]:border-green-500 data-[state=checked]:bg-green-600"
      />
    </div>
  );
}

export function Activation(props: Readonly<ActivationProps>) {
  const { navButtons, isStepCompleted, refetch } = props;

  const [paymentOption, setPaymentOption] = useState<PaymentOption>("USDC");
  const [feeAmount, setFeeAmount] = useState<string | null>();

  const {
    approvePayment,
    isPaymentError,
    isPaymentSuccess,
    isPaymentLoading,
    getFormattedFeeAmount,
    isFeeAmountLoading,
  } = useImmersvePayment();

  const initialStatus = isStepCompleted ? "Success" : "Not Started";
  const [status, setStatus] = useState<StatusTypes>(initialStatus);

  async function onPayment() {
    setStatus("Not Started");
    await approvePayment();
  }

  const getCardProps = useCallback(() => {
    switch (status) {
      case "Pending":
        return {
          label: "Payment Processing",
          variant: "pending",
        };

      case "Failed":
        return {
          label: "Unable to process payment. Try again!",
          variant: "error",
        };

      case "Success":
        return {
          label: "Payment Completed",
          variant: "success",
        };

      default:
        return {
          label: "Payment Not Started",
          variant: "default",
        };
    }
  }, [status]);

  const { label, variant } = getCardProps() as CardProps;

  useEffect(() => {
    if (isPaymentSuccess) {
      refetch();
      setStatus("Success");
    } else if (isPaymentError) {
      setStatus("Failed");
    }
  }, [isPaymentSuccess, isPaymentError]);

  useEffect(() => {
    (async () => {
      try {
        const amount = await getFormattedFeeAmount();
        setFeeAmount(amount);
      } catch (e) {
        toast({
          variant: "error",
          title: "Error",
          description: "Failed to get fee amount. Try again later.",
        });
      }
    })();
  }, [getFormattedFeeAmount]);

  return (
    <div className="col-auto grid h-full max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons(isPaymentLoading)}
        <Text size="2xl" variant="secondary">
          Step 2: <span className="text-foreground">Activation</span>
        </Text>
        <Text size="sm">
          In order to activate your debit Mastercard, the payment of a one off
          activation fee is required.
        </Text>
        <Text size="sm">
          Please note that this amount will be fully refunded should you not
          successfully pass Step 3.
        </Text>
        <Text size="sm">
          In the event that this occurs, please contact us at{" "}
          <Link to="mailto:hello@joinn.io" className="text-primary underline">
            hello@joinn.io
          </Link>{" "}
          for a full refund of the activation fee.
        </Text>
      </div>

      {status === "Not Started" ? (
        <Card className="flex flex-col justify-between gap-10 text-center">
          <PaymentOptionCard
            isRecommended
            label={feeAmount ? `${feeAmount} USDC` : ""}
            isSelected={paymentOption === "USDC"}
            isStepCompleted={isStepCompleted}
            onSelect={() => {
              if (!isStepCompleted) {
                setPaymentOption("USDC");
              }
            }}
            isLoading={isFeeAmountLoading}
          />
          <div className="flex justify-end">
            <Button
              variant={isStepCompleted ? "success" : "primary"}
              size="md"
              className="gap-0 rounded"
              onClick={onPayment}
              readOnly={isPaymentLoading || isStepCompleted}
            >
              {isStepCompleted ? "Payment completed" : "Proceed to payment"}
              <Collapsible open={isPaymentLoading}>
                <CollapsibleContent>
                  <Spinner className="ml-2 mr-0" />
                </CollapsibleContent>
              </Collapsible>
            </Button>
          </div>
        </Card>
      ) : (
        <Card variant={variant} className="flex flex-col justify-center gap-8">
          <Button
            variant={variant}
            size="md"
            className="w-fit min-w-56 rounded max-md:w-full max-md:text-left"
            readOnly={status !== "Failed"}
            disabled={feeAmount === null}
            onClick={onPayment}
          >
            {label}
          </Button>
          <Separator />
          {status === "Failed" ? (
            <div className="flex flex-col gap-3">
              <Text size="sm" className="text-left font-bold">
                Your payment was unsuccessful, please try again.
              </Text>
              <Text size="sm" className="text-left">
                Please contact{" "}
                <Link
                  to="mailto:hello@joinn.io"
                  className="text-primary underline"
                >
                  hello@joinn.io
                </Link>{" "}
                if assistance is required.
              </Text>
            </div>
          ) : (
            <Text size="sm" className="text-left">
              If you have any problems, please contact{" "}
              <Link
                to="mailto:hello@joinn.io"
                className="text-primary underline"
              >
                hello@joinn.io
              </Link>{" "}
            </Text>
          )}
        </Card>
      )}
    </div>
  );
}
