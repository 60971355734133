import { Text } from "../typography";

const steps = [
  {
    step: 1,
    description:
      "Open your wallet provider and select the wallet you would like to add",
  },
  {
    step: 2,
    description: "Click “Connect”",
  },
  {
    step: 3,
    description:
      "Select “Link Wallet” to add this wallet to your existing profile or select “Login” to login into a new Joinn profile",
  },
  {
    step: 4,
    description: "Sign the message in your wallet",
  },
];

type CardProps = {
  step: number;
  description: string;
  hasSeparator?: boolean;
};
function Card({ step, description, hasSeparator = true }: CardProps) {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-8">
        <div className="h-7 w-7 rounded-full bg-blue-500" />
        <Text size="lg" className="font-bold text-white">
          Step {step}
        </Text>
      </div>
      <div className="gap flex gap-8">
        <div className="flex h-[calc(100%+16px)] min-w-7 flex-col items-center">
          {hasSeparator && (
            <div className="h-full border-l-2 border-dashed border-primary"></div>
          )}
        </div>
        <Text size="sm" className="mb-12 font-normal text-gray-300">
          {description}
        </Text>
      </div>
    </div>
  );
}

export function AddWalletInfo() {
  return (
    <div className="flex w-full max-w-screen-xl flex-wrap max-md:flex-col max-md:p-5">
      {steps.map((step, index) => (
        <Card
          key={index}
          step={step.step}
          description={step.description}
          hasSeparator={index !== steps.length - 1}
        />
      ))}
    </div>
  );
}
