import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui";

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onPageClick: (page: number) => void;
};

export function Paginator({
  totalPages,
  currentPage,
  onNextPage,
  onPreviousPage,
  onPageClick,
}: Readonly<PaginationProps>) {
  const getPageNumbers = () => {
    if (totalPages <= 7) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const pages: (number | "ellipsis-start" | "ellipsis-end")[] = [];
    pages.push(1);

    if (currentPage > 4) {
      pages.push("ellipsis-start");
    }

    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (currentPage < totalPages - 1) {
      pages.push("ellipsis-end");
    }

    pages.push(totalPages);

    return pages;
  };

  const pageNumbers = getPageNumbers();

  if (totalPages === 1) return null;

  return (
    <Pagination>
      <PaginationContent>
        {totalPages > 1 && (
          <PaginationItem>
            <PaginationPrevious href="#" onClick={onPreviousPage} />
          </PaginationItem>
        )}
        {pageNumbers.map((page, index) =>
          page === "ellipsis-start" || page === "ellipsis-end" ? (
            <PaginationItem key={`ellipsis-${index}`}>
              <PaginationEllipsis />
            </PaginationItem>
          ) : (
            <PaginationItem key={page}>
              <PaginationLink
                href="#"
                isActive={page === currentPage}
                onClick={() => onPageClick(page as number)}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ),
        )}
        {totalPages > 1 && (
          <PaginationItem>
            <PaginationNext href="#" onClick={onNextPage} />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
}
