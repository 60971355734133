import { cn } from "@/lib/utils";
import { LinkProps, Link as RouterLink } from "react-router-dom";

export function Link(
  props: LinkProps & React.RefAttributes<HTMLAnchorElement>,
) {
  const { children, className, ...linkProps } = props;
  return (
    <RouterLink {...linkProps} className={cn("hover:opacity-75", className)}>
      {children}
    </RouterLink>
  );
}
