import {
  StatsResp,
  UserDetailsResp,
  UserListResp,
} from "@/services/interfaces/adminPanel";
import { Hex } from "viem";
import { api } from "../baseApi";

export const adminPanelApi = api.injectEndpoints({
  endpoints: (builder) => ({
    stats: builder.query<StatsResp, void>({
      query: () => ({
        url: `stats`,
        method: "GET",
      }),
    }),

    usersList: builder.query<UserListResp, void>({
      query: () => ({
        url: `user`,
        method: "GET",
      }),
    }),

    userDetails: builder.query<UserDetailsResp, Hex>({
      query: (address: Hex) => ({
        url: `user/${address}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useStatsQuery, useUsersListQuery, useUserDetailsQuery } =
  adminPanelApi;
