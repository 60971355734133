import { defineChain } from 'viem'

export const theRootNetwork = defineChain({
    id: 7668,
    name: "The Root Network",
    network: "root-mainnet",
    nativeCurrency: { name: "XRP", symbol: "XRP", decimals: 18 },
    rpcUrls: {
        archive: {
            http: ["https://root.rootnet.live/archive"],
            webSocket: ["wss://root.rootnet.live/archive/ws"],
        },
        default: {
            http: ["https://root.rootnet.live/"],
        },
        public: {
            http: ["https://root.rootnet.live/"],
            webSocket: ["wss://root.rootnet.live/ws"],
        },
    },
    blockExplorers: {
        default: {
            name: "rootMainnet",
            url: "https://rootscan.io/",
        },
    },
    contracts: {},
});

/**
 * Use this config to programatically add this network to a wallet
 */
export const rootWalletConfig = {
    chainId: "0x1DF4",
    chainName: 'The Root Network',
    nativeCurrency: { name: 'Ripple', symbol: 'XRP', decimals: 18 },
    rpcUrls: ['https://root.rootnet.live/'],
    blockExplorerUrls: ['https://rootscan.io/'],
}