import { Text } from "@/components";
import { Button } from "@/components/ui";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";

export function HideActivationModal() {
  const { closeModal } = useModalState();
  const { hideSpending } = useImmersveState();

  function handleConfirm() {
    hideSpending();
    closeModal();
  }

  return (
    <div className="flex flex-col gap-5">
      <Text size="sm" className="text-foreground/75">
        Are you sure you don’t want to enjoy this service? If you change your
        mind, you can still{" "}
        <span className="font-bold text-foreground">
          access the Mastercard creation from the User Profile Menu!
        </span>
      </Text>
      <div className="flex gap-3 self-end">
        <Button size="sm" variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button size="sm" variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}
