import JoinnLogo from "@/assets/images/logo.svg";
import { Box, BoxContent } from "@/components/box";
import { Separator } from "@/components/ui/separator";
import { getMaskedAddress } from "@/lib/utils";
import { useUserProfileQuery } from "@/redux/user/user.api";
import Toolbar from "./Toolbar";
import UserProfile from "./Toolbar/UserProfile";

export default function PageNavigation() {
  const { data } = useUserProfileQuery();

  return (
    <Box className="bg-gradient-to-t" radius="lg">
      <BoxContent>
        {/* TODO: Implement Admin */}
        <div className="flex max-md:block">
          <div className="flex grow items-center max-md:justify-between">
            <div className="flex items-center">
              <img src={JoinnLogo} className="max-w-16" alt="Joinn Logo" />
              <p className="hidden p-2.5 min-[380px]:flex">{`Welcome back, ${data?.name || getMaskedAddress(data?.address)}`}</p>
            </div>
            <div className="hidden max-md:flex max-md:items-center">
              <UserProfile />
            </div>
          </div>
          <Separator className="my-2.5 hidden max-md:flex" />
          <Toolbar />
        </div>
      </BoxContent>
    </Box>
  );
}
