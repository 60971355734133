export enum EarnAccountsFilter {
  ALL = "All",
  DEFI = "DeFi Earn Accounts",
  RWA = "RWA Earn Accounts",
}

// TODO: Check with KG if these contract addresses are correct
export const EarnAddresseType: { [key: string]: { [key: string]: string[] } } =
  {
    80001: {
      RWA: ["0x54Fc5db00F850F63651Bc1888bACA6657Ec6a078"],
      DEFI: [
        "0x35eCeC2629CDb1070DF2f9bcaB71E967b88Ac3E0",
        "0x321ed50B1bED49E48D2B04a3667e044d5cF019Da",
      ],
    },
    80002: {
      RWA: [
        "0x61f732101395d8D8C11DB5F8d6a7e9E7C9dF182B",
        "0x295FF1FfC397500a596C84656af9fe9790cE7dEb",
      ],
      DEFI: ["0x4d7F05D2c25bEcFC93fB3445e924212466d99393"],
    },
    7672: {
      RWA: ["0xe2D060BCEF1541fbF160299faDEE55049802B0c4"],
    },
    // TODO: To be changed
    7668: {
      RWA: ["0x61f732101395d8D8C11DB5F8d6a7e9E7C9dF182B"],
    },
    11155111: {
      RWA: ["0xE23D03AdaFE976222E9b0c6cc214df1e745B1D25"],
    },
    // TODO: Arbitrum sepolia
    421614: {
      RWA: [],
      DEFI: [],
    },
  };
