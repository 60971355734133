export enum TimeRange {
  "7D" = "7d",
  "14D" = "14d",
  "1M" = "1m",
  "3M" = "3m",
  "6M" = "6m",
}

export enum TimeRangeByUnix {
  "7D" = 604800,
  "14D" = 1209600,
  "1M" = 2592000,
  "3M" = 7776000,
  "6M" = 15552000,
}
