import { CircularProgress, Text } from "@/components";
import { Button } from "@/components/ui";
import { cn } from "@/lib/utils";
import { Check } from "lucide-react";
import React from "react";

interface StepperProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  steps: string[];
  activeStep: number;
  completedStep: number;
  onSelectStep: (value: number) => void;
}

export function Stepper(props: Readonly<StepperProps>) {
  const {
    steps,
    activeStep: currentStep,
    completedStep,
    onSelectStep: onSelect,
    ...buttonProps
  } = props;

  return (
    <>
      <div className="flex justify-center max-md:hidden max-md:flex-col max-md:justify-start">
        {steps.map((step, index) => {
          return (
            <div key={step} className="">
              <div className="relative flex items-center text-center max-md:block">
                <Button
                  {...buttonProps}
                  onClick={() => {
                    onSelect(index + 1);
                  }}
                  disabled={index > completedStep}
                  variant="primary"
                  className={cn(
                    "disabled:opacity-1 relative z-10 m-auto h-8 w-8 rounded-full border-2 border-border bg-background",
                    index + 1 === currentStep && "border-primary",
                    index + 1 <= completedStep && "border-primary bg-primary",
                    index + 1 <= completedStep
                      ? "hover:border-border hover:bg-accent"
                      : "hover:bg-button",
                  )}
                >
                  {index + 1 <= completedStep && <Check />}
                </Button>
                {index + 1 !== steps.length && (
                  <div className="absolute left-[calc(50%_+_12px)] h-0.5 w-full border border-dashed border-border max-md:hidden" />
                )}
              </div>
              <Text
                size="sm"
                className="pt-2.5 text-center uppercase text-accent"
              >{`Step ${index + 1}`}</Text>
              <Text
                size="md"
                className={cn(
                  "px-4 text-center",
                  index + 1 === currentStep && "text-primary",
                )}
              >
                {step}
              </Text>
              {index + 1 !== steps.length && (
                <div className="hidden border border-dashed border-border max-md:relative max-md:left-1/2 max-md:mt-5 max-md:flex max-md:h-16 max-md:w-0.5" />
              )}
            </div>
          );
        })}
      </div>
      <div className="hidden items-center gap-5 pb-5 max-md:flex max-sm:gap-2">
        <CircularProgress
          progress={25 * completedStep}
          label={`${completedStep} of ${steps.length}`}
        />

        {steps[currentStep - 1] ? (
          <div>
            <Text size="2xl">{steps[currentStep - 1]}</Text>
            {steps[currentStep] && (
              <Text
                size="md"
                variant="label"
              >{`Next: ${steps[currentStep]}`}</Text>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <Text size="md">You have completed all the steps!</Text>
            <div className="flex gap-2">
              <Button
                className="rounded"
                variant="primary"
                size="sm"
                onClick={() => {
                  onSelect(currentStep - 1);
                }}
              >
                Back
              </Button>
              <Button
                className="rounded"
                variant="outline"
                size="sm"
                onClick={() => {
                  onSelect(1);
                }}
              >
                Step 1
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
