import { Identifier } from "@/redux/balance/balance.api";
import {
  AssignResponseToContracts,
  ContractType,
  ErrorResponse,
  FunctionsArgs,
  GroupByAddress,
  SuccessResponse,
  VaultsFormatted,
  WalletsFormatted,
} from "@/services/interfaces";
import { formatUnits } from "viem";

export function assignResponseToContracts(
  response: (SuccessResponse | ErrorResponse)[],
  contracts: ContractType[],
): AssignResponseToContracts[] {
  if (response.length !== contracts.length) {
    throw new Error(
      "The number of responses does not match the number of contracts.",
    );
  }

  return contracts.map((contract, index) => {
    return {
      ...contract,
      balance: response[index],
    };
  });
}

function groupByAddress(data: AssignResponseToContracts[]) {
  return data.reduce((acc: GroupByAddress[], item) => {
    const { address } = item.metadata;
    const index = acc.findIndex((entry) => entry.metadata.address === address);
    if (index >= 0) {
      acc[index].functions.push({
        functionName: item.functionName,
        balance: item.balance,
        args: item.args,
        address: item.address,
      });
    } else {
      acc.push({
        metadata: item.metadata,
        functions: [
          {
            functionName: item.functionName,
            balance: item.balance,
            args: item.args,
            address: item.address,
          },
        ],
      });
    }

    return acc;
  }, []);
}

function getFunctionsValue(
  functions: FunctionsArgs[],
  functionName: string,
  defaultValue: unknown = null,
) {
  const func = functions.find((f) => f.functionName === functionName);
  return func?.balance?.result ?? defaultValue;
}

export function transformBalanceObj(data: AssignResponseToContracts[]) {
  const vaults = data.filter(
    (item) => item.metadata?.identifier === Identifier.VAULT,
  );
  const wallets = data.filter(
    (item) => item.metadata?.identifier === Identifier.WALLET,
  );

  const walletsGrouped = groupByAddress(wallets);

  const vaultsGrouped = groupByAddress(vaults);

  const walletsFormatted: WalletsFormatted[] = walletsGrouped.map((wallet) => {
    const decimals = getFunctionsValue(wallet.functions, "decimals");
    const balance = getFunctionsValue(wallet.functions, "balanceOf") ?? 0;
    return {
      address: wallet.metadata?.owner,
      joinnAddress: wallet.metadata?.address,
      name: wallet.metadata?.name,
      decimals: decimals,
      balance: Number(formatUnits(balance as bigint, decimals as number)),
    };
  });

  const vaultsFormatted: VaultsFormatted[] = vaultsGrouped.map((vault) => {
    const decimals = Number(getFunctionsValue(vault.functions, "decimals", 0n));
    const balanceOf = getFunctionsValue(vault.functions, "balanceOf", 0);
    const pricePerShare = getFunctionsValue(
      vault.functions,
      "pricePerShare",
      0n,
    );

    return {
      address: vault.metadata?.address,
      name: vault.metadata?.name,
      symbol: vault.metadata?.symbol,
      decimals: decimals,
      balance: Number(formatUnits(BigInt(balanceOf as bigint), decimals)),
      pricePerShare: Number(formatUnits(pricePerShare as bigint, decimals)),
      contract: vault.metadata.address,
      strategyAddress: vault.metadata.strategyAddress ?? "",
    };
  });

  return { vaults: vaultsFormatted, wallets: walletsFormatted };
}
