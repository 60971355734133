import { Card, Separator, Skeleton } from "../ui";

export function ActivityTileSkeleton() {
  return (
    <Card
      size="sm"
      className="flex items-center justify-between gap-3 border-[hsla(var(--secondary)/0.25)]"
    >
      <div className="flex w-20 flex-col gap-1">
        <Skeleton className="h-3 w-14" />
        <Skeleton className="h-3 w-14" />
      </div>
      <Separator className="h-8" orientation="vertical" />
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col gap-1">
          <Skeleton className="h-3 w-20" />
          <Skeleton className="h-3 w-28" />
        </div>
        <Skeleton className="h-3 w-14" />
      </div>
    </Card>
  );
}
