import {
  Cell,
  Head,
  Header,
  HistoryDropdownFilter,
  HistoryTile,
  Row,
  Table,
} from "@/components/transactionHistory";
import { HistoryRowSkeleton } from "@/components/transactionHistory/HistoryRowSkeleton";
import { MASTERCARD_STATUS } from "@/const";
import { useListCardsQuery } from "@/redux/immersve/immersve.api";

import { useBalances } from "@/hooks";
import { getImmersveTokenData } from "@/utils";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

export function CardEnrollment() {
  const immersveTokenData = getImmersveTokenData();
  const { data, isFetching } = useListCardsQuery(
    immersveTokenData.cardholderAccountId,
    {
      skip: !immersveTokenData.cardholderAccountId,
    },
  );
  const { spendingAccountBalance } = useBalances();
  const [createdCardStatus, setCreatedCardStatus] = useState<MASTERCARD_STATUS>(
    MASTERCARD_STATUS.ALL,
  );

  const mastercardHistory = data?.items;

  const MastercardHistoryStatusOptions = [
    { id: "1", label: MASTERCARD_STATUS.ALL },
    { id: "2", label: MASTERCARD_STATUS.ACTIVE },
    { id: "3", label: MASTERCARD_STATUS.CANCELED },
    { id: "4", label: MASTERCARD_STATUS.CREATED },
    { id: "5", label: MASTERCARD_STATUS.SHIPPED },
  ];

  const filteredCreatedCards = useMemo(() => {
    if (!mastercardHistory) return [];
    const matchesStatusType = mastercardHistory.filter(
      (transaction) =>
        createdCardStatus === MASTERCARD_STATUS.ALL ||
        transaction.status.toLowerCase() === createdCardStatus.toLowerCase(),
    );
    return matchesStatusType;
  }, [mastercardHistory, createdCardStatus]);

  function noHistoryCases() {
    const noResultsMessage =
      createdCardStatus === MASTERCARD_STATUS.ALL
        ? "There is no history yet"
        : "No filtered results";

    return (
      filteredCreatedCards.length === 0 && (
        <div className="flex w-full justify-center text-accent">
          {noResultsMessage}
        </div>
      )
    );
  }

  return (
    <>
      <HistoryTile balance={spendingAccountBalance} title="Mastercard Balance">
        <HistoryDropdownFilter
          title="Transaction Status"
          label={createdCardStatus}
          options={MastercardHistoryStatusOptions}
          onSelectOption={(option) =>
            setCreatedCardStatus(option?.label as MASTERCARD_STATUS)
          }
        />
      </HistoryTile>

      <Table>
        <Head>
          <Header>Date</Header>
          <Header>Time</Header>
          <Header>Pan No.</Header>
          <Header>Status</Header>
          <Header>Expires At</Header>
        </Head>
        {noHistoryCases()}
        {filteredCreatedCards.map((row) => (
          <Row key={row.id}>
            <Cell caption="Date:" textStyles="text-accent">
              {dayjs(row.createdAt).format("MM/DD/YYYY")}
            </Cell>
            <Cell caption="Date:" textStyles="text-accent">
              {dayjs(row.createdAt).format("hh:mm A")}
            </Cell>
            <Cell caption="Pan No.:">
              <span className="capitalize">
                {row.panFirst6 + " **** " + row.panLast4}
              </span>
            </Cell>
            <Cell caption="Status:" className="capitalize">
              {row.status}
            </Cell>
            <Cell caption="Expires At: ">
              {dayjs(row.expiresAt).format("MM/DD/YYYY")}
            </Cell>
          </Row>
        ))}
      </Table>
      <HistoryRowSkeleton colsNumber={5} isLoading={isFetching} />
    </>
  );
}
