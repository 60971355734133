import { ApiPromise } from "@polkadot/api";
import React from "react";
import { Hex } from "viem";

export interface TrnNativeApiContextType {
  api: ApiPromise | null;
  holder: null | Hex;
}

export const TrnNativeApiContext =
  React.createContext<TrnNativeApiContextType | null>(null);

TrnNativeApiContext.displayName = "TrnNativeApiContext";

export default TrnNativeApiContext;
