import { Routes } from "@/routes/routers";
import { AuthClientConfig, FutureverseAuthClient } from "@futureverse/auth";
import {
  FutureverseAuthProvider,
  useFutureverseSigner,
} from "@futureverse/auth-react";
import { PropsWithChildren } from "react";

const clientId = "GRV_Hv9uuRdnTGdpOfBMx";
const fvConfig: AuthClientConfig = {
  clientId,
  environment: "staging",
  redirectUri: `${
    typeof window !== "undefined"
      ? window.location.origin
      : "http://localhost:5173"
  }/${Routes.FV_ESTABLISH_SESSION}`,
};
const authClient = new FutureverseAuthClient(fvConfig);

export default function FutureVerseProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const signer = useFutureverseSigner();

  return (
    <FutureverseAuthProvider authClient={authClient} signer={signer}>
      {children}
    </FutureverseAuthProvider>
  );
}
