import { config } from "@/config";
import { ApyVault, HistoricRequest } from "@/services/interfaces";
import { gql } from "graphql-request";
import { api } from "../baseApi";

export const historicApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // TODO: Change the query and response here as soon as the new hosting is available
    apyHistoric: builder.query<ApyVault[], HistoricRequest>({
      query: (timeFrom) => ({
        url: config.ARCHIVE_URI,
        request: "graphql",
        body: gql`
        query {
          Snapshots(
            sort: TIMESTAMP_DESC
            filter: { res: "1d", _operators: { timestamp: { gte: ${timeFrom} } } }
          ) {
            apy1d
            apy3d
            apy7d
            res
            timestamp
          }
        }`,
      }),
    }),

    // TODO: Change the query and response here as soon as the new hosting is available
    sharePriceHistoric: builder.query<ApyVault[], HistoricRequest>({
      query: (timeFrom) => ({
        url: config.ARCHIVE_URI,
        request: "graphql",
        body: gql`
        query {
          Snapshots(
            sort: TIMESTAMP_DESC
            filter: { res: "1d", _operators: { timestamp: { gte: ${timeFrom} } } }
          ) {
            apy1d
            apy3d
            apy7d
            res
            timestamp
          }
        }`,
      }),
    }),
  }),
});

export const { useApyHistoricQuery, useSharePriceHistoricQuery } = historicApi;
