import { customBaseQuery } from "@/services/customQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "JoinnApi",
  baseQuery: customBaseQuery(),
  tagTypes: ["Profile", "TransactionHistory"],
  endpoints: () => {
    return {};
  },
});
