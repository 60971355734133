import { getMaskedAddress } from "@/lib/utils";
import { useUserDetailsQuery } from "@/redux/adminPanel/adminPanel.api";
import { Hex } from "viem";
import { Card } from "../ui";
import { DataTile } from "./dataTile";

type UserDetailsSectionProps = {
  userDetailsAddress: Hex | null;
};

export function UserDetailsSection({
  userDetailsAddress,
}: Readonly<UserDetailsSectionProps>) {
  const { data: userDetails } = useUserDetailsQuery(userDetailsAddress as Hex, {
    skip: !userDetailsAddress,
  });
  if (!userDetailsAddress) return null;

  console.log("user details", userDetails);

  //   TODO: n/a values should be add to backend or pull from vaults if possible
  return (
    <Card radius="md" size="md" className="grid grid-cols-4 gap-3">
      <DataTile
        title="User"
        value={
          userDetails?.address ? getMaskedAddress(userDetails.address) : "-"
        }
      />
      <DataTile title="KYC status" value={userDetails?.kyc?.status ?? "-"} />
      <DataTile title="TVL Earn Accounts" value={"n/a"} />
      <DataTile title="TVL Spending Accounts" value={"n/a"} />
      <DataTile
        title="Sponsored Gas Used"
        value={userDetails?.stats.totalGasUsage ?? "-"}
      />
      <DataTile title="Email" value={userDetails?.email ?? "-"} />
      <DataTile
        title="Linked Wallets"
        value={
          userDetails?.linkedAccounts ? userDetails.linkedAccounts.length : "-"
        }
      />
      <DataTile title="Total Deposits" value={"n/a"} />
      <DataTile title="Total Withdrawals" value={"n/a"} />
      <DataTile title="Total Site Visits" value={"n/a"} />
      <DataTile title="Total Transactions" value={"n/a"} />
      <DataTile title="Total Mastercard Transactions" value={"n/a"} />
      <DataTile title="Transaction History" value={"n/a"} />
      <DataTile title="Last Log In" value={"n/a"} />
      <DataTile title="Open Orders" value={"n/a"} />
      <DataTile title="Open Support Tickets" value={"n/a"} />
      <DataTile title="Closed Support Tickets" value={"n/a"} />
    </Card>
  );
}
