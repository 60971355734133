import OnlineIcon from "@/assets/icons/online.svg";
import { Text } from "@/components/typography";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Separator,
} from "@/components/ui";
import { useNetworkConfig } from "@/hooks";
import { useAccount, useSwitchChain } from "wagmi";

export default function Network() {
  const { chainId } = useAccount();
  const { switchChain } = useSwitchChain();
  const { supportedChains, chainImage } = useNetworkConfig({
    activeChain: chainId,
  });

  // TODO: IMPORTANT!! - Create component that will auto-switch to supported network

  return (
    <div className="flex items-center max-[400px]:hidden">
      <DropdownMenu>
        <DropdownMenuTrigger variant="outline">
          <div className="rounded-l-2xl bg-accent/50 p-1">
            <img
              src={chainImage}
              className="ml-1 h-6 w-6 pt-1 opacity-75"
              alt="Chain Icon"
            />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[20rem] p-0">
          <Text size="xl" padding="lg">
            Network Selection
          </Text>
          <Separator />
          <div className="grid gap-1.5 p-5">
            {supportedChains.map((chain) => {
              return (
                <DropdownMenuItem
                  key={chain.id}
                  variant="secondary"
                  className="py-4"
                  active={chain.id === chainId}
                  onClick={(events) => {
                    switchChain({ chainId: chain.id });
                    events.preventDefault();
                  }}
                >
                  <span className="w-10">
                    <img src={chain.image} alt="chain icon"></img>
                  </span>
                  <Text className="flex w-full items-center justify-between">
                    {chain.name}
                    {chainId === chain.id && (
                      <img
                        src={OnlineIcon}
                        className="ml-2"
                        alt="Online Icon"
                      />
                    )}
                  </Text>
                </DropdownMenuItem>
              );
            })}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
