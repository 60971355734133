import { AddWallet, AddWalletInfo, DisconnectWallet, Text } from "@/components";
import { ChangeWalletName } from "@/components/externalWallets";
import { CancelMastercard } from "@/components/mastercard";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui";
import { cn } from "@/lib/utils";
import { Deposit, Transfer, Withdraw } from "@/pages/Transactions";
import { useModalState } from "@/redux/modal/modal.slice";
import { X } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { ActivationModal } from "../Dashboard/SpendingAccount/ActivationModal";
import { HideActivationModal } from "../Dashboard/SpendingAccount/HideActivationModal";
import { ImmersveKycFlow } from "../ImmersveKYC/Steps/ImmersveKycFlow";
import { KycFractal } from "../KYC/Steps";

export default function ModalContainer() {
  const { useModal, closeModal } = useModalState();
  const { isModalOpen, props } = useModal();
  const navigate = useNavigate();
  const { state } = useLocation();

  const getContent = () => {
    switch (props?.id) {
      case "deposit":
        return <Deposit fromAddress={state?.vaultAddress} />;
      case "transfer":
        return <Transfer fromAddress={state?.vaultAddress} />;
      case "withdraw":
        return <Withdraw fromAddress={state?.vaultAddress} />;
      case "fractal-verification":
        return <KycFractal />;
      case "immersve-verification":
        return <ImmersveKycFlow />;
      case "cancel-mc":
        return <CancelMastercard />;
      case "change-wallet-name": {
        const walletData = props?.data as {
          walletAddress: string;
          walletName: string;
        };
        return (
          <ChangeWalletName
            walletAddress={walletData.walletAddress}
            walletName={walletData.walletName}
          />
        );
      }
      case "disconnect-wallet":
        return <DisconnectWallet />;
      case "pre-kyc-modal":
        return <ActivationModal />;
      case "add-wallet":
        return <AddWalletInfo />;
      case "hide-spending":
        return <HideActivationModal {...props?.data} />;
      case "add-wallet-action":
        return <AddWallet />;
      default:
        return <></>;
    }
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogContent
        className={cn(
          "bg-transparent px-2",
          props?.isFullWidth && "w-max max-w-[calc(100%-20px)]",
        )}
        onPointerDownOutside={() => {
          if (!props?.isClickawayDisabled) {
            closeModal();
            if (props?.redirectBack) {
              navigate(-1);
            }
          }
        }}
      >
        <Card
          variant="container"
          radius="lg"
          size="md"
          className="bg-card max-md:gap-3 max-md:pt-5"
        >
          <DialogHeader className={cn("hidden", props?.title && "block")}>
            <DialogTitle className="flex items-center justify-between">
              <Text className="capitalize max-md:px-5" size="2xl">
                {props?.title}
              </Text>
              {props?.isCloseEnabled && (
                <Button
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <X />
                </Button>
              )}
            </DialogTitle>
          </DialogHeader>
          {getContent()}
        </Card>
      </DialogContent>
    </Dialog>
  );
}
