import { getMaskedAddress } from "@/lib/utils";
import { useUsersListQuery } from "@/redux/adminPanel/adminPanel.api";
import dayjs from "dayjs";
import { useState } from "react";
import { Hex } from "viem";
import { Paginator } from "../paginator";
import { Cell, Head, Header, Row } from "../transactionHistory";
import { Table, TableBody } from "../ui/table";
import { UserDetailsSection } from "./userDetailsSection";

export function UserDataSection() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: usersList } = useUsersListQuery();
  const [selectedUser, setSelectedUser] = useState<Hex | null>(null);

  const totalPages = usersList?.meta.totalPages ?? 1;
  //   const totalPages = 20;

  if (!usersList) return null;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Table>
        <Head>
          <Header>User master wallet</Header>
          <Header>User email</Header>
          <Header>KYC status</Header>
          <Header>Created</Header>
        </Head>
        <TableBody className="flex flex-col gap-2">
          {usersList.data.map((user) => (
            <Row
              key={user.id}
              onClick={() => setSelectedUser(user.address as Hex)}
              className="cursor-pointer hover:bg-primary/10"
            >
              <Cell>{getMaskedAddress(user.address)}</Cell>
              <Cell>
                {user.email && user.email.length > 0 ? user.email : "-"}
              </Cell>
              <Cell>{user.kyc?.id ?? "-"}</Cell>
              <Cell>{dayjs(user.createdAt).format("DD/MM/YYYY HH:MM")}</Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
      <Paginator
        currentPage={currentPage}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
        totalPages={totalPages}
        onPageClick={handlePageClick}
      />
      <UserDetailsSection userDetailsAddress={selectedUser} />
    </>
  );
}
