import JoinnLogo from "@/assets/images/joinnworkmark.svg";
import { Text } from "@/components";
import { Button, Card } from "@/components/ui";
import { useNetworkConfig } from "@/hooks";
import { useSwitchChain } from "wagmi";

export function ChainNotSupported() {
  const { supportedChains } = useNetworkConfig();
  const { switchChain } = useSwitchChain();

  function handleSwitchNetwork(chainId: number) {
    switchChain({ chainId: chainId });
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Card
        variant="container"
        size="lg"
        className="w-auto items-center"
        radius="lg"
      >
        <img src={JoinnLogo} alt="logo" className="h-8" />
        <h1 className="text-xl">Chain Not Supported</h1>
        <Text size="md" variant="accent">
          Please switch to a supported network by selecting one of the below
          options or via your wallet provider.
        </Text>
        <div className="mt-3 flex flex-col gap-3">
          {supportedChains.map((chain) => (
            <Button
              variant="outline"
              key={chain.id}
              className="px-4 py-2"
              onClick={() => handleSwitchNetwork(chain.id)}
            >
              <img src={chain.image} alt={chain.name} className="mr-2 h-4" />
              {chain.name}
            </Button>
          ))}
        </div>
      </Card>
    </div>
  );
}
