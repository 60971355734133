import { CurrencyResponse } from "@/services/interfaces";
import { api } from "../baseApi";
import { customQueryFn } from "@/services/customQuery";
import { COUNTRIES } from "@/const";

export const currencyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getImmersveCurrency: builder.query<CurrencyResponse[], void>({
      queryFn: async () => {
        const symbols = [
          ...new Set(COUNTRIES.map((country) => country.currencyCode)),
        ];

        const response = await customQueryFn({
          url: `immersve/currencies?symbols=${symbols.join(",")}`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        return response;
      },
    }),
  }),
});

export const { useGetImmersveCurrencyQuery } = currencyApi;
