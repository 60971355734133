import { TransactionsStatus } from "@/const/transactions";
import { useEffect, useRef, useState } from "react";
import { Text } from "../typography";
import { Progress } from "../ui/progress";

type TransferProgressProps = {
  status: TransactionsStatus;
  isProcessing: boolean;
  transactionDescription?: string;
};

export function TransferProgress({
  status,
  isProcessing,
  transactionDescription = "Transaction",
}: Readonly<TransferProgressProps>) {
  const [progress, setProgress] = useState(0);
  const TOTAL_TIME = 60 * 1000;
  const WHEN_TO_SLOW_DOWN = 0.8;
  const SLOW_DOWN_FACTOR = 3;
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const slowedDownRef = useRef(false);
  const pausedRef = useRef(false);
  const progressRef = useRef(0);

  // Handle resetting when processing starts
  useEffect(() => {
    if (isProcessing) {
      setProgress(0);
      progressRef.current = 0;
      slowedDownRef.current = false;
      pausedRef.current = false;
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isProcessing]);

  // Main interval handler for progress
  useEffect(() => {
    if (!isProcessing || pausedRef.current) return;

    const speed = TOTAL_TIME / 100; // Base speed for the progress

    const startProgress = () => {
      intervalRef.current = setInterval(() => {
        setProgress((prev) => {
          if (pausedRef.current) return prev; // If paused, don't update

          // Check if we need to slow down
          if (prev >= WHEN_TO_SLOW_DOWN * 100 && !slowedDownRef.current) {
            slowedDownRef.current = true;
            clearInterval(intervalRef.current as NodeJS.Timeout);

            const slowSpeed = speed * SLOW_DOWN_FACTOR;
            intervalRef.current = setInterval(() => {
              setProgress((prev) => {
                if (prev >= 100) {
                  clearInterval(intervalRef.current as NodeJS.Timeout);
                  return 100;
                }
                progressRef.current = prev + 1;
                return progressRef.current;
              });
            }, slowSpeed);

            return prev;
          }

          if (prev >= 100) {
            clearInterval(intervalRef.current as NodeJS.Timeout);
            return 100;
          }

          progressRef.current = prev + 1;
          return progressRef.current;
        });
      }, speed);
    };

    startProgress();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isProcessing, TOTAL_TIME]);

  // Handle status changes to pause and resume
  useEffect(() => {
    if (status === TransactionsStatus.PENDING) {
      // Pause progress when the status is PENDING
      if (intervalRef.current) clearInterval(intervalRef.current);
      pausedRef.current = true;
    } else if (status === TransactionsStatus.PROCESSING && pausedRef.current) {
      // Resume progress when the status changes back to PROCESSING
      pausedRef.current = false;
      slowedDownRef.current = progressRef.current >= WHEN_TO_SLOW_DOWN * 100;

      // Restart the interval to continue the progress
      const speed = TOTAL_TIME / 100;
      if (slowedDownRef.current) {
        const slowSpeed = speed * SLOW_DOWN_FACTOR;
        intervalRef.current = setInterval(() => {
          setProgress((prev) => {
            if (prev >= 100) {
              clearInterval(intervalRef.current as NodeJS.Timeout);
              return 100;
            }
            progressRef.current = prev + 1;
            return progressRef.current;
          });
        }, slowSpeed);
      } else {
        intervalRef.current = setInterval(() => {
          setProgress((prev) => {
            if (prev >= 100) {
              clearInterval(intervalRef.current as NodeJS.Timeout);
              return 100;
            }
            progressRef.current = prev + 1;
            return progressRef.current;
          });
        }, speed);
      }
    } else if (status === TransactionsStatus.SUCCESS) {
      // On success, complete the progress
      if (intervalRef.current) clearInterval(intervalRef.current);
      setProgress(100);
    }
  }, [status]);

  if (status === TransactionsStatus.ERROR && !isProcessing) {
    return (
      <div className="flex flex-col gap-1">
        <Text variant="accent">
          {transactionDescription} failed. Please try again later.
        </Text>
        <Progress value={100} variant="secondary" />
      </div>
    );
  }

  if (
    (status === TransactionsStatus.PROCESSING ||
      status === TransactionsStatus.PENDING) &&
    isProcessing
  ) {
    return (
      <div className="flex flex-col gap-1">
        <Text variant="accent">
          {status === TransactionsStatus.PENDING
            ? `${transactionDescription} is pending, please wait.`
            : `${transactionDescription} in progress, please wait.`}
        </Text>
        <Progress value={progress} />
      </div>
    );
  }

  if (status === TransactionsStatus.SUCCESS && !isProcessing) {
    return (
      <div className="flex flex-col gap-1">
        <Text variant="accent">{transactionDescription} is completed.</Text>
        <Progress value={100} />
      </div>
    );
  }

  return null;
}
