import { Hex } from "viem";

const envVariableValidator = (
  variable: string | undefined,
  expectedVariableName: string,
) => {
  if (!variable || typeof variable !== "string") {
    throw new Error(
      `You need to include all necessary variables inside the .env file in the root of your project to run the application. The first missing variable is: ${expectedVariableName}.`,
    );
  }
  return variable;
};

export const config = {
  API_URL: envVariableValidator(import.meta.env.VITE_API_URL, "API_URL"),
  ARCHIVE_URI: envVariableValidator(
    import.meta.env.VITE_ARCHIVE_URI,
    "ARCHIVE_URI",
  ),
  // BOF_SUBGRAPH_URI: envVariableValidator(
  // 	import.meta.env.VITE_BOF_SUBGRAPH_URI,
  // 	"BOF_SUBGRAPH_URI",
  // ),
  // BOF_SUBGRAPH_URI:
  //   "https://api.studio.thegraph.com/query/68622/bof-aa/version/latest", // ! Hardcoded
  BLOCK_CONFIRMATIONS: import.meta.env.VITE_BLOCK_CONFIRMATIONS ?? 5,
  ALCHEMY_API_KEY: envVariableValidator(
    import.meta.env.VITE_ALCHEMY_API_KEY,
    "ALCHEMY_API_KEY",
  ),
  BLOCKCHAIN_NET: envVariableValidator(
    import.meta.env.VITE_BLOCKCHAIN_NET,
    "BLOCKCHAIN_NET",
  ),
  EXECUTOR_SELECTOR: "0x3e0dd752" as Hex,
  IMMERSVE_FUNDING_STORAGE_ADDRESS: envVariableValidator(
    import.meta.env.VITE_IMMERSVE_FUNDING_STORAGE_ADDRESS,
    "IMMERSVE_FUNDING_STORAGE_ADDRESS",
  ),
  IMMERSVE_PARTNER_ACCOUNT_ID: envVariableValidator(
    import.meta.env.VITE_IMMERSVE_PARTNER_ACCOUNT_ID,
    "IMMERSVE_PARTNER_ACCOUNT_ID",
  ),
  IMMERSVE_CLIENT_APPLICATION_ID: envVariableValidator(
    import.meta.env.VITE_IMMERSVE_CLIENT_APPLICATION_ID,
    "IMMERSVE_CLIENT_APPLICATION_ID",
  ),
  IMMERSVE_CARD_PROGRAM_ID: envVariableValidator(
    import.meta.env.VITE_IMMERSVE_CARD_PROGRAM_ID,
    "IMMERSVE_CARD_PROGRAM_ID",
  ),
  IMMERSVE_FUNDING_CHANNEL_ID: envVariableValidator(
    import.meta.env.VITE_IMMERSVE_FUNDING_CHANNEL_ID,
    "IMMERSVE_FUNDING_CHANNEL_ID",
  ),
  IMMERSVE_REDIRECT_URL: envVariableValidator(
    import.meta.env.VITE_IMMERSVE_REDIRECT_URL,
    "IMMERSVE_REDIRECT_URL",
  ),
  FUTUREVERSE_CLIENT_ID: envVariableValidator(
    import.meta.env.VITE_FUTUREVERSE_CLIENT_ID,
    "FUTUREVERSE_CLIENT_ID",
  ),
};
