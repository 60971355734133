import { Button, Separator } from "@/components/ui";
import { config } from "@/config";
import { ImmersveStageStatus, Transactions } from "@/const";
import { useIcons, useImmersve, useSpending, useTransactionNav } from "@/hooks";
import { cn } from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { Spinner } from "../spinner";

export function ButtonsSet() {
  const { isConnected, isConnecting } = useImmersveState();
  const { handleTransactionNav } = useTransactionNav();
  const { TransferIcon } = useIcons();
  const { action } = useImmersve();
  const { kyc } = useSpending();

  const isDisabled = !isConnected || kyc?.status !== ImmersveStageStatus.OK;

  return (
    <div
      className={cn(
        "flex w-full flex-wrap items-center justify-between gap-2 @container/spending",
        isConnected && "justify-end",
      )}
    >
      {!isConnected && (
        <Button
          variant="primary"
          size="sm"
          onClick={action.login}
          readOnly={isConnecting}
          className="max-md:p-1.5"
        >
          {isConnecting ? "Connecting" : "Connect"}
          {isConnecting && <Spinner className="mx-0" />}
        </Button>
      )}
      <Separator className="hidden max-[420px]:flex" />
      <div
        className={cn(
          "hidden items-center gap-2",
          !isConnected ? "@sm/spending:flex" : "flex",
        )}
      >
        <Button
          variant="secondary"
          size="sm"
          // disabled={isDisabled}
          onClick={() =>
            handleTransactionNav(
              Transactions.DEPOSIT,
              config.IMMERSVE_FUNDING_STORAGE_ADDRESS,
            )
          }
        >
          Deposit
        </Button>
        <Button
          size="sm"
          variant="primary"
          // disabled={isDisabled}
          onClick={() =>
            handleTransactionNav(
              Transactions.WITHDRAW,
              config.IMMERSVE_FUNDING_STORAGE_ADDRESS,
            )
          }
        >
          Withdraw
        </Button>
        <Button
          size="sm"
          variant="primary"
          // disabled={isDisabled}
          onClick={() =>
            handleTransactionNav(
              Transactions.TRANSFER,
              config.IMMERSVE_FUNDING_STORAGE_ADDRESS,
            )
          }
        >
          <TransferIcon />
          Transfer
        </Button>
      </div>
    </div>
  );
}
