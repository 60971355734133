/* eslint-disable @typescript-eslint/no-explicit-any */

import { ethers } from "ethers";

export async function blockchainErrorsTranslator(
  error: any,
  provider?: ethers.providers.BaseProvider | null,
  textToLocateInCode?: string,
  hideAdditionalInfo?: boolean,
) {
  const txReceipt = error as { [key: string]: any };
  const hash = txReceipt.transactionHash;
  if (hash && provider) {
    const receipt = await provider.getTransaction(hash);
    const result = await provider.call(
      {
        from: receipt.from,
        to: receipt.to,
        nonce: receipt.nonce,
        data: receipt.data,
      },
      receipt.blockNumber,
    );
    if (result.length > 2) {
      const iface = new ethers.utils.Interface(["function Error(string)"]);
      const decodedError = iface.decodeFunctionData(
        result.slice(0, 10),
        result,
      );
      if (decodedError.length > 0) return decodedError.join(" ");
    } else if (txReceipt.transaction.gasLimit.lte(txReceipt.receipt.gasUsed)) {
      return "Gas limit exceeded.";
    }
  }

  let errorMessage = "";

  if (error?.reason) {
    errorMessage = error.reason;
  } else if (error.data?.message) {
    errorMessage = error.data.message;
  } else if (error?.message) {
    errorMessage = error.message;
  } else errorMessage = "Unknown error occurred.";

  function includesText(text: string) {
    if (errorMessage.toLowerCase().includes(text.toLowerCase()))
      return errorMessage;
  }

  if (!hideAdditionalInfo) {
    if (textToLocateInCode) {
      console.error(textToLocateInCode);
    }
    console.error(errorMessage);
  }

  switch (errorMessage) {
    case `[ethjs-query] while formatting outputs from RPC \\'{"value":{"code":-32603,"data":{"code":-32000,"message":"intrinsic gas too low"}}}\\'`:
      return "Intrinsic gas too low.";
    case `[ethjs-query] while formatting outputs from RPC \\'{"value":{"code":-32603,"data":{"code":-32000,"message":"replacement transaction underpriced"}}}\\'`:
      return "Replacement transaction underpriced. Possibly gas too low.";
    case includesText(
      "Error: cannot estimate gas; transaction may fail or may require manual gas limit [ See: https://links.ethers.org/v5-errors-UNPREDICTABLE_GAS_LIMIT ]",
    ):
      return "Error! Cannot estimate gas. Transaction may fail or may require manual gas limit.";
    default:
      return errorMessage;
  }
}
