import { useModalState } from "@/redux/modal/modal.slice";
import { Text } from "../typography";
import { Button } from "../ui";

export function CancelMastercard() {
  async function cancelMastercard() {}
  const { closeModal } = useModalState();

  return (
    <>
      <Text variant="label" size="md">
        Are you sure you want to cancel your Mastercard?
      </Text>
      <div className="flex items-center justify-end gap-3">
        <Button variant="primary" size="sm" onClick={() => closeModal()}>
          Close
        </Button>
        <Button variant="destructive" size="sm" onClick={cancelMastercard}>
          Cancel Mastercard
        </Button>
      </div>
    </>
  );
}
