import { Spinner, Text } from "@/components";
import { Button, Separator } from "@/components/ui";
import { ImmersveStageStatus } from "@/const";
import { useDashboard, useImmersve, useMastercard, useSpending } from "@/hooks";
import { getKycBtnVariant } from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function ActivationModal() {
  const {
    action: { login },
  } = useImmersve();
  const {
    kyc,
    hasFundingSource,
    createFundingSource,
    isCreatingFundingSource,
    isKycLoading,
  } = useSpending();
  const { isUserProfileLoading, userProfile: user } = useDashboard();
  const { closeModal } = useModalState();
  const { isConnected, isConnecting } = useImmersveState();
  const { mastercard } = useMastercard();
  const navigate = useNavigate();

  const isKycCompleted = kyc?.status === ImmersveStageStatus.OK;
  const isKycPending = kyc?.status === ImmersveStageStatus.PENDING;
  const isKycError = kyc?.status === ImmersveStageStatus.BLOCKED;

  async function handleCreateMastercard() {
    await mastercard.create();
  }

  async function handleSignIn() {
    if (!isConnected) {
      await login();
    }
  }

  const handleCreateFundingSource = useCallback(async () => {
    if (isConnected && !hasFundingSource) {
      await createFundingSource();
    }
  }, [hasFundingSource, isConnected]);

  async function handleSignInMessage() {
    await handleSignIn();
    await handleCreateFundingSource();
  }

  function handleQuickVerification() {
    if (
      !!user?.bofTermsOfService &&
      !!user?.bofPrivacyPolicy &&
      !!user?.dataShareAgreement &&
      !!user?.emailVerification?.isVerified
    ) {
      navigate(Routes.IMMERSVE_KYC);
    } else {
      navigate(Routes.ACCOUNT_VERIFICATION);
    }

    closeModal();
  }

  const verificationLabel = useMemo(() => {
    let label = "Start Activation Process";

    if (isKycCompleted) {
      label = "Account Verified!";
    } else if (isKycPending) {
      label = "Verification is being reviewed";
    } else if (isKycError) {
      label = "Verification failed, try again!";
    }

    return label;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kyc?.status]);

  const signLabel = useMemo(() => {
    let label = "Sign Message";

    if (isUserProfileLoading || isConnecting) {
      label = "Signing in";
    } else if (isConnected && !hasFundingSource) {
      label = "Proceed";
    } else if (isConnected && hasFundingSource) {
      label = "Connected!";
    }

    return label;
  }, [isConnected, hasFundingSource, isUserProfileLoading, isConnecting]);

  return (
    <div className="flex w-full max-w-screen-xl flex-wrap gap-5 max-md:flex-col max-md:p-5">
      <div className="flex flex-1 flex-col justify-between gap-5">
        <div className="flex flex-col gap-3">
          <Text
            variant="accent"
            size="2xl"
            className="w-fit rounded-full bg-foreground px-7 py-4 font-bold"
          >
            1
          </Text>
          <Text size="xl" className="font-bold">
            Sign a Message
          </Text>

          {(!isConnected || hasFundingSource) && (
            <Text size="sm" className="text-foreground/75">
              This step requires you to{" "}
              <span className="font-bold text-foreground">
                sign a message with our Mastercard® partner Immersve
              </span>{" "}
              in order to initiate the process.
            </Text>
          )}

          {isConnected && !hasFundingSource && (
            <Text size="sm" className="text-foreground/75">
              You are already signed in{" "}
              <span className="font-bold text-foreground">
                with our Mastercard® partner Immersve
              </span>{" "}
              , please click proceed in order to revalidate this step.
            </Text>
          )}
        </div>
        <Button
          readOnly={
            isConnecting ||
            isCreatingFundingSource ||
            !isEmpty(hasFundingSource)
          }
          variant={hasFundingSource ? "success" : "primary"}
          size="md"
          className="w-fit self-end"
          onClick={handleSignInMessage}
        >
          {signLabel}
          {(isConnecting ||
            isUserProfileLoading ||
            isCreatingFundingSource) && <Spinner className="mx-0" />}
        </Button>
      </div>
      <Separator orientation="vertical" className="max-md:hidden" />
      <Separator className="md:hidden" />
      <div className="flex flex-1 flex-col justify-between gap-5">
        <div className="flex flex-col gap-3">
          <Text
            variant="accent"
            size="2xl"
            className="w-fit rounded-full bg-foreground px-7 py-4 font-bold"
          >
            2
          </Text>
          <Text size="xl" className="font-bold">
            Quick Verification
          </Text>
          <Text size="sm" className="text-foreground/75">
            Verify your identity by going through an easy and quick
            verification. This step will require you to{" "}
            <span className="font-bold text-foreground">pay a minimal fee</span>
            , charged through your wallet address.{" "}
          </Text>
        </div>
        <Button
          variant={getKycBtnVariant(kyc?.status ?? "")}
          size="md"
          className="w-fit self-end"
          disabled={!hasFundingSource}
          onClick={handleQuickVerification}
        >
          {verificationLabel}
          {isKycLoading && <Spinner className="mx-0" />}
        </Button>
      </div>
      <Separator orientation="vertical" className="max-md:hidden" />
      <Separator className="md:hidden" />
      <div className="flex flex-1 flex-col justify-between gap-5">
        <div className="flex flex-col gap-3">
          <Text
            variant="accent"
            size="2xl"
            className="aspect-square w-fit rounded-full bg-foreground px-7 py-4 font-bold"
          >
            3
          </Text>
          <Text size="xl" className="font-bold">
            Mastercard Creation
          </Text>
          <Text size="sm" className="text-foreground/75">
            Hooray! You are on the final step! Create a Mastercard to fully
            enjoy the services offered.
          </Text>
        </div>
        <Button
          variant={mastercard.hasMastercard ? "success" : "primary"}
          size="md"
          className="w-fit self-end"
          disabled={!isKycCompleted}
          readOnly={mastercard.hasMastercard}
          onClick={handleCreateMastercard}
        >
          {mastercard.hasMastercard
            ? "Mastercard Created!"
            : "Create a Mastercard"}
          {mastercard.isLoading && <Spinner className="mx-0" />}
        </Button>
      </div>
    </div>
  );
}
