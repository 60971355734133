import { Text } from "../typography";

type DataTileProps = {
  title: string;
  value: string | number;
  currency?: string;
};

export function DataTile({ title, value, currency }: DataTileProps) {
  return (
    <div>
      <Text size="sm" variant="accent">
        {title}
      </Text>
      <Text size="lg">
        {value} {currency}
      </Text>
    </div>
  );
}
