import { TrnNativeApiContext } from "@/context/trnNativeApi.context";
import { useContext } from "react";

export function useTrnNativeApi() {
  const trnNativeApiContext = useContext(TrnNativeApiContext);

  if (!trnNativeApiContext) {
    throw new Error("useTrnApi must be used within a TrnProvider");
  }

  return trnNativeApiContext;
}
