import MastercardIcon from "@/assets/images/mc-logo.svg";
import { ChainLabel, Text } from "@/components";
import { ButtonsSet } from "@/components/spendingAccount";
import { Card, Separator, Skeleton } from "@/components/ui";
import { useBalances, useDashboard } from "@/hooks";
import { cn } from "@/lib/utils";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { Routes } from "@/routes/routers";
import { NavLink } from "react-router-dom";

type SpendingProps = {
  isConnecting: boolean;
  isLoading?: boolean;
};

export default function SpendingAccount(props: Readonly<SpendingProps>) {
  const { isConnecting } = props;
  const { spendingAccountBalance, balanceIsProcessing } = useBalances();
  const { isConnected } = useImmersveState();
  const { info } = useDashboard();

  const displayBalance = () => {
    return (
      <div className="text-end max-sm:text-start">
        {balanceIsProcessing || isConnecting ? (
          <div className="flex items-center gap-2">
            <Skeleton className="h-6 w-20" />
            <Text
              size="2xl"
              className={cn(
                "text-right font-normal text-accent",
                !isConnected && !isConnecting && "blur-sm",
              )}
            >
              {" "}
              USDC
            </Text>
          </div>
        ) : (
          <Text size="2xl">
            <span className={cn(!isConnected && !isConnecting && "blur-sm")}>
              {spendingAccountBalance?.toFixed(2) ?? 0}
            </span>
            <span className="font-normal text-accent"> USDC</span>
          </Text>
        )}
        <Separator />
        <Text variant="label">Balance</Text>
      </div>
    );
  };

  return (
    <Card radius="md" size="md">
      <div className="flex justify-between @container/spending">
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full flex-col-reverse flex-wrap justify-between gap-2 @sm/spending:flex-row">
            <div>
              <Text size="2xl" className="font-bold">
                Spending Account
              </Text>
              <NavLink
                to={Routes.ACCOUNT_INFORMATION.replace(
                  ":account",
                  info?.immersveAddress ?? "",
                )}
                className="text-primary"
              >
                Account Information
              </NavLink>
            </div>
            <ChainLabel className="self-start pt-2 opacity-45" />
          </div>
          <div className="flex flex-wrap justify-between gap-4 max-sm:flex-col-reverse">
            <div className="flex items-center gap-2">
              <img src={MastercardIcon} alt="Mastercard" />
              <Text className="max-w-20 text-xs" variant="label">
                Mastercard Linked
              </Text>
            </div>
            <div className="mt-2 flex flex-col justify-between">
              {displayBalance()}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-9 flex flex-wrap items-center gap-5">
        <ButtonsSet />
      </div>
    </Card>
  );
}
