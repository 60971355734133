import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const labelVariants = cva(
  "text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      variant: {
        default: "",
        transfers:
          "all:unset absolute text-base left-3 top-[-10px] h-3 bg-secondary-foreground px-3 text-white/50 text-base t-0 l-0 color-white/50 pl-[0.75rem] pr-[0.9375rem] bg-[#062334]",
      },
      size: {
        default: "h-10 py-4.5 px-5",
        sm: "h-9 rounded-md px-3 max-sm:px-2",
        lg: "h-11 rounded-md px-8",
        xl: "h-12 rounded-md px-5",
        icon: "h-10 w-10",
        regular: "text-base",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, variant, size, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants({ variant, size }), className)}
    {...props}
  />
));

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
