import { toast } from "@/components/hooks/use-toast";

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return typeof error === "object" && error !== null && "message" in error;
}

export function handleError(
  error: unknown,
  defaultMessage = "Something went wrong",
) {
  let errorMessage: string;

  if (isErrorWithMessage(error)) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = defaultMessage;
  }

  toast({
    title: "Error!",
    description: errorMessage,
    variant: "error",
    duration: 5000,
  });

  console.error(error);
}
