import {
  ImmersveKycResponse,
  KycAuthRequest,
  KycAuthResponse,
  KycUrlResponse,
} from "@/services/interfaces";
import { api } from "../baseApi";

export const kycApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createKycUrl: builder.mutation<KycUrlResponse, void>({
      query: () => ({
        url: "kyc",
        method: "POST",
      }),
    }),
    createKycOAuth: builder.mutation<KycAuthResponse, KycAuthRequest>({
      query: ({ code, state }) => ({
        url: `kyc/oauth/callback?code=${code}&state=${state}`,
        method: "POST",
      }),
    }),
    updateKycNotification: builder.mutation<boolean, void>({
      query: () => ({
        url: "kyc/user-notification",
        method: "PATCH",
      }),
    }),
    getImmersveKycProfile: builder.query<ImmersveKycResponse, void>({
      query: () => ({
        url: "immersve/kyc-profile",
      }),
    }),
  }),
});

export const {
  useCreateKycUrlMutation,
  useCreateKycOAuthMutation,
  useUpdateKycNotificationMutation,
  useGetImmersveKycProfileQuery,
} = kycApi;
