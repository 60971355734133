import { Dropdown, Text } from "@/components";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  Separator,
} from "@/components/ui";
import { useNavigationItems } from "@/hooks";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { MenuOption } from "@/types/sort";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

/**
 * Wide Screen Navigation Menu
 * @returns
 */
export default function Menu() {
  const { items } = useNavigationItems();
  const { pathname } = useLocation();
  const { toggleModal } = useModalState();

  const navigate = useNavigate();

  function getCurrentPath() {
    return pathname === Routes.DASHBOARD ? "" : pathname;
  }

  return (
    <NavigationMenu className="hidden xl:flex">
      <NavigationMenuList>
        {items
          .filter((item) => {
            return item.path !== Routes.ADMIN;
          })
          .map((item) => {
            return (
              !item.isHidden && (
                <NavigationMenuItem key={`${item.label}-${item.id}`}>
                  <div className="flex items-center">
                    {item.path === Routes.TRANSACTION ? (
                      <Dropdown
                        className="min-w-48"
                        label={
                          <Text
                            variant={`${pathname.includes("transaction") ? "active" : "menu"}`}
                            size="sm"
                            className="p-2.5 capitalize"
                          >
                            {item.label}
                          </Text>
                        }
                        options={item.subItems as unknown as MenuOption[]}
                        onSelectOption={(option) => {
                          toggleModal({
                            title: option?.label,
                            id: option?.label.toLowerCase(),
                            redirectBack: true,
                          });
                          navigate(getCurrentPath() + "/" + option?.path);
                        }}
                      />
                    ) : (
                      <NavLink to={item.path} className="p-2.5">
                        <Text
                          variant={`${pathname === item.path ? "active" : "menu"}`}
                          size="sm"
                        >
                          {item.label}
                        </Text>
                      </NavLink>
                    )}
                    <Separator orientation="vertical" className="h-8" />
                  </div>
                </NavigationMenuItem>
              )
            );
          })}
      </NavigationMenuList>
    </NavigationMenu>
  );
}
