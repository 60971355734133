import { Button } from "@/components/ui";

type ConfirmProps = {
  onClose: () => void;
  onNextPage: () => void;
};

export function ConfirmButtons(props: Readonly<ConfirmProps>) {
  const { onClose, onNextPage } = props;

  return (
    <div className="flex justify-end gap-2">
      <Button variant="outline" size="md" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="primary" size="md" onClick={onNextPage}>
        Confirm
      </Button>
    </div>
  );
}
