import { Deferrable, UserOperationStruct } from "@alchemy/aa-core";
import { TransactionReceipt } from "viem";
import { apiCallWithAuthToken } from "./config";

const getPaymasterAndData = (
  userOp: Deferrable<UserOperationStruct>,
  validUntil: string,
  validAfter: string,
  chainId: number,
  entrypoint: string,
) =>
  apiCallWithAuthToken
    .post<{ paymasterAndData: string }>(`/paymaster/paymasterAndData`, {
      userOp,
      validUntil,
      validAfter,
      chainId,
      entrypoint,
    })
    .then((res) => res.data);

const handleOp = (userOp: Deferrable<UserOperationStruct>, chainId: number) =>
  apiCallWithAuthToken
    .post<{ hash: string }>(`/paymaster/handleOp`, { userOp, chainId })
    .then((res) => res.data);

const txStatus = (hash: string, chainId: number) =>
  apiCallWithAuthToken
    .post<
      TransactionReceipt & { status: string; error?: string }
    >(`/paymaster/txStatus`, { hash, chainId })
    .then((res) => res.data);

export const paymaster = {
  getPaymasterAndData,
  handleOp,
  txStatus,
};
