import { Spinner, Text } from "@/components";
import {
  Button,
  Card,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui";
import { getHighlightedTexts } from "@/lib/utils";
import { useUpdateKycNotificationMutation } from "@/redux/kyc/kyc.api";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { KycProps } from "@/types/kyc";
import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { z } from "zod";

const items = [
  {
    id: "joinn-swarm",
    content:
      "I approve to the sharing of my KYC data with Joinn's current, and future, third party partners where required in order to be able to interact with Joinn’s current and future products and services.",
    highlights: [
      {
        label:
          "I approve to the sharing of my KYC data with Joinn's current, and future, third party partners where required",
        link: "",
      },
    ],
  },
];

const KycSharingForm = z.object({
  items: z.array(z.string()).refine((value) => value.length === items.length, {
    message: "You have to accept all terms.",
  }),
});

export function KycSharingApproval(props: Readonly<KycProps>) {
  const { navButtons, isStepCompleted } = props;

  const [updateKycNotification, notifResult] =
    useUpdateKycNotificationMutation();

  const [updateUserProfile, userResult] = useUpdateUserProfileMutation();

  function getDefaultValues() {
    if (isStepCompleted) {
      return ["joinn-swarm"];
    }

    return [];
  }

  const form = useForm<z.infer<typeof KycSharingForm>>({
    resolver: zodResolver(KycSharingForm),
    defaultValues: {
      items: getDefaultValues(),
    },
  });

  async function onSubmit() {
    await updateUserProfile({
      dataShareAgreement: true,
    });

    await updateKycNotification();
  }

  return (
    <div className="col-auto grid max-w-screen-xl gap-6 lg:grid-cols-2">
      <div className="flex flex-col gap-5">
        {navButtons()}
        <Text size="2xl" variant="secondary">
          Step 3: <span className="text-foreground"> KYC Sharing Approval</span>
        </Text>
        <Text size="md">
          Users must consent to sharing their KYC data with Joinn's current and
          future third-party partners should the need arise for Joinn to do so.
          This is required in order to access the available products, as well as
          any products that may be offered in the future.
        </Text>
        <Text size="md">
          This approach simplifies the user experience by reducing the need, in
          most cases, for Users to repeat the KYC process with each of Joinn's
          existing and potential partners.
        </Text>
      </div>
      <Card variant={isStepCompleted ? "success" : "default"}>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex h-full flex-col justify-between space-y-8"
          >
            <FormField
              control={form.control}
              name="items"
              render={() => (
                <FormItem>
                  {items.map((item) => {
                    const texts = getHighlightedTexts(
                      item.highlights,
                      item.content,
                    );

                    return (
                      <div key={item.id}>
                        <FormField
                          control={form.control}
                          name="items"
                          render={({ field }) => {
                            return (
                              <FormItem
                                key={item.id}
                                className="flex flex-row items-start space-y-0 py-2"
                              >
                                <FormControl>
                                  <Checkbox
                                    disabled={isStepCompleted}
                                    checked={field.value?.includes(item.id)}
                                    onCheckedChange={(checked) => {
                                      const values = [...field.value];

                                      return checked
                                        ? field.onChange([...values, item.id])
                                        : field.onChange(
                                            values.filter((value) => {
                                              return value !== item.id;
                                            }),
                                          );
                                    }}
                                  />
                                </FormControl>
                                <FormLabel className="h-full pl-3 pr-0 text-sm font-normal text-foreground/50 max-sm:text-xs">
                                  {texts.map((text, itemIndex) => {
                                    const accent = item.highlights.find(
                                      (accent) => {
                                        return accent.label === text;
                                      },
                                    );

                                    return !isEmpty(accent) ? (
                                      <span
                                        className="font-semibold"
                                        key={`${item.id}-${itemIndex}`}
                                      >
                                        {text}
                                      </span>
                                    ) : (
                                      <span key={`${item.id}-${itemIndex}`}>
                                        {text}
                                      </span>
                                    );
                                  })}
                                </FormLabel>
                              </FormItem>
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex h-fit w-full justify-end">
              <Button
                disabled={isStepCompleted}
                variant={isStepCompleted ? "success" : "primary"}
                className="rounded"
                type="submit"
                size="sm"
              >
                {isStepCompleted
                  ? "I have accepted all terms and policies"
                  : "I accept and agree to the above"}

                {(notifResult.isLoading || userResult.isLoading) && (
                  <Spinner className="mx-0" />
                )}
              </Button>
            </div>
          </form>
        </Form>
      </Card>
    </div>
  );
}
