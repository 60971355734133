import { Text } from "@/components";

type FrontCardProps = {
  balance: number;
  panLast4?: string;
  isHidden?: boolean;
  isConnected: boolean;
};

export function FrontCard(props: Readonly<FrontCardProps>) {
  const { balance, panLast4, isHidden, isConnected } = props;
  return (
    <>
      <div>
        <Text variant="label" size="sm">
          Balance
        </Text>
        <Text variant="default" className="font-medium">
          {isConnected ? balance?.toFixed(2) : "**"} USDC
        </Text>
      </div>
      {!isHidden && (
        <Text variant="default" className="font-medium">
          {panLast4 ? ` **** ${panLast4}` : "**** ****"}
        </Text>
      )}
    </>
  );
}
