import { Asset } from "@/services/interfaces/asset";
import { api } from "../baseApi";

export const assetApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsTreasuryAsset: builder.query<Asset, void>({
      query: () => ({
        url: "https://swarm.infura-ipfs.io/ipfs/QmYD5tu9XTdkrbb9TLK7vzYVx4JgFN8SxJXeghjKPW7egB",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const { useGetUsTreasuryAssetQuery } = assetApi;
