import MetaMask from "@/assets/wallets/metamask.svg";
import Coinbase from "@/assets/wallets/coinbase.svg";
import WalletConnect from "@/assets/wallets/walletconnect.svg";

export enum Connector {
  Injected = "Injected",
  MetaMask = "MetaMask",
  WalletConnect = "WalletConnect",
  Coinbase = "Coinbase Wallet",
}

interface ConnectorIcon {
  /** Make sure to pass the name of the wallet and not the id */
  name: Connector;
}

export function useConnectorIcon(props?: ConnectorIcon) {
  const getIcon = (name?: Connector) => {
    switch (name) {
      case "Injected":
      case "MetaMask":
        return MetaMask;
      case "WalletConnect":
        return WalletConnect;
      case "Coinbase Wallet":
        return Coinbase;
      default:
        return "";
    }
  };

  return { path: getIcon(props?.name), getIcon };
}
