import { config } from "@/chains/config";
import { toast } from "@/components/hooks/use-toast";
import { config as envConfig } from "@/config";
import { SUBGRAPH_URI } from "@/const";
import axiosInstance from "@/interceptor";
import { hasBaseUrl } from "@/lib/utils";
import { BaseQueryFn, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { getChainId } from "@wagmi/core";
import { AxiosError, AxiosRequestConfig } from "axios";
import { request } from "graphql-request";
import {
  BaseQueryRequestConfig,
  GraphqlQueryConfig,
} from "./interfaces/baseQuery";

export const customPutQueryFn = async (config: AxiosRequestConfig) => {
  return customQueryFn({ ...config, method: "PUT" });
};

export const customPostQueryFn = async (config: AxiosRequestConfig) => {
  return customQueryFn({ ...config, method: "POST" });
};

export const customQueryFn = async (config: AxiosRequestConfig) => {
  try {
    const response = await axiosQuery({ ...config });
    return { data: response.data };
  } catch (error) {
    console.error("Error encountered while fetching data: ", error);
    return { error: error as FetchBaseQueryError };
  }
};

export const axiosQuery = async (config: AxiosRequestConfig) => {
  const { url, headers, ...axiosConfig } = config;
  const queryUrl = hasBaseUrl(url) ? url : `${envConfig.API_URL}/${url}`;

  try {
    const response = await axiosInstance({
      ...axiosConfig,
      url: queryUrl,
      headers: headers ?? getHeader(),
    });

    return { data: response.data };
  } catch (e) {
    const error = e as AxiosError;

    return {
      error: {
        status: error.response?.status,
        data: error.response?.data,
      },
    };
  }
};

export const graphqlQuery = async ({ body, url }: GraphqlQueryConfig) => {
  const chainId = getChainId(config);
  const graphqlUrl = url ?? SUBGRAPH_URI[chainId];

  try {
    const result = await request(graphqlUrl, body);
    return { data: result };
  } catch (e) {
    const error = (e as any).toString();
    const regex = new RegExp(/: {(.*?)}./gm);
    const message = error.replace(regex, "");

    toast({
      title: "An error has occurred!",
      description: `Graphql ${message}`,
      variant: "error",
    });

    return {
      error: {
        status: (error as AxiosError).response?.status,
        message,
      },
    };
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customBaseQuery = (): BaseQueryFn<any> => {
  return async (requestConfig: BaseQueryRequestConfig) => {
    const { request, ...config } = requestConfig;
    let responseData = null;

    if (request === "graphql") {
      const response = await graphqlQuery({
        body: config.data,
        url: config.url,
      });
      responseData = response;
    } else {
      const response = await axiosQuery({ ...config });
      responseData = response;
    }

    return responseData;
  };
};

export const getHeader = () => {
  const token = localStorage.getItem("accessToken") ?? "";

  let header: Record<string, string | undefined> = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (token) {
    header = {
      ...header,
      Authorization: `Bearer ${token}`,
    };
  }

  return header;
};
