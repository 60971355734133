import * as React from "react";

import { Primitive } from "@radix-ui/react-primitive";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { Text } from "./typography";

const progressVariants = cva("", {
  variants: {
    variant: {
      default: "",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface ProgressProps
  extends React.ComponentPropsWithoutRef<typeof Primitive.div>,
    VariantProps<typeof progressVariants> {
  size?: number;
  width?: number;
  progress?: number;
  label?: string;
}

const CircularProgress = React.forwardRef<HTMLInputElement, ProgressProps>(
  ({ className, label, width = 12, size = 80, progress = 0 }, ref) => {
    return (
      <div ref={ref} className={cn("relative h-full", className)}>
        <svg
          className="-rotate-90 transform"
          width={size}
          height={size}
          viewBox={`0 0 ${size * 2} ${size * 2}`}
        >
          <circle
            cx={size}
            cy={size}
            r={size - 8}
            stroke="currentColor"
            strokeWidth={width}
            fill="transparent"
            className="text-accent"
          />
          <circle
            cx={size}
            cy={size}
            r={size - 8}
            stroke="currentColor"
            strokeWidth={width}
            fill="transparent"
            strokeDasharray="100"
            strokeDashoffset={`calc(100 - ${progress})`}
            className="text-primary"
            pathLength="99"
          />
        </svg>
        <Text
          variant="primary"
          size="lg"
          className="absolute top-0 flex h-full w-full items-center justify-center"
        >
          {label || `${progress}%`}
        </Text>
      </div>
    );
  },
);
CircularProgress.displayName = "CircularProgress";

export { CircularProgress, type ProgressProps };
