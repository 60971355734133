import { config } from "@/config";
import { useSpending } from "@/hooks";
import {
  useCreateCardMutation,
  useGetCardDetailsQuery,
  useGetCardTokenMutation,
  useListCardsQuery,
} from "@/redux/immersve/immersve.api";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { TokenResp } from "@/services/interfaces";
import { getImmersveTokenData, handleError } from "@/utils";
import axios from "axios";
import { isError } from "lodash";
import { useCallback, useState } from "react";

export function useMastercard() {
  const { cardholderAccountId } = getImmersveTokenData();
  const { hasFundingSource } = useSpending();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const [getCardToken] = useGetCardTokenMutation();
  const [secretData, setSecretData] = useState<null | TokenResp>(null);
  const [secretDataIsLoading, setSecretDataIsLoading] = useState(false);

  const {
    data: listCardsData,
    refetch: listCardsRefetch,
    isFetching: listCardsIsFetching,
  } = useListCardsQuery(cardholderAccountId, {
    skip: !cardholderAccountId,
  });

  const cardId = listCardsData?.items?.[0]?.id;

  const cardDetails = useGetCardDetailsQuery(cardId as string, {
    skip: !cardId,
  });

  const [createCard, { isLoading: createCardIsLoading }] =
    useCreateCardMutation();

  async function createMastercard() {
    try {
      if (!hasFundingSource) throw new Error("No funding source found");
      await createCard({
        cardProgramId: config.IMMERSVE_CARD_PROGRAM_ID,
        fundingSourceId: hasFundingSource.id,
      }).unwrap();

      await updateUserProfile({
        haveMastercard: true,
      });

      await listCardsRefetch();
    } catch (error) {
      console.error("Error creating mastercard", error);
      handleError(error);
    }
  }

  const getCardSecret = useCallback(async () => {
    try {
      setSecretDataIsLoading(true);
      const cardTokenResp = await getCardToken(cardId as string).unwrap();
      const callbackUrl = cardTokenResp?.callbackUrl;
      if (!callbackUrl) {
        throw new Error("Callback URL not found in cardToken response");
      }
      const response = await axios.get(callbackUrl);
      setSecretData(response.data);
    } catch (error) {
      console.error("Error in cardToken", error);
      handleError(error);
    } finally {
      setSecretDataIsLoading(false);
    }
  }, [cardId]);

  return {
    cardDetails,
    secretData: {
      get: getCardSecret,
      data: secretData,
      isLoading: secretDataIsLoading,
      isError: isError(secretData),
    },
    mastercard: {
      create: createMastercard,
      hasMastercard: (listCardsData?.items?.length ?? 0) > 0,
      isLoading: createCardIsLoading,
      isError: isError(listCardsData),
    },
  };
}
