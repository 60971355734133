import { cn } from "@/lib/utils";
import { TooltipContentProps } from "@radix-ui/react-tooltip";
import { isString } from "lodash";
import { Text } from "./typography";
import {
  Tooltip as ShadTooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "./ui";

export type TooltipProps = Omit<TooltipContentProps, "content"> & {
  children?: React.ReactNode;
  content: React.ReactNode | string;
  delay?: number;
};

export function Tooltip(props: Readonly<TooltipProps>) {
  const { children, content, side = "top", className, delay = 500 } = props;

  return content ? (
    <ShadTooltip delayDuration={delay}>
      <TooltipTrigger asChild>
        {isString(children) ? <Text>{children}</Text> : children}
      </TooltipTrigger>
      <TooltipContent
        className={cn(
          "max-w-xs whitespace-pre-line break-words rounded-sm",
          className,
        )}
        side={side}
      >
        <TooltipArrow className="h-2 w-4 fill-accent" />
        {isString(content) ? <Text>{content}</Text> : content}
      </TooltipContent>
    </ShadTooltip>
  ) : (
    <>{children}</>
  );
}
