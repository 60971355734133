import ReservesIcon from "@/assets/icons/reserves.svg";
import { Link, Text } from "@/components";
import { Separator } from "@/components/ui";
import { useIcons } from "@/hooks";
import { numberWithCommas } from "@/lib/utils";
import { isNil, isNumber } from "lodash";
import React from "react";
import { AccordionCard } from "./accordionCard";

type FieldProps = {
  value?: number | string;
  link?: string;
  label?: string;
};

export type VaultOverviewProps = {
  tvl?: FieldProps;
  reserves?: FieldProps;
  reservesTarget?: string;
  tokenizedSecurity?: FieldProps;
  liquidity?: string;
  info?: FieldProps;
  heading?: React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

export type VaultsFieldProps = FieldProps & {
  customField?: React.ReactNode;
  divider?: boolean;
};

function VaultsField(props: Readonly<VaultsFieldProps>) {
  const { customField, value, link, label, divider = true } = props;

  if (isNil(value) && isNil(customField)) {
    // do not render anything -- return false instead of null
    return false;
  }

  return (
    <div className="grid gap-3">
      <div className="items-center justify-between gap-3 max-sm:flex">
        <Text size="md" variant="accent">
          {label}
        </Text>
        {customField ?? (
          <div className="grid grid-flow-col items-center justify-start gap-1 text-end max-[500px]:grid-flow-row max-[500px]:gap-1">
            <Text size="md" className="max-sm:text-end">
              {value}
            </Text>
            {link && (
              <>
                <Separator
                  orientation="vertical"
                  className="mx-2 h-full max-[500px]:hidden"
                />
                <Link
                  to={link}
                  target="_blank"
                  className="flex items-center gap-2 text-nowrap text-base text-secondary underline max-sm:text-sm"
                >
                  <img
                    src={ReservesIcon}
                    alt="Proof of Reserves Icon"
                    className="h-4 max-[500px]:hidden"
                  />{" "}
                  Proof of Reserves
                </Link>
              </>
            )}
          </div>
        )}
      </div>
      {divider && <Separator className="hidden max-sm:flex" />}
    </div>
  );
}

export function VaultOverviewCard(props: Readonly<VaultOverviewProps>) {
  const {
    tvl,
    tokenizedSecurity,
    reservesTarget,
    reserves,
    liquidity,
    info,
    heading,
    children,
    icon,
  } = props;
  const { ShareIcon } = useIcons();

  function formatValue(value?: string | number) {
    if (value) {
      const formatted = numberWithCommas(Number(value).toFixed(2));
      return `${formatted} USDC`;
    }

    return value;
  }

  return (
    <AccordionCard id="vault-overview" label="Vault Overview" icon={icon}>
      {heading}
      <div className="grid gap-3">
        <VaultsField
          value={formatValue(tvl?.value)}
          link={tvl?.link}
          label="Total Value Locked in Vault"
        />
        <VaultsField
          value={formatValue(tokenizedSecurity?.value)}
          link={tokenizedSecurity?.link}
          label="Tokenized Securities Value"
        />
        <VaultsField
          value={formatValue(reserves?.value)}
          link={reserves?.link}
          label="USDC Reserves Value"
        />
        <VaultsField value={reservesTarget} label="Reserves Target" />
        <VaultsField value={liquidity ?? "Instant"} label="Liquidity" />
        <VaultsField
          divider={false}
          customField={
            <div className="flex gap-5">
              <Link
                to={info?.link ?? ""}
                target="_blank"
                className="flex items-center gap-3 text-end text-base underline max-sm:text-sm"
              >
                {info?.label}
                <ShareIcon />
              </Link>
            </div>
          }
          label="Detailed Information"
        />
      </div>
      {children}
    </AccordionCard>
  );
}
