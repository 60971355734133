import { Text } from "@/components/typography";
import { Button } from "@/components/ui";
import { useModalState } from "@/redux/modal/modal.slice";

export function DisconnectWallet() {
  const { closeModal } = useModalState();

  return (
    <div className="m-5 flex grow flex-col items-center gap-7">
      <div className="flex w-full flex-col gap-10">
        <Text size="2xl" className="font-normal">
          Unable to Disconnect Wallet
        </Text>
        <Text size="md">
          You are unable to disconnect a wallet from a profile that has been
          verified.
        </Text>
        <Text size="md">
          Please contact us for more information: hello@joinn.io
        </Text>
      </div>

      <Button
        type="button"
        className="self-end font-normal"
        onClick={closeModal}
      >
        Close
      </Button>
    </div>
  );
}
