import { useFormatToken } from "@/hooks";
import { useListTransactionsQuery } from "@/redux/immersve/immersve.api";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { Routes } from "@/routes/routers";
import { getImmersveTokenData } from "@/utils";
import { useNavigate } from "react-router-dom";
import {
  ActivityCard,
  ActivityTile,
  ActivityTileSkeleton,
} from "../activityPanel";
import { Button } from "../ui";

export function RecentMastercardActivity() {
  const { formatTokenAmount } = useFormatToken();
  const navigate = useNavigate();
  const { isConnected } = useImmersveState();

  const immersveTokenData = getImmersveTokenData();
  const { data, isFetching } = useListTransactionsQuery(
    immersveTokenData.cardholderAccountId,
    {
      skip: !immersveTokenData.cardholderAccountId,
    },
  );
  const historyTransaction = data?.items.slice(0, 4);
  function handleNavigation(route: string) {
    navigate(route);
  }

  if (!isConnected) return;
  return (
    <ActivityCard title="Mastercard Activity" route={Routes.HISTORY}>
      {isFetching && (
        <>
          <ActivityTileSkeleton />
          <ActivityTileSkeleton />
          <ActivityTileSkeleton />
        </>
      )}

      {!isFetching &&
        historyTransaction &&
        (historyTransaction.length > 0 ? (
          // (false ? (
          <>
            {historyTransaction
              ?.slice(0, 5)
              .map((transaction) => (
                <ActivityTile
                  key={transaction.id}
                  date={transaction.transactionDate ?? ""}
                  caption={"Payment"}
                  description={transaction.cardAcceptor.name}
                  amount={formatTokenAmount(transaction.amount) + " USD"}
                />
              ))}
            <Button
              variant="ghost"
              className="p-0 text-primary"
              size="sm"
              onClick={() =>
                handleNavigation(Routes.HISTORY + "/" + Routes.MASTERCARD)
              }
            >
              Show more
            </Button>
          </>
        ) : (
          <div className="flex h-10 items-center justify-center text-accent">
            No recent history
          </div>
        ))}
    </ActivityCard>
  );
}
