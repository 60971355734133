import { Outlet } from "react-router-dom";

export function Orders() {
  return (
    <div className="py-5">
      <p>Orders Content here</p>
      <Outlet />
    </div>
  );
}
