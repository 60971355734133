import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Card,
  Separator,
} from "@/components/ui";
import { Text } from "@/components";
import { useState } from "react";

type CardAccordionProps = {
  id: string;
  icon?: React.ReactNode;
  label?: string | React.ReactNode;
  children?: React.ReactNode;
};

export function AccordionCard(props: Readonly<CardAccordionProps>) {
  const { id, label, icon, children } = props;

  const [isCollapsed, setIsCollapsed] = useState<string>(id);

  return (
    <Card className="rounded-lg p-0 max-sm:p-0">
      <Accordion
        type="single"
        collapsible
        value={isCollapsed}
        onValueChange={setIsCollapsed}
      >
        <AccordionItem value={id}>
          <AccordionTrigger className="px-8 py-6 max-sm:p-4">
            {typeof label === "string" ? (
              <Text
                variant="primary"
                size="2xl"
                className="flex items-center gap-8 text-left max-sm:grid max-sm:gap-1"
              >
                {label} {icon}
              </Text>
            ) : (
              label
            )}
          </AccordionTrigger>
          <Separator />
          <AccordionContent className="flex flex-col gap-8 px-8 py-6 max-sm:p-4">
            {children}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}
