import { TransactionForm, TransactionSummary } from "@/components/transaction";
import { Button, Card } from "@/components/ui";
import { Transactions } from "@/const";
import { useDashboard } from "@/hooks";
import { useTransactions } from "@/hooks/useTransactions";
import { cn } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { X } from "lucide-react";
import { useEffect, useState } from "react";

type TransferProps = {
  fromAddress?: string;
  type?: "modal" | "panel";
};

export function Transfer({
  fromAddress,
  type = "modal",
}: Readonly<TransferProps>) {
  const { state, actions } = useTransactions(
    Transactions.TRANSFER,
    fromAddress,
  );
  const { closeModal } = useModalState();
  const [isSummary, setIsSummary] = useState(false);

  const { refetch } = useDashboard();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Card
      className={cn(
        "p-5 max-md:border-none max-md:pb-7 max-md:shadow-none",
        type === "panel" && "border-none bg-transparent p-0 pb-3 shadow-none",
      )}
    >
      {type === "modal" && (
        <Button
          className="absolute right-6 top-6 max-md:top-5"
          disabled={state.isProcessing}
          onClick={() => {
            closeModal();
          }}
        >
          <X />
        </Button>
      )}
      {isSummary ? (
        <TransactionSummary
          onBackPage={(value) => {
            actions.clearError();
            setIsSummary(value);
          }}
          state={state}
          actions={actions}
          type={type}
          transaction={Transactions.TRANSFER}
        />
      ) : (
        <TransactionForm
          state={state}
          actions={actions}
          hasFromDetails
          hasToDetails
          transaction={Transactions.TRANSFER}
          onNextPage={() => {
            actions.validateAmountField();
            setIsSummary(true);
          }}
        />
      )}
    </Card>
  );
}
