import { Spinner, Text } from "@/components";
import { Button, Card, Skeleton } from "@/components/ui";
import { cn } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { Routes } from "@/routes/routers";
import { useNavigate } from "react-router-dom";

type PreKycProps = {
  isConnecting: boolean;
  isLoading: boolean;
};

export function PreKycSpending(props: Readonly<PreKycProps>) {
  const { isConnecting, isLoading } = props;
  const { toggleModal } = useModalState();
  const navigate = useNavigate();

  return (
    <Card radius="md" size="md" className="flex flex-col gap-3">
      <Text size="2xl" className="font-bold">
        Spending Account
      </Text>
      <div className="relative">
        <Skeleton
          className={cn("absolute block h-full w-full", !isLoading && "hidden")}
        />
        <Text
          size="sm"
          className={cn("text-foreground/75", isLoading && "invisible")}
        >
          Your Spending Account allows you to instantly create and link a
          digital Mastercard, allowing you to spend your digital assets anywhere
          in the world where Mastercard is accepted.
        </Text>
      </div>
      <div className="relative">
        <Skeleton
          className={cn("absolute block h-full w-full", !isLoading && "hidden")}
        />
        <Text
          size="sm"
          className={cn("text-foreground/75", isLoading && "invisible")}
        >
          Enjoy this service by creating a Spending Account and Mastercard now!
        </Text>
      </div>
      <div className="flex items-center justify-between gap-5 pt-5 max-[500px]:items-start max-[440px]:flex-col">
        <div className="flex">
          {isConnecting && (
            <>
              <Text size="sm" className="flex w-fit font-bold">
                Connecting
              </Text>
              <Spinner className="w-fit" />
            </>
          )}
        </div>
        <div className="flex gap-3 self-end max-[500px]:flex-col max-[500px]:items-start">
          <Button
            disabled={isConnecting}
            variant="secondary"
            size="sm"
            className="max-[500px]:w-full"
            onClick={() => {
              toggleModal({
                id: "hide-spending",
                title: "Are you sure?",
              });
            }}
          >
            No, thanks!
          </Button>
          <Button
            disabled={isConnecting}
            variant="primary"
            size="sm"
            onClick={() => {
              navigate(Routes.MASTERCARD_ACTIVATION);
              toggleModal({
                id: "pre-kyc-modal",
                isFullWidth: true,
                redirectBack: true,
              });
            }}
          >
            Yes, I’d like to create a Spending Account and Mastercard
          </Button>
        </div>
      </div>
    </Card>
  );
}
