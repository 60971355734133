export const MOCK_HISTORIC_APY = [
  { date: "1728472045000", apy: 6.27 },
  { date: "1728535890000", apy: 8.75 },
  { date: "1728579090000", apy: 4.27 },
  { date: "1728688045000", apy: 0 },
  { date: "1728749245000", apy: 5.6 },
  { date: "1728850045000", apy: 7.5 },
  { date: "1728965245000", apy: 6.3 },
];

export const MOCK_SHARE_PRICE = [
  { date: "1728472045000", price: 1.25 },
  { date: "1728535890000", price: 1.75 },
  { date: "1728579090000", price: 2.56 },
  { date: "1728688045000", price: 1.6 },
  { date: "1728749245000", price: 2.5 },
  { date: "1728850045000", price: 2.3 },
  { date: "1728965245000", price: 0.82 },
];
