import { utils } from "ethers";
import { Address } from "viem";

export type Token = {
  address: Address;
  decimals: number;
};

export const TOKEN_ADDRESSES: { [key: string]: { [key: string]: Token } } = {
  "80001": {
    USDC: {
      address: "0x2fac06acfaeb42cc3b5327fcf53f48d9da72749d",
      decimals: 6,
    },
  },
  "80002": {
    USDC: {
      address: "0x41e94eb019c0762f9bfcf9fb1e58725bfb0e7582",
      decimals: 6,
    },
  },
  "137": {
    USDC: {
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      decimals: 6,
    },
  },
  "7672": {
    USDC: {
      address: "0xcCcCCccC00004464000000000000000000000000",
      decimals: 18,
    },
  },
  "11155111": {
    USDC: {
      address: "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238",
      decimals: 6,
    },
  },
  "421614": {
    USDC: {
      address: "0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d",
      decimals: 6,
    }
  }
};

export const parseWeiToToken = (
  weiValue: string | undefined,
  chainId: number | undefined,
  tokenSymbol = "USDC",
): string => {
  if (!weiValue || !chainId) return "0";
  const token = TOKEN_ADDRESSES[chainId][tokenSymbol];
  const parsedValue = utils.formatUnits(weiValue, token.decimals);
  return parsedValue;
};
