import {
  AccountOverviewCard,
  AssetCard,
  HoldingsCard,
  QuickLinks,
  TransactionPanel,
} from "@/components/accountInfo";
import { CurrencyCalculator } from "@/components/accountInfo/calculator";
import { Card } from "@/components/ui";
import { Accounts, AccountsAddressByChain, IMMERSVE } from "@/const";
import { SPENDING_ACCOUNT_ASSET } from "@/const/assets";
import { useMemo } from "react";
import { useAccount } from "wagmi";

export type SpendingProps = {
  accountId: string;
  isKYCApproved?: boolean;
};

export function SpendingAccount(props: Readonly<SpendingProps>) {
  const { accountId, isKYCApproved } = props;
  const { chainId } = useAccount();

  const spendingAddr = useMemo(() => {
    return chainId
      ? AccountsAddressByChain[chainId].find((account) => {
          return account.label === Accounts.SPENDING_ACCOUNT;
        })?.address
      : "";
  }, [chainId]);

  // TODO: Add query to useGetAutomatedLending when supported
  // TODO: Dummy data while spending account is not supported yet in TRN
  const assetDetails = {
    properties: [
      { property_type: "deposit_asset", value: "USDC" },
      { property_type: "redemption_asset", value: "USDC" },
      {
        property_type: "contract_address",
        value: spendingAddr ?? "",
      },
      { property_type: "kya_version", value: "1.0" },
      { property_type: "protocol", value: "Immersve" },
      { property_type: "immersve_audits", value: IMMERSVE },
      { property_type: "joinn_audits", value: IMMERSVE },
    ],
  };

  return (
    <>
      <Card
        variant="container"
        radius="lg"
        size="md"
        className="col-span-3 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
      >
        {/* TODO: Dummy data while spending account is not supported yet in TRN */}
        <HoldingsCard balance={0} dailyLoad={5000} yearlyLoad={100000} />
        <AccountOverviewCard />
        <CurrencyCalculator />
      </Card>
      <Card
        className="col-span-2 flex flex-col gap-5 max-lg:col-auto max-lg:gap-3"
        variant="container"
        radius="lg"
        size="md"
      >
        <QuickLinks />
        {isKYCApproved && <TransactionPanel address={accountId} />}
        <AssetCard fields={SPENDING_ACCOUNT_ASSET} asset={assetDetails} />
      </Card>
    </>
  );
}
