import { useToast } from "@/components/hooks/use-toast";
import { getErrorMessage } from "@/lib/utils";
import {
  useGenerateChallengeMutation,
  useGenerateTokenMutation,
} from "@/redux/auth/auth.api";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { useUser } from "@/redux/user/user.slice";
import { Routes } from "@/routes/routers";
import { ChallengeRequest } from "@/services/interfaces";
import { AxiosError } from "axios";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Connector, useAccount, useConnect, useSignMessage } from "wagmi";

export function useLogin() {
  const accessToken = localStorage.getItem("accessToken") ?? "";

  const navigate = useNavigate();

  const { toast } = useToast();
  const { connectAsync } = useConnect();
  const { signMessageAsync } = useSignMessage();
  const {
    connector,
    isConnected,
    isDisconnected,
    isConnecting,
    chainId,
    address = "",
  } = useAccount();

  const { logInUser } = useUser();
  const { lastLoggedInWallet, setLastLoggedInWallet } = useUser();
  const [generateChallenge] = useGenerateChallengeMutation();
  const [generateToken] = useGenerateTokenMutation();
  const [bearer, setBearer] = useState<string>(accessToken);
  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

  const { isSuccess, error, refetch, isFetching } = useUserProfileQuery(
    undefined,
    {
      skip: !chainId || !bearer,
    },
  );

  const status = useMemo(() => {
    return error && (error as AxiosError)?.status;
  }, [error]);

  async function generateAccessCode(
    signature: string,
    nonce: string,
    address: string,
  ) {
    if (!isEmpty(signature)) {
      const { token } = await generateToken({
        address,
        nonce,
        signature,
      }).unwrap();

      if (token) {
        if (bearer) {
          refetch();
        }
        setBearer(token);
        setIsSigningIn(false);
      }
    }
  }

  async function signAccess({
    address,
    chainId,
    reSignAccess,
  }: ChallengeRequest) {
    setIsSigningIn(true);
    // if there is already a bearer but already invalid, generates a new code
    if (reSignAccess || !bearer || (bearer && status === 401 && chainId)) {
      try {
        /** #1: Generate a message to be signed */
        const { message, nonce } = await generateChallenge({
          address,
          chainId,
        }).unwrap();

        /** #2: Sign the message generated by the challenge */
        const signature = await signMessageAsync({
          message: message ?? "",
        });

        /** #3: Generate access token using the signature and store the token in localstorage */
        await generateAccessCode(signature, nonce, address);
        setLastLoggedInWallet(address);
      } catch (error) {
        setIsSigningIn(false);
        const message = getErrorMessage(error);

        toast({
          description: message,
          variant: "error",
        });
      }
    }
    setIsSigningIn(false);
  }

  async function handleConnect(connector: Connector) {
    if (isDisconnected) {
      setIsSigningIn(true);
      try {
        await connectAsync({ connector });
      } catch (error) {
        const message = getErrorMessage(error);
        toast({
          description: message,
          variant: "error",
        });
      } finally {
        setIsSigningIn(false);
      }
    } else if (isConnected) {
      await signAccess({ address, chainId: chainId?.toString() ?? "" });
    }
  }

  useEffect(() => {
    if (!isFetching) {
      if (isConnected && !lastLoggedInWallet) setLastLoggedInWallet(address);
      // If connected to wallet but no access code, generatecode
      // If connected to wallet but has access code, check if expired, if yes, generate code
      if (isConnected && isSuccess && address === lastLoggedInWallet) {
        // If connected to wallet and has valid access code, loginUser then navigate to dashboard
        setLastLoggedInWallet(address);
        logInUser();
        navigate(Routes.DASHBOARD);
      }
      if (isConnected && address !== lastLoggedInWallet) return;
      signAccess({
        address,
        chainId: chainId?.toString() ?? "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isSuccess, bearer, lastLoggedInWallet]);

  return {
    connect: handleConnect,
    isConnecting: isConnecting || isSigningIn,
    connector,
    signAccess,
  };
}
