import dayjs from "dayjs";
import { Text } from "../typography";
import { Card, Separator } from "../ui";

type ActivityTileProps = {
  date: string;
  caption: string;
  amount: string;
  description?: string;
};

export function ActivityTile({
  date,
  caption,
  amount,
  description,
}: Readonly<ActivityTileProps>) {
  const isUnixTimestamp = /^\d+$/.test(date);
  const parsedDate = isUnixTimestamp ? dayjs.unix(Number(date)) : dayjs(date);

  const dateFormatted = parsedDate.format("MMM DD");
  const timeFormatted = parsedDate.format("hh:mm A");

  return (
    <Card
      size="sm"
      variant="secondary"
      className="flex items-center justify-between gap-3"
    >
      <div className="flex w-20 flex-col">
        <Text variant="default" size="sm" className="uppercase">
          {dateFormatted}
        </Text>
        <Text variant="label" size="sm" className="text-nowrap">
          {timeFormatted}
        </Text>
      </div>
      <Separator className="h-full" orientation="vertical" />
      <div className="flex w-full flex-wrap items-center justify-between gap-2">
        <div className="flex flex-col">
          <Text variant="default" size="sm" className="capitalize">
            {caption}
          </Text>
          <Text variant="label" size="sm">
            {description}
          </Text>
        </div>
        <Text className="text-right">{amount}</Text>
      </div>
    </Card>
  );
}
