import axios from "axios";
import { Hex } from "viem";
import { config } from "../config";
// TODO: Add ImmersveClient
// import { ImmersveClient } from "../services/ImmersveClient";
import { chainId, ENTRYPOINT_ADDRESS_V06 } from "permissionless";

const apiConfig = {
  baseURL: config.API_URL,
  // baseURL: import.meta.env.VITE_API_URL ?? "https://api-stg.bof.xyz",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const apiCall = axios.create(apiConfig);

export const apiCallWithAuthToken = axios.create(apiConfig);
apiCallWithAuthToken.interceptors.request.use(
  (conf) => {
    conf.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken",
    )}`;
    return conf;
  },
  (error) => Promise.reject(error),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const networks: { [key: number]: any } = {
  137: {
    chainId: "0x89",
    chainName: "Polygon",
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    rpcUrls: [
      "https://rpc.ankr.com/polygon/",
      "https://polygon.llamarpc.com",
      "https://rpc.ankr.com/polygon",
    ],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  80002: {
    chainId: "0x13882",
    chainName: "Polygon Amoy",
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    rpcUrls: ["https://polygon-amoy-bor-rpc.publicnode.com"],
    blockExplorerUrls: ["https://amoy.polygonscan.com/"],
  },
  7668: {
    chainId: "0x1df4",
    chainName: "The Root Network",
    nativeCurrency: { name: "XRP", symbol: "XRP", decimals: 18 },
    rpcUrls: ["https://root.rootnet.live/archive"],
    blockExplorerUrls: ["https://rootscan.io/"],
  },
  7672: {
    chainId: "0x1df8",
    chainName: "The Root Network Porcini",
    nativeCurrency: { name: "XRP", symbol: "XRP", decimals: 18 },
    rpcUrls: ["https://porcini.rootnet.app/archive"],
    blockExplorerUrls: ["https://porcini.rootscan.io/"],
  },
  11155111: {
    chainId: "0x1b7e",
    chainName: "Sepolia",
    nativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://lb.nodies.app/v1/d0f2b847b0ec4830bb176efb61e36d31"],
    blockExplorerUrls: ["https://sepolia.etherscan.io/"],
  },
  421614: {
    chainId: "0x66eee",
    chainName: "Arbitrum Sepolia",
    nativeCurrency: { name: "Ethereum", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://api.zan.top/arb-sepolia"],
    blockExplorerUrls: ["https://sepolia.arbiscan.io/"],
  },
};

// ? Maybe we could move this to .env file
export const zerodevProjects: { [key: number]: string } = {
  // 80001: "9d2a4849-df45-49f5-889f-a9dbabae25d1",
  80002: "f2665d77-2f59-46e1-b7a5-b3fc9c54b35f",
  11155111: "77655996-7587-4529-9ab0-6b1b5bb38237",
};

const explorers: { [key: number]: string } = {
  137: "https://polygonscan.com",
  80002: "https://amoy.polygonscan.com",
  7672: "https://porcini.rootscan.io",
  7668: "https://rootscan.io",
  // Sepolia
  11155111: "https://sepolia.etherscan.io",
};

export type ZeroDevAddresses = {
  ECDSA_VALIDATOR: Hex;
  ACCOUNT_LOGIC: Hex;
  FACTORY_ADDRESS: Hex;
  SESSION_KEY_VALIDATOR: Hex;
  ENTRYPOINT_ADDRESS: Hex;
};

export const zerodevAddressByChain: {
  [key: number]: ZeroDevAddresses;
} = {
  137: {
    ECDSA_VALIDATOR: "0xd9AB5096a832b9ce79914329DAEE236f8Eea0390",
    ACCOUNT_LOGIC: "0xd3082872F8B06073A021b4602e022d5A070d7cfC",
    FACTORY_ADDRESS: "0x5de4839a76cf55d0c90e2061ef4386d962E15ae3",
    SESSION_KEY_VALIDATOR: "0x8Df2bf61F849676f00C6883743E077d391da1dDA",
    ENTRYPOINT_ADDRESS: ENTRYPOINT_ADDRESS_V06,
  },
  80002: {
    ECDSA_VALIDATOR: "0xd9AB5096a832b9ce79914329DAEE236f8Eea0390",
    ACCOUNT_LOGIC: "0xd3082872F8B06073A021b4602e022d5A070d7cfC",
    FACTORY_ADDRESS: "0x5de4839a76cf55d0c90e2061ef4386d962E15ae3",
    SESSION_KEY_VALIDATOR: "0x5C06CE2b673fD5E6e56076e40DD46aB67f5a72A5",
    ENTRYPOINT_ADDRESS: ENTRYPOINT_ADDRESS_V06,
  },
  // The Root Network - Mainnet
  7668: {
    ECDSA_VALIDATOR: "0x27A4f53C50F95171Fe6566FC73f92a61B0631ED1",
    ACCOUNT_LOGIC: "0xBF3e74fF15AE7FC973dF9eC094f53c2684C4d00a",
    FACTORY_ADDRESS: "0xB42fDca10E807B86ac674A2D96Fd9FE5cD0a07A3",
    SESSION_KEY_VALIDATOR: "0x55f7a819a7D937aE752B09b6866b0DDb30F8CeD7",
    ENTRYPOINT_ADDRESS: "0x599b9906AC7b7cBa96957876AB7f19ACD887D256",
  },
  // The Root Network - Porcini Testnet
  7672: {
    ECDSA_VALIDATOR: "0xC7839BdDA1809A3B668D74478b6cb36746ff0a9e",
    ACCOUNT_LOGIC: "0x85C81A06ac06DccD10c1B58dd131ED300eC692E9",
    FACTORY_ADDRESS: "0xa2b73B60dC128d3400b1B40116ddef64e2cF64ed",
    SESSION_KEY_VALIDATOR: "0x4d7F05D2c25bEcFC93fB3445e924212466d99393",
    ENTRYPOINT_ADDRESS: "0x32EB4326D133D884995845AdD48d025720648770",
  },
  11155111: {
    ECDSA_VALIDATOR: "0xd9AB5096a832b9ce79914329DAEE236f8Eea0390",
    ACCOUNT_LOGIC: "0xd3082872F8B06073A021b4602e022d5A070d7cfC",
    FACTORY_ADDRESS: "0x5de4839a76cf55d0c90e2061ef4386d962E15ae3",
    SESSION_KEY_VALIDATOR: "0x8Df2bf61F849676f00C6883743E077d391da1dDA",
    ENTRYPOINT_ADDRESS: ENTRYPOINT_ADDRESS_V06,
  },
  421614: {
    ECDSA_VALIDATOR: "0xd9AB5096a832b9ce79914329DAEE236f8Eea0390",
    ACCOUNT_LOGIC: "0xd3082872F8B06073A021b4602e022d5A070d7cfC",
    FACTORY_ADDRESS: "0x5de4839a76cf55d0c90e2061ef4386d962E15ae3",
    SESSION_KEY_VALIDATOR: "0x8Df2bf61F849676f00C6883743E077d391da1dDA",
    ENTRYPOINT_ADDRESS: ENTRYPOINT_ADDRESS_V06,
  },
};
