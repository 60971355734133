import { Text } from "@/components";
import { AccordionCard, AssetItem } from "@/components/accountInfo";
import { Accordion, Separator } from "@/components/ui";
import { Asset } from "@/services/interfaces/asset";
import { useState } from "react";

type AssetCardProps = {
  asset: Asset | undefined;
  fields: {
    id: string;
    label: string;
    properties: string[];
  }[];
};

export function AssetCard(props: Readonly<AssetCardProps>) {
  const { fields, asset } = props;

  const [isCollapsed, setIsCollapsed] = useState<string[]>(["asset-details"]);

  return (
    <AccordionCard
      id="know-your-asset"
      label={
        <Text variant="primary" size="lg">
          Know your Asset
        </Text>
      }
    >
      <Accordion
        type="multiple"
        value={isCollapsed}
        onValueChange={setIsCollapsed}
        className="grid gap-2"
      >
        {fields.map((field, index) => {
          return (
            <div key={field.label} className="grid gap-2">
              <AssetItem
                asset={asset}
                label={field.label}
                items={field.properties}
                id={field.id}
              />
              {fields.length - 1 !== index && <Separator />}
            </div>
          );
        })}
      </Accordion>
    </AccordionCard>
  );
}
