import * as React from "react";
import { cn } from "@/lib/utils";
import { Primitive } from "@radix-ui/react-primitive";
import { cva, VariantProps } from "class-variance-authority";

const textVariants = cva("", {
  variants: {
    variant: {
      default: "text-foreground",
      primary: "text-primary",
      secondary: "text-secondary",
      accent: "text-accent",
      label: "text-foreground/50",
      muted: "text-muted",
      link: "text-primary/50 underline hover:text-primary/75",
      menu: "text-foreground hover:text-accent",
      active: "text-primary hover:text-accent",
      error: "text-[hsl(353,100%,46%)]",
    },
    padding: {
      default: "",
      sm: "p",
      md: "p-2",
      lg: "p-5",
    },
    size: {
      default: "text-sm",
      xs: "text-xs",
      sm: "text-sm",
      md: "text-sm sm:text-base", // 16px
      lg: "text-base sm:text-lg", // 18px
      xl: "text-base sm:text-xl",
      "2xl": "text-lg sm:text-2xl", // 24px
      "3xl": "text-xl sm:text-3xl", // 32px
    },
  },
  defaultVariants: {
    variant: "default",
    padding: "default",
    size: "default",
  },
});

interface TextProps
  extends React.ComponentPropsWithoutRef<typeof Primitive.p>,
    VariantProps<typeof textVariants> {}

const Text = React.forwardRef<React.ElementRef<typeof Primitive.p>, TextProps>(
  ({ className, padding, variant, size, ...props }, ref) => {
    return (
      <Primitive.p
        ref={ref}
        className={cn(textVariants({ padding, variant, size, className }))}
        {...props}
      />
    );
  },
);
Text.displayName = Primitive.p.displayName;

export { Text, type TextProps };
