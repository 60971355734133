import { Skeleton } from "../ui";
import { Row } from "./HistoryTable";

type HistoryRowSkeletonProps = {
  colsNumber: number;
  isLoading: boolean;
};

export function HistoryRowSkeleton({
  colsNumber,
  isLoading,
}: Readonly<HistoryRowSkeletonProps>) {
  const cols: JSX.Element[] = [];

  Array.from({ length: colsNumber }).forEach((_, index) => {
    cols.push(
      <div className="w-full px-7 py-[0.125rem]" key={"skeleton" + index}>
        <Skeleton className="h-5 w-32" />
      </div>,
    );
  });

  if (!isLoading) return null;

  return (
    <div className="mt-1 flex flex-col gap-1">
      <Row>{cols}</Row>
      <Row>{cols}</Row>
      <Row>{cols}</Row>
    </div>
  );
}
