import { MenuOption } from "@/types";
import Dropdown from "../dropdown";
import { Text } from "../typography";

type HistoryDropdownFilterProps = {
  title: string;
  label: string | React.ReactNode;
  options: MenuOption[];
  isSorting?: boolean;
  onSelectOption: (option?: MenuOption) => void;
};

export function HistoryDropdownFilter({
  title,
  label,
  options,
  onSelectOption,
}: Readonly<HistoryDropdownFilterProps>) {
  return (
    <div className="flex flex-wrap items-center justify-start gap-2">
      <Text size="md" variant="accent">
        {title}
      </Text>
      <Dropdown
        label={<Text size={"lg"}>{label}</Text>}
        options={options}
        onSelectOption={onSelectOption}
        isSorting
        triggerProps={{
          className:
            "px-5 py-2 border rounded-sm capitalize bg-black/15 text-red-500 min-w-[200px] flex justify-between",
        }}
        className="[&>p]:text-red [&>p]:capitalize"
      />
    </div>
  );
}
