import { Text } from "@/components";
import { Skeleton } from "@/components/ui";

type BackCardProps = {
  isLoading?: boolean;
  pan?: string;
  expiry?: string;
  cvv2?: string;
};

export function BackCard(props: Readonly<BackCardProps>) {
  const { isLoading, pan, expiry, cvv2 } = props;
  return (
    <div className="flex flex-col gap-1">
      <Text variant="default" className="font-medium">
        {isLoading ? (
          <div>
            <Skeleton className="w-38 h-5 bg-accent-foreground/80" />
          </div>
        ) : pan ? (
          pan.match(/.{1,4}/g)?.join(" ")
        ) : (
          "**** **** **** ****"
        )}
      </Text>
      <div className="flex gap-4">
        <div className="flex items-center gap-2">
          <Text variant="label" size="xs">
            EXP
          </Text>
          {/* TODO: This is semantically incorrect - div within a text */}
          <Text variant="default" className="font-medium">
            {isLoading ? (
              <div>
                <Skeleton className="h-5 w-8 bg-accent-foreground/80" />
              </div>
            ) : (
              (expiry ?? "**/**")
            )}
          </Text>
        </div>
        <div className="flex items-center gap-2">
          <Text variant="label" size="xs">
            CVV2
          </Text>
          {/* TODO: This is semantically incorrect - div within a text */}
          <Text variant="default" className="font-medium">
            {isLoading ? (
              <div>
                <Skeleton className="h-5 w-8 bg-accent-foreground/80" />
              </div>
            ) : (
              (cvv2 ?? "***")
            )}
          </Text>
        </div>
      </div>
    </div>
  );
}
