import * as React from "react";
import { cn } from "@/lib/utils";
import { Primitive } from "@radix-ui/react-primitive";
import { cva, VariantProps } from "class-variance-authority";

const spinnerVariants = cva("flex items-center", {
  variants: {
    variant: {
      default: "",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface SpinnerProps
  extends React.ComponentPropsWithoutRef<typeof Primitive.div>,
    VariantProps<typeof spinnerVariants> {
  size?: string;
  width?: string;
}

const Spinner = React.forwardRef<HTMLInputElement, SpinnerProps>(
  ({ className, size = "20", width = "6" }, ref) => {
    return (
      <div ref={ref} className={cn("mx-2 animate-spin", className)}>
        <svg
          width={size}
          height={size}
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M36.639 5.60901C39.5795 6.74346 42.2677 8.44598 44.5502 10.6193C46.8327 12.7927 48.6648 15.3944 49.9418 18.2758C51.2189 21.1572 51.9159 24.2619 51.9931 27.4127C52.0702 30.5635 51.5261 33.6986 50.3916 36.6391C49.2572 39.5796 47.5547 42.2678 45.3813 44.5503C43.2079 46.8328 40.6063 48.6649 37.7248 49.9419C34.8434 51.219 31.7387 51.916 28.5879 51.9932C25.4371 52.0703 22.302 51.5262 19.3615 50.3917C16.4211 49.2573 13.7328 47.5547 11.4503 45.3814C9.16783 43.208 7.33575 40.6063 6.0587 37.7249C4.78165 34.8435 4.08464 31.7388 4.00746 28.588C3.93029 25.4372 4.47447 22.3021 5.60892 19.3616C6.74337 16.4212 8.44589 13.7329 10.6193 11.4504C12.7926 9.16792 15.3943 7.33584 18.2757 6.05879C21.1571 4.78174 24.2618 4.08473 27.4126 4.00756C30.5634 3.93038 33.6985 4.47456 36.639 5.60901L36.639 5.60901Z"
            strokeWidth={width}
            className="stroke-primary/50"
          />
          <path
            d="M36.639 5.60901C41.3676 7.43333 45.3972 10.7078 48.1504 14.9631"
            className="stroke-primary"
            strokeWidth={width}
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  },
);
Spinner.displayName = "Spinner";

export { Spinner, type SpinnerProps };
