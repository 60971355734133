import { Text } from "@/components";
import {
  DataSection,
  UserDataSection,
  VaultSection,
} from "@/components/adminPanel";
import { Card } from "@/components/ui";

export function AdminPanel() {
  return (
    <Card
      variant="container"
      size="md"
      className="col-span-3 my-5 flex flex-col gap-5 lg:col-span-2"
      radius="lg"
    >
      <Text size="lg">Admin Panel</Text>
      <DataSection />
      <VaultSection />
      <UserDataSection />
    </Card>
  );
}
