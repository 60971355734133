import { Stepper, Text } from "@/components";
import { Button, Card } from "@/components/ui";
import { Communication, UserOnboarding } from "@/pages/KYC/Steps";
import { useUserProfileQuery } from "@/redux/user/user.api";
import { useEffect, useState } from "react";
import { SuccessPage } from "./Steps/Success";
import { SupportedRegions } from "@/components/kyc";

export default function OnboardingProcess() {
  const { data: user, isSuccess, isFetching, refetch } = useUserProfileQuery();

  const [activeStep, setActiveStep] = useState(1);
  const [completedStep, setCompletedStep] = useState(0);

  const steps = ["User Onboarding", "Communication"];

  const isOnboardingCompleted =
    !!user?.bofPrivacyPolicy &&
    !!user?.bofTermsOfService &&
    !!user?.dataShareAgreement;

  function setStep(step: number) {
    setCompletedStep(step);
    setActiveStep(step + 1);
  }

  function getCompletedStep() {
    if (user?.emailVerification?.isVerified) {
      setStep(2);
    } else if (isOnboardingCompleted) {
      setStep(1);
    } else {
      setStep(0);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function getNavButtons() {
    return (
      <div className="flex gap-3 pb-5 max-md:pb-3">
        <Button
          disabled={activeStep <= 1}
          variant="outline"
          size="md"
          onClick={() => {
            handleBack();
          }}
          className="rounded"
        >
          Back
        </Button>
        <Button
          disabled={activeStep - 1 >= completedStep}
          variant="primary"
          size="md"
          className="rounded"
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    );
  }

  useEffect(() => {
    if (isSuccess) {
      getCompletedStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching]);

  return (
    <Card variant="container" size="md" radius="lg">
      {!isOnboardingCompleted && (
        <SupportedRegions>
          <Text size="sm">
            Interacting with Joinn’s Earn Accounts and other services requires
            Users to successfully complete the Onboarding and KYC process. The
            Joinn platform is currently not available for use by US persons (as
            defined here) or persons that hold identities from other countries
            not serviced. US persons and users from non-serviced countries may
            connect a wallet to the Joinn platform and interact with features
            that do not require Onboarding and KYC approval but will not be able
            to interact with the above mentioned offerings.
          </Text>
          <Text size="sm">
            Joinn supports the onboarding and KYC of Users who are both a
            citizen and a resident of the following countries.
          </Text>
        </SupportedRegions>
      )}
      <Card
        radius="lg"
        className="relative z-10 flex h-[-webkit-fill-available] min-h-[calc(100vh_-_340px)] flex-col items-center gap-20 py-14 max-md:items-start max-md:gap-2"
      >
        <Stepper
          steps={steps}
          activeStep={activeStep}
          completedStep={completedStep}
          onSelectStep={setActiveStep}
        />

        {activeStep === 1 && (
          <UserOnboarding
            navButtons={getNavButtons}
            isStepCompleted={isOnboardingCompleted}
          />
        )}

        {activeStep === 2 && (
          <Communication
            navButtons={getNavButtons}
            isStepCompleted={user?.emailVerification?.isVerified || false}
            verifiedEmail={user?.emailVerification?.email ?? ""}
            refetch={refetch}
          />
        )}

        {/* Temporarily hide the following steps - do not remove */}
        {/* {activeStep === 3 && (
        <KycVerification
          navButtons={getNavButtons}
          isStepCompleted={user?.kyc?.status === "APPROVED"}
          user={user?.kyc || null}
        />
      )} */}

        {activeStep === 3 && <SuccessPage />}
      </Card>
    </Card>
  );
}
