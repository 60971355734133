import { Transactions } from "@/const";
import { Routes } from "@/routes/routers";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { useDashboard } from "./useDashboard";

interface Menu {
  id: number;
  label: string;
  path: string;
  isAdmin?: boolean;
  isHidden?: boolean;
}

interface NavigationItem extends Menu {
  subItems?: Menu[];
}

export function useNavigationItems() {
  const { pathname } = useLocation();
  const { userProfile } = useDashboard();

  const ADMIN_PANEL = "Admin Panel";

  const items: NavigationItem[] = [
    {
      id: 0,
      label: "Joinn Dashboard",
      path: Routes.ADMIN,
      isAdmin: true,
    },
    {
      id: 1,
      label: "My Accounts",
      path: Routes.DASHBOARD,
    },
    {
      id: 2,
      label: "Transact",
      path: Routes.TRANSACTION,
      isHidden:
        !isEmpty(RegExp(/\/account/).exec(pathname)?.input) ||
        !isEmpty(RegExp(/\/kyc/).exec(pathname)?.input),
      subItems: [
        {
          id: 1,
          label: "Deposit",
          path: Routes.TRANSACTION.replace(":type", Transactions.DEPOSIT),
        },
        {
          id: 2,
          label: "Transfer",
          path: Routes.TRANSACTION.replace(":type", Transactions.TRANSFER),
        },
        {
          id: 3,
          label: "Withdraw",
          path: Routes.TRANSACTION.replace(":type", Transactions.WITHDRAW),
        },
      ],
    },
    {
      id: 3,
      label: "History",
      path: Routes.HISTORY,
    },
    {
      id: 4,
      label: "Orders",
      path: Routes.ORDERS,
    },
    {
      id: 5,
      label: ADMIN_PANEL,
      path: Routes.ADMIN_PANEL,
    },
  ];

  const isAdmin = userProfile?.roles.some((role) => role.name === "admin");

  const filteredItems = isAdmin
    ? items
    : items.filter((item) => item.label !== ADMIN_PANEL);

  return {
    items: filteredItems,
  };
}
