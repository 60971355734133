import { Button, Collapsible, CollapsibleContent } from "@/components/ui";
import { TransactionsStatus } from "@/const";
import { Spinner } from "../spinner";

type ActionButtonsProps = {
  status: TransactionsStatus;
  isProcessing: boolean;
  isError: boolean;
  type: "panel" | "modal";
  onClose: () => void;
  onTransact: () => void;
  transactionLabel: string;
};

export function ActionButtons(props: Readonly<ActionButtonsProps>) {
  const { status, type, onClose, onTransact, isProcessing, transactionLabel } =
    props;

  return (
    <div className="mt-2 flex justify-end gap-2">
      {status === TransactionsStatus.SUCCESS && type === "modal" ? (
        <Button
          variant="outline"
          size="md"
          disabled={isProcessing}
          onClick={onClose}
        >
          Close
        </Button>
      ) : (
        <>
          <Button
            variant="outline"
            size="md"
            disabled={isProcessing}
            onClick={onClose}
          >
            {type === "modal" ? "Cancel" : "Clear"}
          </Button>
          <Button
            type="button"
            variant="primary"
            size="md"
            onClick={onTransact}
            readOnly={isProcessing}
            className="gap-0 capitalize"
          >
            {transactionLabel}
            <Collapsible open={isProcessing}>
              <CollapsibleContent>
                <Spinner className="ml-2 mr-0" />
              </CollapsibleContent>
            </Collapsible>
          </Button>
        </>
      )}
    </div>
  );
}
