import { useImmersve } from "@/hooks";
import { useImmersveState } from "@/redux/immersve/immersve.slice";
import { useUser } from "@/redux/user/user.slice";
import { useAuth } from "@futureverse/auth-react";
import { useDisconnect } from "wagmi";

export function useLogout() {
  const { signOut } = useAuth();
  const { logOutUser } = useUser();
  const {
    action: { logout },
  } = useImmersve();
  const { resetSpendingHiddenState } = useImmersveState();
  const { disconnectAsync } = useDisconnect();

  async function handleDisconnect() {
    await disconnectAsync();
    resetSpendingHiddenState();
    signOut();
    logout();
    logOutUser();
  }

  return {
    disconnect: handleDisconnect,
  };
}
