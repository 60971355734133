import { Text } from "@/components";
import {
  ConfirmButtons,
  TransferCard,
  TransferDetails,
  TransferDropdown,
  TransferInput,
} from "@/components/transaction";
import { Collapsible, CollapsibleContent } from "@/components/ui";
import { Accounts, EarnAccounts, Transactions } from "@/const";
import { DataArgs, TransactionActions, TransactionState } from "@/hooks";
import { sanitizeNumber } from "@/lib/utils";
import { useModalState } from "@/redux/modal/modal.slice";
import { VaultsFormatted } from "@/services/interfaces";
import { useMemo, useState } from "react";

type TransactionFormProps = {
  state: TransactionState;
  actions: TransactionActions;
  onNextPage: () => void;
  transaction: Transactions;
  hasFromDetails?: boolean;
  hasToDetails?: boolean;
};

export function TransactionForm(props: Readonly<TransactionFormProps>) {
  const {
    state,
    actions,
    onNextPage,
    hasFromDetails,
    hasToDetails,
    transaction,
  } = props;
  const { closeModal } = useModalState();

  const initialCollapse = useMemo(() => {
    return state.amount ? "amount-details" : "";
  }, [state.amount]);

  const [collapse, setCollapse] = useState<string>(initialCollapse);

  function isEarnAccount(name: string) {
    return Object.values(EarnAccounts).includes(name as EarnAccounts);
  }

  function getAccountDetails(args: DataArgs) {
    if (isEarnAccount(args?.name ?? "")) {
      const data = args as VaultsFormatted;
      const details = [
        {
          item: "Share Price",
          tooltip:
            "Current Share Price of the assets within this Earn Account.",
          value: `${data?.pricePerShare?.toFixed(2)} USDC`,
        },
        {
          item: "Shares",
          tooltip:
            "The total number of Shares you currently hold within this Earn Account.",
          value: `${data?.balance ?? 0} Units`,
        },
        {
          item: "Share Value",
          tooltip: `The total value of the shares you own within this Earn Account. 
          
          Total Share Value is calculated by multiplying the number of Shares you own by the Share Price of the Earn Account.`,
          value: `${(data?.balance ?? 0) * data?.pricePerShare} USDC`,
        },
      ];

      if (args?.name !== Accounts.AUTOMATED_LENDING) {
        details.unshift({
          item: "Available Reserves",
          tooltip:
            "The total amount of USDC currently available to instantly withdraw from this Earn Account.",
          value: "1000000 USDC", // dummy while data is not available
        });
      }

      return details;
    } else {
      return [];
    }
  }

  const fromDetails = useMemo(() => {
    return state.from ? getAccountDetails(state.from) : [];
  }, [state]);

  const toDetails = useMemo(() => {
    return state.to ? getAccountDetails(state.to) : [];
  }, [state]);

  const amountDetails = useMemo(() => {
    switch (transaction) {
      case Transactions.DEPOSIT:
      case Transactions.TRANSFER:
        if (state.to?.name !== Accounts.SPENDING_ACCOUNT) {
          const data = state.to as VaultsFormatted;
          const transactionLabel =
            transaction === Transactions.DEPOSIT ? "deposited" : "transferred";
          return [
            {
              item: "Estimated Shares",
              tooltip: `The estimated total number of Shares to be ${transactionLabel} based on the current Share Price of the Earn Account.`,
              value: `${sanitizeNumber((state.amount * data?.pricePerShare).toString())} Units`,
            },
          ];
        } else {
          return [];
        }
      case Transactions.WITHDRAW:
        if (state.from?.name !== Accounts.SPENDING_ACCOUNT) {
          const data = state.from as VaultsFormatted;
          return [
            {
              item: "Estimated Shares",
              tooltip:
                "Estimated amount of Shares to be wihtdrawn based on the current Share Price of the Earn Account.",
              value: `${sanitizeNumber((state.amount * data?.pricePerShare).toString())} Units`,
            },
          ];
        } else {
          return [];
        }
      default:
        return [];
    }
  }, [state]);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <TransferDropdown
            data={state.fromRestData}
            label="From:"
            setDataItem={(e) => actions.setFrom(e)}
            selectedDataItem={state.from}
          />
          {hasFromDetails && <TransferDetails data={fromDetails} />}
        </div>
        <div className="flex flex-col gap-2">
          <TransferDropdown
            data={state.toRestData}
            label="To:"
            setDataItem={(e) => actions.setTo(e)}
            selectedDataItem={state.to}
          />
          {hasToDetails && <TransferDetails data={toDetails} />}
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <TransferInput
              value={state.amount}
              onChange={(amount) => {
                if (amount === 0) {
                  setCollapse("");
                } else {
                  setCollapse("amount-details");
                }
                return actions.setAmount(amount);
              }}
              maxValue={state.from?.balance}
            />
            <Collapsible open={!!state.isError}>
              <CollapsibleContent>
                <Text
                  size="xs"
                  variant="error"
                  className="max-w-sm break-words pt-2"
                >
                  {state.isError}
                </Text>
              </CollapsibleContent>
            </Collapsible>
          </div>
          <TransferCard data={amountDetails} collapsed={collapse} />
        </div>
      </div>
      <ConfirmButtons
        onClose={() => {
          actions.setAmount(0);
          closeModal();
        }}
        onNextPage={onNextPage}
      />
    </div>
  );
}
