export const VAULT_ABI = [
  {
    name: "Transfer",
    inputs: [
      { name: "sender", type: "address", indexed: true },
      { name: "receiver", type: "address", indexed: true },
      { name: "value", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "Approval",
    inputs: [
      { name: "owner", type: "address", indexed: true },
      { name: "spender", type: "address", indexed: true },
      { name: "value", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "Deposit",
    inputs: [
      { name: "recipient", type: "address", indexed: true },
      { name: "shares", type: "uint256", indexed: false },
      { name: "amount", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "Withdraw",
    inputs: [
      { name: "recipient", type: "address", indexed: true },
      { name: "shares", type: "uint256", indexed: false },
      { name: "amount", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "Sweep",
    inputs: [
      { name: "token", type: "address", indexed: true },
      { name: "amount", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "LockedProfitDegradationUpdated",
    inputs: [{ name: "value", type: "uint256", indexed: false }],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyAdded",
    inputs: [
      { name: "strategy", type: "address", indexed: true },
      { name: "debtRatio", type: "uint256", indexed: false },
      { name: "minDebtPerHarvest", type: "uint256", indexed: false },
      { name: "maxDebtPerHarvest", type: "uint256", indexed: false },
      { name: "performanceFee", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyReported",
    inputs: [
      { name: "strategy", type: "address", indexed: true },
      { name: "gain", type: "uint256", indexed: false },
      { name: "loss", type: "uint256", indexed: false },
      { name: "debtPaid", type: "uint256", indexed: false },
      { name: "totalGain", type: "uint256", indexed: false },
      { name: "totalLoss", type: "uint256", indexed: false },
      { name: "totalDebt", type: "uint256", indexed: false },
      { name: "debtAdded", type: "uint256", indexed: false },
      { name: "debtRatio", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "UpdateDepositLimit",
    inputs: [{ name: "depositLimit", type: "uint256", indexed: false }],
    anonymous: false,
    type: "event",
  },
  {
    name: "EmergencyShutdown",
    inputs: [{ name: "active", type: "bool", indexed: false }],
    anonymous: false,
    type: "event",
  },
  {
    name: "UpdateWithdrawalQueue",
    inputs: [{ name: "queue", type: "address[20]", indexed: false }],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyUpdateDebtRatio",
    inputs: [
      { name: "strategy", type: "address", indexed: true },
      { name: "debtRatio", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyUpdateMinDebtPerHarvest",
    inputs: [
      { name: "strategy", type: "address", indexed: true },
      { name: "minDebtPerHarvest", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyUpdateMaxDebtPerHarvest",
    inputs: [
      { name: "strategy", type: "address", indexed: true },
      { name: "maxDebtPerHarvest", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyUpdatePerformanceFee",
    inputs: [
      { name: "strategy", type: "address", indexed: true },
      { name: "performanceFee", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyMigrated",
    inputs: [
      { name: "oldVersion", type: "address", indexed: true },
      { name: "newVersion", type: "address", indexed: true },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyRevoked",
    inputs: [{ name: "strategy", type: "address", indexed: true }],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyRemovedFromQueue",
    inputs: [{ name: "strategy", type: "address", indexed: true }],
    anonymous: false,
    type: "event",
  },
  {
    name: "StrategyAddedToQueue",
    inputs: [{ name: "strategy", type: "address", indexed: true }],
    anonymous: false,
    type: "event",
  },
  {
    name: "UpdateHealthCheck",
    inputs: [{ name: "healthCheck", type: "address", indexed: true }],
    anonymous: false,
    type: "event",
  },
  {
    name: "WithdrawFromStrategy",
    inputs: [
      { name: "strategy", type: "address", indexed: true },
      { name: "totalDebt", type: "uint256", indexed: false },
      { name: "loss", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "FeeReport",
    inputs: [
      { name: "management_fee", type: "uint256", indexed: false },
      { name: "performance_fee", type: "uint256", indexed: false },
      { name: "strategist_fee", type: "uint256", indexed: false },
      { name: "partner_fee", type: "uint256", indexed: false },
      { name: "duration", type: "uint256", indexed: false },
    ],
    anonymous: false,
    type: "event",
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "initialize",
    inputs: [
      { name: "token", type: "address" },
      { name: "nameOverride", type: "string" },
      { name: "symbolOverride", type: "string" },
      { name: "configAddress", type: "address" },
    ],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "initialize",
    inputs: [
      { name: "token", type: "address" },
      { name: "nameOverride", type: "string" },
      { name: "symbolOverride", type: "string" },
      { name: "configAddress", type: "address" },
      { name: "healthCheck", type: "address" },
    ],
    outputs: [],
  },
  {
    stateMutability: "pure",
    type: "function",
    name: "apiVersion",
    inputs: [],
    outputs: [{ name: "", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "DOMAIN_SEPARATOR",
    inputs: [],
    outputs: [{ name: "", type: "bytes32" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "setName",
    inputs: [{ name: "name", type: "string" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "setSymbol",
    inputs: [{ name: "symbol", type: "string" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "setLockedProfitDegradation",
    inputs: [{ name: "degradation", type: "uint256" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "setDepositLimit",
    inputs: [{ name: "limit", type: "uint256" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "setEmergencyShutdown",
    inputs: [{ name: "active", type: "bool" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "setWithdrawalQueue",
    inputs: [{ name: "queue", type: "address[20]" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "transfer",
    inputs: [
      { name: "receiver", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "transferFrom",
    inputs: [
      { name: "sender", type: "address" },
      { name: "receiver", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "approve",
    inputs: [
      { name: "spender", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "increaseAllowance",
    inputs: [
      { name: "spender", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "decreaseAllowance",
    inputs: [
      { name: "spender", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "permit",
    inputs: [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "amount", type: "uint256" },
      { name: "expiry", type: "uint256" },
      { name: "signature", type: "bytes" },
    ],
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "totalAssets",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "deposit",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "deposit",
    inputs: [{ name: "_amount", type: "uint256" }],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "deposit",
    inputs: [
      { name: "_amount", type: "uint256" },
      { name: "recipient", type: "address" },
    ],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "maxAvailableShares",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "withdraw",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "withdraw",
    inputs: [{ name: "maxShares", type: "uint256" }],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "withdraw",
    inputs: [
      { name: "maxShares", type: "uint256" },
      { name: "recipient", type: "address" },
    ],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "withdraw",
    inputs: [
      { name: "maxShares", type: "uint256" },
      { name: "recipient", type: "address" },
      { name: "maxLoss", type: "uint256" },
    ],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "pricePerShare",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "addStrategy",
    inputs: [
      { name: "strategy", type: "address" },
      { name: "debtRatio", type: "uint256" },
      { name: "minDebtPerHarvest", type: "uint256" },
      { name: "maxDebtPerHarvest", type: "uint256" },
      { name: "performanceFee", type: "uint256" },
    ],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "updateStrategyDebtRatio",
    inputs: [
      { name: "strategy", type: "address" },
      { name: "debtRatio", type: "uint256" },
    ],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "updateStrategyMinDebtPerHarvest",
    inputs: [
      { name: "strategy", type: "address" },
      { name: "minDebtPerHarvest", type: "uint256" },
    ],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "updateStrategyMaxDebtPerHarvest",
    inputs: [
      { name: "strategy", type: "address" },
      { name: "maxDebtPerHarvest", type: "uint256" },
    ],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "updateStrategyPerformanceFee",
    inputs: [
      { name: "strategy", type: "address" },
      { name: "performanceFee", type: "uint256" },
    ],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "migrateStrategy",
    inputs: [
      { name: "oldVersion", type: "address" },
      { name: "newVersion", type: "address" },
    ],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "revokeStrategy",
    inputs: [],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "revokeStrategy",
    inputs: [{ name: "strategy", type: "address" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "addStrategyToQueue",
    inputs: [{ name: "strategy", type: "address" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "removeStrategyFromQueue",
    inputs: [{ name: "strategy", type: "address" }],
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "debtOutstanding",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "debtOutstanding",
    inputs: [{ name: "strategy", type: "address" }],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "creditAvailable",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "creditAvailable",
    inputs: [{ name: "strategy", type: "address" }],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "availableDepositLimit",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "expectedReturn",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "expectedReturn",
    inputs: [{ name: "strategy", type: "address" }],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "report",
    inputs: [
      { name: "gain", type: "uint256" },
      { name: "loss", type: "uint256" },
      { name: "_debtPayment", type: "uint256" },
    ],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "sweep",
    inputs: [{ name: "token", type: "address" }],
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    name: "sweep",
    inputs: [
      { name: "token", type: "address" },
      { name: "amount", type: "uint256" },
    ],
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "governance",
    inputs: [],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "management",
    inputs: [],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "guardian",
    inputs: [],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "rewards",
    inputs: [],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "approver",
    inputs: [],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "name",
    inputs: [],
    outputs: [{ name: "", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "symbol",
    inputs: [],
    outputs: [{ name: "", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "decimals",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "balanceOf",
    inputs: [{ name: "arg0", type: "address" }],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "allowance",
    inputs: [
      { name: "arg0", type: "address" },
      { name: "arg1", type: "address" },
    ],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "totalSupply",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "token",
    inputs: [],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "config",
    inputs: [],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "healthCheck",
    inputs: [],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "strategies",
    inputs: [{ name: "arg0", type: "address" }],
    outputs: [
      {
        name: "",
        type: "tuple",
        components: [
          { name: "performanceFee", type: "uint256" },
          { name: "activation", type: "uint256" },
          { name: "debtRatio", type: "uint256" },
          { name: "minDebtPerHarvest", type: "uint256" },
          { name: "maxDebtPerHarvest", type: "uint256" },
          { name: "lastReport", type: "uint256" },
          { name: "totalDebt", type: "uint256" },
          { name: "totalGain", type: "uint256" },
          { name: "totalLoss", type: "uint256" },
        ],
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "withdrawalQueue",
    inputs: [{ name: "arg0", type: "uint256" }],
    outputs: [{ name: "", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "emergencyShutdown",
    inputs: [],
    outputs: [{ name: "", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "depositLimit",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "debtRatio",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "totalDebt",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "totalIdle",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "lastReport",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "activation",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "lockedProfit",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "lockedProfitDegradation",
    inputs: [],
    outputs: [{ name: "", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    name: "nonces",
    inputs: [{ name: "arg0", type: "address" }],
    outputs: [{ name: "", type: "uint256" }],
  },
];
