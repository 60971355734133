import {
  CheckIcon,
  ChevronDown,
  ChevronRight,
  CopyIcon,
  PencilIcon,
  SquareArrowOutUpRight,
  X,
} from "lucide-react";

import Calculator from "@/assets/icons/currencycalculator.svg";
import Error from "@/assets/icons/error.svg";
import Info from "@/assets/icons/info.svg";
import Info2 from "@/assets/icons/info2.svg";
import Mastercard from "@/assets/icons/mastercard.svg";
import MastercardContact from "@/assets/icons/mastercardcontact.svg";
import MastercardPin from "@/assets/icons/mastercardpin.svg";
import MastercardSupport from "@/assets/icons/mastercardsupport.svg";
import Success from "@/assets/icons/success.svg";
import TransferIcon from "@/assets/icons/transfer.svg";
import Warning from "@/assets/icons/warning.svg";
import JoinnLogoPrimary from "@/assets/joinn.svg";
import HideIcon from "@/assets/mastercard/hide.svg";
import JoinnLogoWhite from "@/assets/mastercard/joinn-logo-white.svg";
import ShowIcon from "@/assets/mastercard/show.svg";

export type IconProps = React.HTMLAttributes<HTMLImageElement> & {
  size?: number;
};

export function useIcons() {
  return {
    ShareIcon: () => {
      return (
        <SquareArrowOutUpRight color="hsl(var(--primary)/0.75)" size={18} />
      );
    },
    ChevronDownOutline: ({ className }: IconProps) => {
      return (
        <ChevronDown color="hsl(var(--primary)/0.75)" className={className} />
      );
    },
    ChevronRightOutline: ({ className }: IconProps) => {
      return (
        <ChevronRight color="hsl(var(--primary)/0.75)" className={className} />
      );
    },
    CheckIcon: ({ className, size = 20 }: IconProps) => {
      return (
        <CheckIcon
          color="hsl(var(--primary)/0.75)"
          size={size}
          className={className}
        />
      );
    },
    CloseIcon: ({ className, size = 20 }: IconProps) => {
      return (
        <X color="hsl(var(--primary)/0.75)" size={size} className={className} />
      );
    },
    EditIcon: ({ className, size = 18, color }: IconProps) => {
      return (
        <PencilIcon
          color={color ?? "hsl(var(--primary)/0.75)"}
          size={size}
          className={className}
        />
      );
    },
    CopyIcon: ({ size = 14, className }: IconProps) => {
      return (
        <CopyIcon
          color="hsl(var(--primary)/0.75)"
          size={size}
          className={className}
        />
      );
    },
    SuccessIcon: ({ size = 16 }: IconProps) => {
      return (
        <img src={Success} alt="Success Icon" height={size} width={size} />
      );
    },
    InfoIcon: ({ size = 16 }: IconProps) => {
      return <img src={Info} alt="Info Icon" height={size} width={size} />;
    },
    InfoIcon2: ({ size = 16 }: IconProps) => {
      return <img src={Info2} alt="Info Icon" height={size} width={size} />;
    },
    WarningIcon: ({ size = 16 }: IconProps) => {
      return (
        <img src={Warning} alt="Warning Icon" height={size} width={size} />
      );
    },
    ErrorIcon: ({ size = 16 }: IconProps) => {
      return <img src={Error} alt="Error Icon" height={size} width={size} />;
    },
    ShowIcon: ({ size = 16 }: IconProps) => {
      return <img src={ShowIcon} alt="Hide Icon" height={size} width={size} />;
    },
    HideIcon: ({ size = 16 }: IconProps) => {
      return <img src={HideIcon} alt="Show Icon" height={size} width={size} />;
    },
    CalculatorIcon: ({ size = 16 }: IconProps) => {
      return (
        <img
          src={Calculator}
          alt="Currency Calculator Icon"
          height={size}
          width={size}
        />
      );
    },
    MastercardIcon: ({ size = 16 }: IconProps) => {
      return (
        <img
          src={Mastercard}
          alt="Mastercard Icon"
          height={size}
          width={size}
        />
      );
    },
    MastercardSupportIcon: ({ size = 16 }: IconProps) => {
      return (
        <img
          src={MastercardSupport}
          alt="MastercardSupport Icon"
          height={size}
          width={size}
        />
      );
    },
    MastercardPinIcon: ({ size = 16 }: IconProps) => {
      return (
        <img
          src={MastercardPin}
          alt="MastercardPin Icon"
          height={size}
          width={size}
        />
      );
    },
    MastercardContactIcon: ({ size = 16 }: IconProps) => {
      return (
        <img
          src={MastercardContact}
          alt="MastercardContact Icon"
          height={size}
          width={size}
        />
      );
    },
    JoinnIcon: ({ ...props }: IconProps) => {
      return <img src={JoinnLogoWhite} alt="Joinn Logo" {...props} />;
    },
    JoinnPrimaryIcon: ({ size = 16, ...props }: IconProps) => {
      return (
        <img
          src={JoinnLogoPrimary}
          alt="Joinn Logo"
          height={size}
          width={size}
          {...props}
        />
      );
    },
    TransferIcon: ({ size = 16, ...props }: IconProps) => {
      return (
        <img src={TransferIcon} alt="Transfer Icon" width={size} {...props} />
      );
    },
  };
}
