import { Spinner } from "@/components";
import { useToast } from "@/components/hooks/use-toast";
import { Button } from "@/components/ui";
import { useCreateKycUrlMutation } from "@/redux/kyc/kyc.api";
import { useModalState } from "@/redux/modal/modal.slice";
import { X } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

export function KycFractal() {
  const { closeModal } = useModalState();
  const [createKycUrl, kycUrl] = useCreateKycUrlMutation();
  const [hasIframeLoaded, setHasIframeLoaded] = useState<boolean>(false);
  const { toast } = useToast();

  const messageReceiver = useCallback((message: MessageEvent) => {
    // React only messages from ID iframe
    setHasIframeLoaded(true);
    if (message.origin === "https://app.fractal.id") {
      if (message.data.error) {
        toast({
          title: "Error!",
          description: `KYC process failed with: ${JSON.stringify(message.data.error)}`,
        });
        closeModal();
      } else if (message.data.open) {
        /**
         * If you want to use wallet-sign-in, this is required
         * since there are security limitations, especially with
         * opening metamask protocol link in mobile device
         */
        window.open(
          message.data.open,
          message.data.target,
          message.data.features,
        );
      } else {
        toast({
          title: "Success!",
          description: "KYC process is completed.",
        });
        closeModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getRedirectUrl() {
      window.addEventListener("message", messageReceiver);
      await createKycUrl();
      return () => window.removeEventListener("message", messageReceiver);
    }

    getRedirectUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageReceiver]);

  return (
    <div className="h-[85vh] rounded-sm bg-white">
      <div className="absolute right-8 z-20 flex w-full justify-end p-2">
        <Button
          onClick={() => {
            setHasIframeLoaded(false);
            closeModal();
          }}
        >
          <X color="hsl(var(--accent))" size={32} />
        </Button>
      </div>
      <div className="absolute flex h-[calc(100%_-_40px)] w-[calc(100%_-_40px)] items-center justify-center">
        {(kycUrl.isLoading || !hasIframeLoaded) && (
          <Spinner size="80" className="h-20 w-20" />
        )}
      </div>
      {kycUrl?.data?.redirectUrl && (
        <iframe
          src={kycUrl?.data?.redirectUrl}
          allow="camera *; fullscreen *"
          sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          title="fractal kyc"
          className="relative z-10 h-full w-full border-none"
        />
      )}
    </div>
  );
}
