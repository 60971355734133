import { Login } from "@/pages";
import { useUser } from "@/redux/user/user.slice";
import { Routes } from "@/routes/routers";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RootLayout from "./RootLayout";

export default function PrivateLayout() {
  const { isUserLoggedIn } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // do not check for falsy, allow undefined to go through the else block
    // when isUserLoggedIn is undefined, it means, the variable is not initialized yet during the login
    if (isUserLoggedIn === false) {
      navigate(Routes.CONNECT);
    } else {
      navigate(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  return isUserLoggedIn ? <RootLayout /> : <Login />;
}
