import { toast } from "@/components/hooks/use-toast";
import { useUpdateUserProfileMutation } from "@/redux/user/user.api";
import { useEffect } from "react";

type ErrorMessage = {
  data: {
    message: string;
    statusCode: number;
  };
};

export function useChangeAvatar() {
  const [updateUserProfile, userResult] = useUpdateUserProfileMutation();

  async function handleOnChangeAvatar(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const avatarUrl = e.target?.result;
        if (avatarUrl) {
          const img = new Image();
          img.onload = async () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Calculate the new width and height based on the desired size
            const maxWidth = 128;
            const maxHeight = 128;
            let width = img.width;
            let height = img.height;
            if (width > maxWidth) {
              height = (height * maxWidth) / width;
              width = maxWidth;
            }
            if (height > maxHeight) {
              width = (width * maxHeight) / height;
              height = maxHeight;
            }

            // Set the canvas size to the new width and height
            canvas.width = width;
            canvas.height = height;

            // Draw the resized image on the canvas
            ctx?.drawImage(img, 0, 0, width, height);

            // Convert the canvas image to a data URL
            canvas.toDataURL("image/jpeg", 0.8);

            await updateUserProfile({ avatarUrl: avatarUrl.toString() });
          };
          img.src = avatarUrl.toString();
        }
      };
      reader.readAsDataURL(file);
    }
  }

  // Bind the response here
  useEffect(() => {
    if (userResult.isSuccess) {
      toast({
        title: "Congratulations!",
        description: "You have successfully uploaded a new profile picture.",
        variant: "success",
      });
    } else if (userResult.isError) {
      toast({
        title: "Upload failed!",
        description:
          (userResult?.error as ErrorMessage)?.data?.message ||
          "Unable to upload, please try again.",
        variant: "error",
      });
    }
  }, [userResult.isSuccess, userResult.isError]);

  return {
    updateUserAvatar: handleOnChangeAvatar,
    isLoading: userResult.isLoading,
    isSucess: userResult.isSuccess,
  };
}
